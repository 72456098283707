import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Container,
  Avatar,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";

const UserProfile = () => {
  const [currentUser, setCurrentUser] = useState({})

  const getUserInfo = () => {
    const userInfo = localStorage.getItem("userInfo");
    console.log(userInfo);
    
    if (userInfo) {
      setCurrentUser(JSON.parse(userInfo));
    } else {
      return null;
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  
  // État pour stocker les informations de l'utilisateur
  // const [user, setUser] = useState({
  //   name: "Jean Dupont",
  //   email: "jean.dupont@example.com",
  //   phone: "679 456 789",
  //   address: "123 Rue Example, Paris, France",
  //   role: "Admin",
  // });

  // // Formik pour gérer le formulaire de mise à jour du profil
  // const formik = useFormik({
  //   initialValues: {
  //     name: user.name,
  //     email: user.email,
  //     phone: user.phone,
  //     address: user.address,
  //   },
  //   onSubmit: (values) => {
  //     // Mettre à jour les informations de l'utilisateur
  //     setUser({
  //       ...user,
  //       ...values,
  //     });
  //     alert("Profil mis à jour avec succès!");
  //   },
  // });

  return (
    <Container width={"full"}>
      <Box p={{ xs: 2, sm: 3, md: 9 }} mt={{ xs: 5, sm: 5, md: "0px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Mon Profil</Typography>
          <Box display={"flex"} flexDirection={"column"} mt={2}>
            <h3>Role</h3>
          <Typography
            mt={-2}
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: "gray" }}
          > 
            {currentUser?.role}
          </Typography>
          </Box>
          
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Avatar sx={{ width: 50, height: 50 }}>
            {currentUser.user?.firstName
            // charAt(0)
            }
          </Avatar>
        </Box> */}
        <Box component="form" 
        // onSubmit={formik.handleSubmit}
         sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                // id="name"
                // name="name"
                label="Nom"
               value= {currentUser?.firstName
                  // charAt(0)
                  }
                //onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                // id="name"
                // name="name"
                label="Prénom"
                value={currentUser.lastName}
                // value={formik.values.name}
                // onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={currentUser?.email}
                // value={formik.values.email}
                // onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="phone"
                name="phone"
                // label="Téléphone"
                value={currentUser?.phoneNumber}
                // value={formik.values.phone}
                // onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
              >
                Mettre à jour
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default UserProfile;
