import axios from "axios";
import { url } from "../constants";

const authUrl = url + "api/auth";

const login = async(authData) => {
    let response = {}
   await 
   axios.post(authUrl + "/login", authData)
    .then(res => {
        response = res?.data;
    })
    .catch(error => {
        response = { error: error.response.data?.message }
    })
    return response
}

const createAccount = async(registerData) => {

    let response = {};
    await axios.post(authUrl+ "/register", registerData)
    .then(res => {
        response = res?.data;
    })
    .catch(error => {
        response = {error: error.response.data?.message}
    })
    return response;
}

export default{
    login,
    createAccount
}
