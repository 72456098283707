import { Box } from "@mui/material";
import React from "react";
import AmenagementService from "../../pages-sections/car-services/AmenagementService";

const AmenagementPage = () => {

    return(
        <Box>
            <AmenagementService/>
        </Box>
    )
}

export default AmenagementPage;