import { Box } from "@mui/material";
import React from "react";
import AllHouse from "../../../pages-sections/manager/houses/AllHouse";


const AllHousePage = () => {
    return(
        <Box>
            <AllHouse/>
        </Box>
    )
}

export default AllHousePage