import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, Card, CardContent, IconButton, Divider, Container } from '@mui/material';
import { Dashboard, Apartment, People, CalendarToday } from '@mui/icons-material';
import bookingApiRoute from '../../utils/api/bookingApiRoute';
import houseApiRoutes from '../../utils/api/houseApiRoutes';

const DashboardPage = () => {

  const [validatedBookings, setValidateBooking] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [bookingData, setBookingData] = useState([]);
  const [publishHouses, setPublishHouses] = useState([]);
 const [bookingHouses, setBookingHouses] = useState([]);

  const retrieveValidetedBooking = async () => {
    setIsLoading(true);

    const responseApi = await bookingApiRoute.getAllValidetedBookings();
    // console.log(responseApi);
    if (!Object.keys(responseApi).includes("error")) {
      // console.log(responseApi);
      setValidateBooking([...responseApi]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alert("Une erreur est survenue");
    }
  }

  const getBookingHouse = async () => {
    setIsLoading(true);

    const responseApi = await bookingApiRoute.getAllBookings();
    // console.log(responseApi);
    if (!Object.keys(responseApi).includes("error")) {
      // console.log(responseApi);
      setBookingData([...responseApi]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alert("Une erreur est survenue");
    }
  };

  const retrievePublishHouses = useCallback(async () => {
    setIsLoading(true);
    const apiResponse = await houseApiRoutes.getPublishAllHouses();
    console.log("Maison", apiResponse);

    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);

      setPublishHouses([...apiResponse]);
      console.log("Maison publiées", apiResponse);
    } else {
      setIsLoading(false);
      alert.error("Aucun logement");
    }
  }, []);

  const retrieveBookinghHouses = useCallback(async () => {
    setIsLoading(true);
    const apiResponse = await houseApiRoutes.getAllBookingHouses();
    console.log("Maison", apiResponse);

    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);

      setBookingHouses([...apiResponse]);
      console.log("Maison réservées", apiResponse);
    } else {
      setIsLoading(false);
      alert.error("Aucun logement");
    }
  }, []);

  useEffect(() => {
    retrieveValidetedBooking()
    getBookingHouse()
    retrievePublishHouses()
    retrieveBookinghHouses()
  }, []);

  return (
    <Box sx={{ flexGrow: 1, p: 3, mt:8, bgcolor: '#f5f5f5', minHeight: '100vh' }}>
      <Typography variant="h5" gutterBottom sx={{ color: '#333' }}>
        Tableau de Bord
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ bgcolor: '#ffffff', boxShadow: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Réservations reçues
              </Typography>
              <Typography variant="h4" sx={{ color: '#4CAF50' }}>
                {bookingData.length}
              </Typography>
              <IconButton sx={{ position: 'absolute', top: 10, right: 10 }}>
                <Apartment />
              </IconButton>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ bgcolor: '#ffffff', boxShadow: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Réservations validées
              </Typography>
              <Typography variant="h4" sx={{ color: '#FF9800' }}>
                {validatedBookings.length}
              </Typography>
              <IconButton sx={{ position: 'absolute', top: 10, right: 10 }}>
                <People />
              </IconButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ bgcolor: '#ffffff', boxShadow: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Réservations rejetées
              </Typography>
              <Typography variant="h4" sx={{ color: '#E91E63' }}>
                ??
              </Typography>
              <IconButton sx={{ position: 'absolute', top: 10, right: 10 }}>
                <Dashboard />
              </IconButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ bgcolor: '#ffffff', boxShadow: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Logements publiés
              </Typography>
              <Typography variant="h4" sx={{ color: '#2196F3' }}>
                {publishHouses.length}
              </Typography>
              <IconButton sx={{ position: 'absolute', top: 10, right: 10 }}>
                <CalendarToday />
              </IconButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ bgcolor: '#ffffff', boxShadow: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Logement reservés
              </Typography>
              <Typography variant="h4" sx={{ color: '#2196F3' }}>
                {bookingHouses.length}
              </Typography>
              <IconButton sx={{ position: 'absolute', top: 10, right: 10 }}>
                <CalendarToday />
              </IconButton>
            </CardContent>
          </Card>
        </Grid>

        
      </Grid>

      <Box mt={4}>
        <Typography variant="h6" sx={{ color: '#333' }}>
        Logements disponibles
        </Typography>
        <Paper sx={{ p: 2, mt: 2, bgcolor: '#ffffff' }}>
          <Typography variant="body1">
            Pas d'activité récente à afficher.
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default DashboardPage;
