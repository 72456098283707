import axios from "axios";

import { url } from "../constants";
const houseUrl = url + "api/house";

const getAllHouses = async () => {
  let response = {};

  await axios
    .get(houseUrl + "/getAll")
    .then((res) => {
      response = res.data;
    })
    .catch((error) => {
      response = { error: error.response?.data.message };
    });

  return response;
};

const addHouse = async (house) => {
  let response = {};

  await axios
    .post(houseUrl + "/addHouse", house)
    .then((res) => {
      response = res?.data;
    })
    .catch((error) => {
      response = { error: error.response?.data.message };
    });
  return response;
};

const getHouseById = async (id) => {
  let response = {};

  await axios.get(houseUrl + "/get-by-id/"+ id)
  .then((res) => {
    response = res?.data
  })
  .catch((errors) => {
    response = {error: errors.response?.data.message ?? ''}
  })
  return response;
};


const removeHouse = async (id) => {
  let response = {};

  await axios
    .delete(houseUrl + "/delete", {
      params: { ids: id },
    })
    .then((res) => {
      response = res?.data;
    })
    .catch((errors) => {
      response = { error: errors.response?.data.message ?? "" };
    });
  return response;
};

const publishHouse = async (id) => {
  let response = {};
  axios
    .put(houseUrl + "/publish/" + id)
    .then((res) => {
      response = res?.data;
    })
    .catch((errors) => {
      response = { error: errors.response?.data.message };
    });
  return response;
};

const unPublishHouse = async (id) => {
  let response = {};
  axios
    .put(houseUrl + "/un-publish/" + id)
    .then((res) => {
      response = res?.data;
    })
    .catch((errors) => {
      response = { error: errors.response?.data.message };
    });
  return response;
};

const getPublishAllHouses = async () => {
  let response = {};
  await axios
    .get(houseUrl + "/publish-house")
    .then((res) => {
      response = res?.data;
    })
    .catch((error) => {
      response = { error: error.response?.data.message ?? "" };
    });
  return response;
};

const getAllBookingHouses = async () => {
  let response = {};
  await axios
    .get(houseUrl + "/get-booking-house")
    .then((res) => {
      response = res?.data;
    })
    .catch((error) => {
      response = { error: error.response?.data.message ?? "" };
    });
  return response;
};

const searchHousesByUnit = async(unit) => {
  let response = {}

  await axios.get(houseUrl + "/search-by-unit", {
    params: { unit: unit }
  })
  .then(res => {
    response = res?.data;
  })
  .catch(error => {
    response = {error: error.response?.data.message ?? ""}
  })
  return response
}

const searchHousesByLocation = async(location) => {
  let response = {}

  await axios.get(houseUrl + "/search-house-by-location", {
    params: { location: location }
  })
  .then(res => {
    response = res?.data;
  })
  .catch(error => {
    response = {error: error.response?.data.message ?? ""}
  })
  return response
}

const searchHousesByType = async(type) => {
  let response = {}

  await axios.get(houseUrl + "/search-by-type", {
    params: {type:type}
  })
  .then(res => {
    response = res?.data;
  })
  .catch(error => {
    response = {error: error.response?.data.message ?? ""}
  })
  return response
}



const searchHousesByBedRoom = async(bedroom) => {
  let response = {}

  await axios.get(houseUrl + "/search-by-bedroom-number", {
    params: { bedroom: bedroom }
  })
  .then(res => {
    response = res?.data;
  })
  .catch(error => {
    response = {error: error.response?.data.message ?? ""}
  })
  return response
}

const searchHousesByPriceRange = async(minPrice, maxPrice) => {
  let response = {}

  await axios.get(houseUrl + "/find-by-price-range", {
    params: {minPrice, maxPrice }
  })
  .then(res => {
    response = res?.data;
  })
  .catch(error => {
    response = {error: error.response?.data.message ?? ""}
  })
  return response
}

const searchHouseByAllParameter = async(type, location, bedroom, minPrice, maxPrice, unit, categoryId ) => {

  let response = {}
  await axios.get(houseUrl + "/search-house-by-parameter", {
    params: {type:type,unit:unit, bedroom:bedroom, location:location,minPrice: minPrice, maxPrice:maxPrice, categoryId:categoryId }
  })
  .then(res => {
    response = res?.data;
  })
  .catch(error => {
    response = {error: error.response?.data.message ?? ""}
  })
  return response
}
const updateHouse = async(id, house) => {

  let response = {}
  await axios.put(houseUrl + "/update/"+ id, house)
  .then(res => {
    response = res?.data;
  })
  .catch(error => {
    response = {error: error.reponse.data?.message??''}
  })
  return response;
}


export default {
  getAllHouses,
  addHouse,
  removeHouse,
  publishHouse,
  getPublishAllHouses,
  getHouseById,
  unPublishHouse,
  searchHousesByType,
  searchHousesByLocation,
  searchHousesByPriceRange,
  searchHousesByBedRoom,
  searchHousesByUnit,
  searchHouseByAllParameter,
  getAllBookingHouses,
  updateHouse
};
