import { Padding } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";

const EntertainementService = () => {
  return (
    <Box px={{ xs: 0, md: 0 }} py={{ xs: 3 }} mt={6}>
      <Box ml={{ xs: 4, sm:6, md: 9 }}>
        <h2 variant="h5" gutterBottom>
          Explorez nos programmes de divertissements
        </h2>
        <p variant="body1" paragraph style={{}}>
          Passez des moments fun en famille
        </p>
      </Box>
      <Grid
        spacing={{ xs: 0, sm: 2, md: 4 }}
        container
        direction={"row"}
        p={{ xs: 4, md: "80px" }}
        alignItems={"center"}
        display="flex"
        // Pour maintenir l'alignement horizontal
        flexDirection={{ xs: "column", md: "row " }}
      >
        <Grid item xs={12} md={6} justifyContent="center">
          <Card sx={{ maxWidth: 600 }}>
            <CardMedia
              component="img"
              height={{ xs: "200", md: "600" }}
              image="/assets/bowling.jpg"
              alt="bowling image service"
            />
          </Card>
        </Grid>
        <Grid item mt={{ xs: 4 }} xs={12} md={6} justifyContent="center">
          <Typography variant="h5">Bowling</Typography>
          <p>
            Plongez dans l'ambiance dynamique de nos pistes de bowling, conçues
            pour offrir à la fois divertissement et défis. Nos installations
            modernes comprennent des boules et des quilles de qualité, des
            écrans de score numériques, et des zones de détente confortables
            pour vous et vos amis.
          </p>
          <Link href="https://www.funcenter.cm/bowling.html">
            En savoir plus
          </Link>
        </Grid>
      </Grid>
      <Grid
        spacing={{ xs: 0, sm: 2, md: 4 }}
        container
        direction={"row"}
        px={{ xs: 4, md: "80px" }}
        alignItems={"center"}
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Grid item mb={{ xs: 4 }} xs={12} md={6} justifyContent="center">
          <Typography variant="h5">Billard</Typography>
          <p>
            Que vous soyez un joueur aguerri ou que vous découvriez le billard
            pour la première fois, notre espace est conçu pour offrir une
            expérience mémorable et divertissante.
          </p>
          <Link href="https://www.funcenter.cm/gaming.html#billard">
            En savoir plus
          </Link>
        </Grid>
        <Grid item xs={12} md={6} justifyContent="center">
          <Card sx={{ maxWidth: 600 }}>
            <CardMedia
              component="img"
              height={{ xs: "200", md: "600" }}
              image="/assets/billard.jpg"
              alt="bowling image service"
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EntertainementService;
