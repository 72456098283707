import React from "react";

import HouseRentalServices from "../../pages-sections/car-services/Home1";
import { Box } from "@mui/material";
import HouseCard from "../../components/house/HouseCard";

const HouseRentalPage = () => {
    
    return(
        <Box>
            <HouseCard/>
        </Box>
    )
}

export default HouseRentalPage;