import { Box } from "@mui/material";
import React from "react";
import PublishHouse from "../../../pages-sections/manager/houses/PublishHouse";

const PublishHousePage = () => {
    return(
        <Box>
            <PublishHouse/>
        </Box>
    )
}

export default PublishHousePage