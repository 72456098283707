import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Box,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { ChevronLeft, ChevronRight } from "lucide-react";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
  borderBottom: `3px solid ${theme.palette.secondary.main}`,
}));

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  "&:hover": {
    fontWeight: "bold",
  },
}));

const SlideContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  color: theme.palette.common.white,
  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
  marginTop: "30px",
  alignContent:"center",
  justifyContent: "center",
  width: theme.breakpoints.values.xs < 600 ? "400px" : "1000px", // Gestion des tailles
}));

const ServiceCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[4],
  },
}));

const DiasporaSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Footer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  textAlign: "center",
}));

const SocialIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const Home1 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentSlide, setCurrentSlide] = useState(0);
  const [serviceImages, setServiceImages] = useState({
    location: 0,
    construction: 0,
    amenagement: 0,
  });

  const slides = [
    {
      image: "/assets/amenagement_home.jpg",
      title: "Bienvenue chez Services Immobiliers",
      subtitle: "Votre partenaire pour tous vos projets immobiliers",
    },
    {
      image: "/assets/maison_home.jpg",
      title: "Location de Prestige",
      subtitle: "Découvrez nos offres exclusives",
    },
    {
      image: "assets/construction.jpg",
      //   '/assets/construction.jpg',
      title: "Construction sur Mesure",
      subtitle: "Réalisez le projet de vos rêves",
    },
    
  ];

  const services = [
    {
      id: 1,
      title: "Location",
      link: "/house-rental",
      description:
        "Trouvez le logement idéal parmi notre sélection de biens à louer.",
      images: [
        "/assets/maison_home.jpg",
        "/assets/location.jpg",
        "/assets/hermes-130-jardin.jpg",
      ],
    },
    {
      id: 2,
      title: "Construction",
      link: "/constuction-service",
      description:
        "Confiez-nous la réalisation de votre projet immobilier sur mesure.",
      images: [
        "assets/construction.jpg",
        "assets/construction3.jpg",
        "assets/location7.jpg",
      ],
    },
    {
      id: 3,
      title: "Aménagement",
      link:"/design-service",
      description:
        'Optimisez et embellissez vos espaces avec nos services d aménagement.',
      images: [
        "assets/amenagement_home.jpg",
        // "/assets/construction3.jpg",
        // "/assets/location7.jpg",
      ],

      //   images: ['/assets/amenagement2.jpg', '/assets/amenagement3.jpg'],
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const handleServiceImageChange = (service, direction) => {
    setServiceImages((prev) => ({
      ...prev,
      [service]: (prev[service] + direction + 3) % 3,
    }));
  };

  return (
    <Box width={"full"}>
      {/* <StyledAppBar position="static">
        <StyledToolbar>
          <Typography variant="h6" component="div">
            <span style={{ color: theme.palette.secondary.main }}>Services</span> Immobiliers
          </Typography>
          {!isMobile && (
            <Box>
              {['Accueil', 'Location', 'Construction', 'Aménagement', 'Diaspora', 'Contact'].map((item) => (
                <NavButton key={item}>{item}</NavButton>
              ))}
            </Box>
          )}
        </StyledToolbar>
      </StyledAppBar> */}

      <Box sx={{ position: "relative", height: "400px", overflow: "hidden" }}>
        {slides.map((slide, index) => (
          <Box
            key={index}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: currentSlide === index ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
              
            }}
          >
            {/* <Grid >  */}
                {/* <Card>  */}
                <img
                    src={slide.image}
                    alt={slide.title}
                style={{ width: "100%", height: "100%", objectFit: "cover"}}
                />
                {/* </Card> */}
            {/* </Grid> */}
            
            <SlideContent >
              <Typography variant="h4" component="h1" gutterBottom 
                  sx={{fontSize: {
                    xs: "33px",
                    sm: "40px",
                    md: "45px"
                  }}
                }
              >
                {slide.title}
              </Typography>
              <Typography variant="h5" component="p">
                {slide.subtitle}
              </Typography>
            </SlideContent>
          </Box>
        ))}
      </Box>

      <Container maxWidth="full" sx={{ mt: 4 }}>
        <Typography variant="body1" paragraph py={3} textAlign={"justify"}>
          Forte de plus de 20 ans d'expérience, notre agence immobilière
          s'engage à vous offrir des services de qualité pour tous vos projets
          immobiliers. Que vous cherchiez à louer, à construire ou à aménager,
          notre équipe d'experts est là pour vous accompagner à chaque étape.
          Nous mettons un point d'honneur à comprendre vos besoins spécifiques
          afin de vous proposer des solutions sur mesure.
        </Typography>

        <Grid container spacing={3} sx={{ mt: 4 }}>
          {services.map((service, index) => (
            <Grid item xs={12} md={4} key={service.id}>
              <ServiceCard>
                <Box sx={{ position: "relative" }}>
                  <CardMedia
                    component="img"
                    height="270"
                    width={"100%"}
                    image={
                      service.images[0]
                    }
                    alt={service.title}
                  />
                  {/* <IconButton
                    sx={{
                      position: "absolute",
                      left: 8,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={() =>
                      handleServiceImageChange(service.title.toLowerCase(), -1)
                    }
                  >
                    <ChevronLeft />
                  </IconButton>
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={() =>
                      handleServiceImageChange(service.title.toLowerCase(), 1)
                    }
                  >
                    <ChevronRight />
                  </IconButton> */}
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" textAlign={"justify"}>
                    {service.description}
                  </Typography>
                  <Box paddingTop={2} mt="auto">
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#f4511e"}}
                    size="small"
                    href={service.link}
                  >
                    En savoir +
                  </Button>
                </Box>
                </CardContent>
              </ServiceCard>
            </Grid>
          ))}
        </Grid>

        <DiasporaSection
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          gap={{xs:4, md:3}}
          sx={{ padding:{ md:3} }}
        >
          <img
            src="/assets/home_cart.jpg"
            alt="Carte du monde avec des connexions"
            style={{
              // marginRight: theme.spacing(3),
              width: "300px",
              height: "220px",
              objectFit: "cover",
              // marginLeft: theme.breakpoints.down('xs')
              // ? theme.spacing(2) // Marges pour les petits écrans (xs)
              // : theme.breakpoints.down('sm')
              // Marges pour les écrans medium et plus grands
            }}
          />
          <Box>
            <Typography variant="h5" gutterBottom >
              Services pour la Diaspora
            </Typography>
            <Typography variant="body2" textAlign={"justify"}>
              Nous offrons des services spécialisés pour la diaspora, facilitant
              vos investissements immobiliers à distance. Que vous soyez à
              l'étranger, nous assurons le suivi de vos projets et garantissons
              la sécurité de vos investissements.
            </Typography>
          </Box>
        </DiasporaSection>
      </Container>
    </Box>
  );
};

export default Home1;
