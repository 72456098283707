import { Box } from "@mui/material";
import React from "react";
import HouseForm from "../../../pages-sections/manager/houses/HouseForm";


const CreateHouse = () => {
    return(
        <Box>
            <HouseForm/>
        </Box>
    )
}

export default CreateHouse;