import { Box } from "@mui/material";
import React from "react";
import AmenagementService from "../../pages-sections/car-services/AmenagementService";
import Home from "../../pages-sections/car-services/Home";

const HomePage = () => {

    return(
        <Box>
            <Home/>
        </Box>
    )
}

export default HomePage;