import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Grid, Link, Box } from '@mui/material';
// import Carousel from 'react-material-ui-carousel';
import Carousel from "react-responsive-carousel/lib/js/components/Carousel";

{/* <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1,
          padding: 2,
        }}
      >
        <Typography variant="h4" color="white" align="center">
          {bannerText}
        </Typography> */}

const coworkingSpaces = [
  {
    id: 1,
    title: 'Espace coworking A',
    description: 'Un espace moderne et inspirant pour travailler.',
    images: [
      'assets/confort-office.jpg',
      'assets/bureau-standart.jpg',
      'assets/premium-coworking-office.jpg',
      'assets/individual_desks_b.jpg'
    ],
    website: 'https://business-center.pategou.com/espaces-coworking'
  },
  {
    id: 2,
    title: 'Espace coworking B',
    description: 'Un espace collaboratif avec de nombreuses fonctionnalités.',
    images: [
      'assets/conference_room_b.jpg',
      'assets/overzise.jpg',
      'assets//rooftop.jpg'
    ],
    website: 'https://business-center.pategou.com/espaces-coworking'
  }
  // Ajoutez d'autres espaces de coworking ici
];

const CoworkingService = () => {
  return (
 
    <div >
      <Box 
        px={{xs:2, md:6}}
        mt={"91px"}
      > 
      <Box ml={{xs:2, md:4}}> 
      <h1 variant="h4" gutterBottom >
        Services de coworking
      </h1>
      <p variant="body1" paragraph style={{}}>
        Découvrez nos espaces de coworking modernes et inspirants adaptés à vos besoins professionnels.
      </p>
      </Box>
      <Grid container spacing={3} p={{xs:2, md:4}}>
        {coworkingSpaces.map((space) => (
          <Grid item xs={12} sm={6} md={6} key={space.id}>
            <Card>
              <Carousel 
                style={{ padding: 0, margin: 0 }} // Supprimer les marges internes et externes du carousel
                containerProps={{ style: { padding: 0, margin: 0 } }} // Appliquer les styles au conteneur du carousel
                classes={{ 
                 thumbWrapper: 'thumbs-wrapper', 
                 thumbList: 'thumbs' 
               }} // Assurez
              >
                {space.images.map((image, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    height="390"
                    image={image}
                    alt={`Image ${index + 1}`}
                  />
                ))}
              </Carousel>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {space.title}
                </Typography>
                <Typography variant="body2" paragraph>
                  {space.description}
                </Typography>
                <Link
                  variant="contained"
                  color="primary"
                  href={"https://business-center.pategou.com/home"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Se rendre sur le site
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>

    </div>
  );
};

export default CoworkingService;
