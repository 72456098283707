import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchFilters from "../searchBar/SearchFilter";
import houseApiRoutes from "../../utils/api/houseApiRoutes";
import { useAlert } from "react-alert";
import { useTranslation, Trans } from 'react-i18next';


const HouseCard = () => {
  const [publishHouses, setPublishHouses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();
  const { t } = useTranslation();

  const retrievePublishHouses = async () => {
    setIsLoading(true);
    const apiResponse = await houseApiRoutes.getPublishAllHouses();
    console.log("Maison", apiResponse);

    if (!Object.keys(apiResponse).includes("error")) {
        
      setPublishHouses([...apiResponse]);
      console.log("Maison publiées", apiResponse);
    } else {
      setIsLoading(false);
      alert.error("Aucun logement");
    }
  };



  // const getHouseCategory = async () => {
  //     setIsLoading(true)
  //     if(publishHouses.category && publishHouses.category.id){ 
  //       const categoryId = publishHouses.category.id
  //       const apiResponse = await categoryApiRoutes.getById(categoryId)
  //     if(!Object.keys(apiResponse).includes('error')){
  //       setIsLoading(false)
        
  //     }else{
  //       setIsLoading(false)
  //     }
  //   }
  // }

  useEffect(() => {
    retrievePublishHouses();
  }, []);


  return (
    <Box>
      <Box mb={6}>
        <SearchFilters />
      </Box>
      <Box px={{ xs: 4, md: 10 }}>
        <Grid container spacing={4}>
          {publishHouses &&
            publishHouses?.map((house) => (
              <Grid item xs={12} sm={6} md={3} key={house.id}>
                <Card
                  sx={{
                    borderRadius: 2,
                    boxShadow: 3,
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="200"
                      image={house.images[0]}
                      alt={"image house"}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                         {} {house.shortDescription} ({house.location})
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {house.bigDescription}
                      </Typography>
                      <Box
                        mt={2}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                           href={`/details/${house.id}`}
                        >
                          Détails
                        </Button>
                      </Box>
                      <Box mt={1}>
                        <Typography
                          variant="body2"
                          color={
                            house.available ? "success.main" : "error.main"
                          }
                        >
                          {house.available ? "Disponible" : "Réservé"}
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default HouseCard;
