import { Box } from "@mui/material";
import React from "react";
import LogementDetails from "../../../pages-sections/manager/houses/Details";

const DetailsPage = () => {

    return(
        <Box>
<LogementDetails/>
        </Box>
    )
}

export default DetailsPage;