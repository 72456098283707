import { Box } from "@mui/material";
import React from "react";
import AllBookings from "../../../pages-sections/manager/bookings/AllBookings";

const AllBookingsPage = () => {

    return(
        <Box>
            <AllBookings/>
        </Box>
    )
}
export default AllBookingsPage;