import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import houseApiRoutes from "../../utils/api/houseApiRoutes";
import { Formik } from "formik";
import * as yup from "yup";
import bookingApiRoute from "../../utils/api/bookingApiRoute";
import { useAlert } from "react-alert";
import serviceApiRoute from "../../utils/api/serviceApiRoute";
import categoryApiRoutes from "../../utils/api/categoryApiRoutes";



const BookingHouse = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [price, setPrice] = useState(0);
  const [userId, setUserId] = useState("");
  const [houseId, setHouseId] = useState("");
  const [serviceId, setServiceId] = useState([]);
  const [category, setCategory] = useState({});

  const [houseDetail, setHouseDetail] = useState({});
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const alert = useAlert();
  const { id } = useParams();
  const [selectedServices, setSelectedServices] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const getUserInfo = () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      setCurrentUser(JSON.parse(userInfo));
    } else {
      return null;
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const initialValues = {
    lastName,
    firstName,
    phoneNumber,
    email,
    startDate,
    endDate,
    price: houseDetail.price,
    userId,
    houseId,
    serviceId: [],
  };


  //Prix total des services que l'utilisateur a sélectionné
  const servicesPrice = serviceId.reduce(
    (sum, service) => sum + service.price,
    0
  );
  const housePrice = houseDetail.price
  const totalBookingPrice= servicesPrice + housePrice

  const formSchema = yup.object().shape({
    lastName: yup.string().required("Le nom ne doit pas etre vide"),
    firstName: yup.string().required("Le prénom ne doit pas etre vide"),
    phoneNumber: yup
      .string()
      .required("Veuillez ajouter un numéro de téléphone"),
    email: yup.string().email("Email requis").required("Email invalide"),
    startDate: yup.string().required("Veuillez ajouter une date de debut"),
    endDate: yup.string().required("Veuillez ajouter une date de fin"),
  });

  const getHouseDetails = async () => {
    setIsLoading(true);
    const apiResponse = await houseApiRoutes.getHouseById(id);
    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      console.log("Détails de la maison", apiResponse);
      setHouseDetail({ ...apiResponse });
      const categoryApiResponse = await categoryApiRoutes.getById(
        apiResponse.category.id
      );
      if (!Object.keys(categoryApiResponse).includes("error")) {
        setIsLoading(false);
        setCategory({ ...categoryApiResponse });
      }
      setIsLoading(true);
      const serviceApiResponse = await serviceApiRoute.getById(
        apiResponse.service.id
      );
      if (!Object.keys(serviceApiResponse).includes("error")) {
        setIsLoading(false);
        setServices([serviceApiResponse]);
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

 
  const handleFormSubmit = async (values) => {
    // console.log("valeurs que je soumets:", values);
    const useId = currentUser?.id;
    //  console.log("user:", useId);
    const ids = serviceId.map((service, index) => service?.id);
    console.log("Idddddssss", ids);

    const submitData = {
      lastName: values.lastName,
      firstName: values.firstName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      startDate: values.startDate,
      endDate: values.endDate,
      price: totalBookingPrice,
      userId: useId,
      houseId: id,
      serviceId: ids,
    };
    console.log("submitData:", submitData);

    const apiResponse = await bookingApiRoute.bookingHouse(submitData);
    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      console.log("infos reservation:", apiResponse);

      alert.success(
        <div style={{ textTransform: "none" }}>
          Votre réservation a bien été éfectuée
        </div>
      );
    } else {
      setIsLoading(false);
      alert.error(
        <div style={{ textTransform: "none" }}>Echec de la réservation</div>
      );
    }
  };

  useEffect(() => {
    getHouseDetails();
  }, []);

  return (
    <Box>
      <Box
         mt={{ xs: 11, sm: 8, md: 6 }}
        px={{ xs: 4, sm: 7, md: 10 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h5" gutterBottom mb={4}>
          Réserver votre logement
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              {/* <CardHeader title={houseDetails.title} /> */}
              <CardContent>
                {houseDetail?.images && (
                  <CardMedia
                    component={"img"}
                    height={"140"}
                    width={"60%"}
                    image={houseDetail.images[0]}
                    alt="Réservation"
                  />
                )}
                <Typography variant="h6">Détails</Typography>
                <Typography gutterBottom variant="h7" component="div">
                  {category.name}
                </Typography>
                <Typography gutterBottom variant="h7" component="div">
                  {houseDetail.shortDescription}
                </Typography>
                <Typography gutterBottom variant="h7" component="div">
                  Lieu: {houseDetail.location}
                </Typography>
                <Typography variant="h7">
                  Prix : {houseDetail.price} FCFA
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={formSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} width="100%" maxWidth="700px">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Nom"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={!!touched.lastName && !!errors.lastName}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Prénom"
                        name="firstName"
                        value={values.firstName}
                        variant="outlined"
                        onChange={handleChange}
                        error={!!touched.firstName && !!errors.firstName}
                        helperText={touched.firstName && errors.firstName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Adresse e-mail"
                        type="email"
                        variant="outlined"
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Numéro de téléphone"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!touched.phoneNumber && !!errors.phoneNumber}
                        helperText={
                          !!touched.phoneNumber && !!errors.phoneNumber
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Période de réservation</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Date de debut</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="date"
                        name="startDate"
                        value={values.startDate}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.startDate && !!errors.startDate}
                        helperText={touched.startDate && errors.startDate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>Date de fin</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="date"
                        name="endDate"
                        value={values.endDate}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.endDate && !!errors.endDate}
                        helperText={!!touched.endDate && !!errors.endDate}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Services</InputLabel>
                        <Select
                          fullWidth
                          multiple
                          name="serviceId"
                          value={values.serviceId}
                          onChange={(e) => {
                            handleChange(e);
                            setServiceId(e.target.value);
                            console.log("selected: ", e.target.value);
                          }}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value.id} label={value.name} />
                              ))}
                            </Box>
                          )}
                        >
                          {services.map((service) => (
                            <MenuItem key={service.id} value={service}>
                              {service.name} : {service.price} FCFA
                            </MenuItem>
                          ))}

                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      spacing={1}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <Typography variant="body1">
                          Prix du logement :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {houseDetail.price} FCFA
                        </Typography>
                      </Grid>
                    </Grid>

                    {serviceId.length > 0 && (
                      <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        spacing={2}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <Typography variant="body1">
                            Prix des services
                          </Typography>
                        </Grid>
                        <Grid container item spacing={2} ml={2}>
                          {serviceId?.map((service) => (
                            <Typography variant="body1">
                              {service.name}: {service.price}
                              FCFA
                            </Typography>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                    {totalBookingPrice !== 0 && 
                    <Grid item xs={12} sm={6}>
                      <Typography>Prix total: {totalBookingPrice} FCFA</Typography>
                      {/* <TextField
                        fullWidth
                        autoFocus
                        variant="outlined"
                        name="price"
                        // value={values.price}
                        disabled
                      /> */}
                    </Grid>
                    }
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button variant="outlined" color="secondary">
                          Annuler
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                        >
                          Réserver
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BookingHouse;
