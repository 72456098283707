import { Box } from "@mui/material";
import React from "react";
import DiasporaService from "../../pages-sections/car-services/DisporaService";

const DiasporaPage = () => {

    return(
        <Box>
            <DiasporaService/>
        </Box>
    )
}

export default DiasporaPage;