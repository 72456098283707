import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  Grid,
  Button,
  Container,
  Paper,
  Divider,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import bookingApiRoute from "../../../utils/api/bookingApiRoute";
import houseApiRoutes from "../../../utils/api/houseApiRoutes";
import categoryApiRoutes from "../../../utils/api/categoryApiRoutes";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const BookingDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const [houseDetails, setHouseDetails] = useState({});
  const [category, setCategory] = useState({});
  const { id } = useParams();
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  const getBookingDetails = async () => {
    const apiResponse = await bookingApiRoute.getById(id);
    setIsLoading(false);
    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      console.log("ddddd", apiResponse);
      setBookingData({ ...apiResponse });
      console.log(apiResponse.houseId);
      const apiResponseHouse = await houseApiRoutes.getHouseById(
        apiResponse.houseId
      );
      if (!Object.keys(apiResponseHouse).includes("error")) {
        console.log("Maison", apiResponseHouse);
        setHouseDetails(apiResponseHouse);
        const categoryApiResponse = await categoryApiRoutes.getById(
          apiResponseHouse.category
        );
        if (!Object.keys(categoryApiResponse).includes("error")) {
          setIsLoading(false);
          setCategory({ ...categoryApiResponse });
          console.log("category", categoryApiResponse);
        }
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBookingDetails();
  }, []);

  return (
    <Box mt={"100px"}>
      <Container>
        <Paper sx={{ padding: 3, marginBottom: 3 }}>
          <Box sx={{ textAlign: "right" }}>
            {bookingData?.validated === true ? (
              <Button variant="contained" sx={{ margin: 1, color: "#f4511e" }}>
                Annuler la réservation
              </Button>
            ) : (
              <Button variant="contained" color="secondary" sx={{ margin: 1 }}>
                Valider la réservation
              </Button>
            )}
            {bookingData?.validated}
          </Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            {/* <Avatar
              src="https://source.unsplash.com/featured/?restaurant"
              alt={reservation.restaurant}
              sx={{ width: 56, height: 56, marginRight: 2 }}
            /> */}
            <div>
              <Typography variant="h5" gutterBottom>
                {category.name} {houseDetails.type}  {} {houseDetails.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {houseDetails.description}
              </Typography>
              <Typography variant="body2" color="textSecondary" mt={1}>
                Localisation: {houseDetails.location}
              </Typography>
            </div>
          </div>

          <Divider sx={{ margin: "16px 0" }} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {/* <Grid > */}
              <Typography variant="h5" mb={2}>
                Infos de la maison
              </Typography>
              <Typography variant="body1">
                Nombre de salon: {houseDetails.livingRoom}
              </Typography>
              <Typography variant="body1">
                Nombre de chambre : {houseDetails.bedroom}
              </Typography>
              <Typography variant="body1">
                Nombre de douche : {houseDetails.shower}
              </Typography>
              {/* </Grid> */}

              <Grid>
                <Typography variant="h5" mb={2} mt={2}>
                  Infos de la réservation
                </Typography>

                <Typography variant="body1">
                  Date de réservation:{" "}
                  {new Date(bookingData.bookingHour).toLocaleString(
                    "fr-FR",
                    options
                  )}
                </Typography>
              </Grid>

              <Grid>
                <div
                // style={{
                //   display: "flex",
                //   alignItems: "center",
                //   // marginBottom: "16px",
                // }}
                >
                  <Typography variant="body1">
                    Date de debut:{" "}
                    {new Date(bookingData.startDate).toLocaleDateString(
                      "fr-FR"
                    )}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">
                    Date de fin:{" "}
                    {new Date(bookingData.endDate).toLocaleDateString("fr-FR")}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" mb={2}>
                  Informations du client
                </Typography>
                <Typography variant="body1">
                  Nom: {bookingData.lastName}
                </Typography>
                <Typography variant="body1">
                  Prénom :{bookingData.firstName}
                </Typography>
                <Typography variant="body1">
                  Adresse E-mail : {bookingData.email}
                </Typography>
                <Typography variant="body1">
                  Téléphone : {bookingData.phoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  {/* <Icon sx={{ marginRight: 1, color: 'primary.main' }}>info</Icon> */}
                  <Typography variant="body1">
                    Statut: {bookingData.status}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid mt={3}>
            <Typography variant="h5" >
              Prix total: {bookingData.price} FCFA
            </Typography>
          </Grid>
          <Divider sx={{ margin: "16px 0" }} />
          <Grid container xs={12} md={6}>
            <Typography variant="h6">Images</Typography>
            <Card width={"300px"}>
              <Carousel
                showArrows={true}
                infiniteLoop={true}
                useKeyboardArrows={true}
                dynamicHeight={false}
              >
                {houseDetails &&
                houseDetails.images &&
                houseDetails.images.length > 0 ? (
                  houseDetails &&
                  houseDetails?.images.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image}
                        alt={`img ${index + 1}`}
                        style={{
                          width: "100%",
                          // height: '400px',
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <p>Aucune image disponible</p>
                )}
              </Carousel>
            </Card>
          </Grid>
          <Divider sx={{ margin: "16px 0" }} />
        </Paper>
      </Container>
    </Box>
  );
};

export default BookingDetails;
