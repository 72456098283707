import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CategoryModalForm from "./CategoryForm";
import categoryApiRoutes from "../../../utils/api/categoryApiRoutes";
import UpdateCategoryModalForm from "./UpdateCategory";
import { useAlert } from "react-alert";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const HouseCategory = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showCategoryModalForm, setShowCategoryModalForm] = useState(false);
  const [showUpdateCategoryForm, setShowUpdateCategoryForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const alert = useAlert();
  const [categories, setCatgories] = useState([]);

  const handleClose = () => {
    setShowCategoryModalForm(false);
  };

  const handleUpdateFormClose = () => {
    setShowUpdateCategoryForm(false);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = categories.map((category) => category.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (id) => {
    console.log("selected:", selected);
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        // Si l'ID est déjà sélectionné, on le retire (désélection)
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        // Sinon, on l'ajoute à la liste (sélection)
        return [...prevSelected, id];
      }
    });
  };

  const isSelected = (id) => selected.includes(id);
  const numSelected = selected.length;
  const rowCount = categories.length;

  const retrieveAllCategories = async () => {
    setIsLoading(true);
    const apiResponse = await categoryApiRoutes.getAllCategories();

    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      setCatgories([...apiResponse]);
    } else {
      alert.error(
        <div style={{ textTransform: "none" }}>
          {apiResponse.error ??
            "Une erreur s'est produite lors de la récupération des catégories"}
        </div>
      );
    }
  };

  useEffect(() => {
    retrieveAllCategories();
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async () => {
    console.log("Deleting selected rows: ", selected);
    setIsLoading(true);
    const apiResponse = selected.map((categoryId) =>
      categoryApiRoutes.deleteCategory(categoryId)
    );
    //  const apiResponse = await categoryApiRoutes.(selected);
    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      console.log("selected:", selected);

      console.log(apiResponse);
      alert.success("Catégorie(s) suprimées avec succès");
    } else {
      setIsLoading(false);
      alert.error("Une erreur a été rencontrée");
    }
  };

  return (
    <Box>
      <Box p={{ xs: 2, sm: 3, md: 10 }} width={"full"} mt={{ xs: 10, md: 3 }}>
        <Typography variant="h6" mb={"2"}>
          Catégories de logements
        </Typography>
        <Box p={2}>
          <Button
            aria-label="Ajouter une catégorie"
            color="secondary"
            variant="contained"
            onClick={() => setShowCategoryModalForm(true)}
          >
            Ajouter une catégorie
          </Button>
        </Box>

        <Grid flexWrap={"wrap"}>
          <Grid item md={8} xs={12}>
            <Card>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={
                              numSelected > 0 && numSelected < rowCount
                            }
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={handleSelectAll}
                          />
                        </TableCell>
                        <TableCell>Nom </TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(categories) &&
                        categories
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((category) => (
                            <TableRow key={category.id}>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isSelected(category.id)}
                                  onChange={() => handleSelect(category.id)}
                                />
                              </TableCell>
                              <TableCell>{category.name}</TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() =>
                                    setShowUpdateCategoryForm(true)
                                  }
                                  size="small"
                                >
                                  <Tooltip title="Editer">
                                    <DriveFileRenameOutlineIcon sx={{color:"#26a69a"}}/>
                                  </Tooltip>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <IconButton
                    color="red"
                    onClick={() => setOpenConfirmationModal(true)}
                    disabled={selected.length === 0}
                    size="small"
                    sx={{color:"#f44336"}}
                  >
                    <DeleteIcon fontSize="small" sx={{color:"#f44336"}}/>
                    Supprimer
                  </IconButton>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={categories.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              </Paper>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {openConfirmationModal ? (
        <DeleteCategoryModal
          open={openConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
          onSubmit={handleDelete}
        />
      ) : (
        ""
      )}

      {showCategoryModalForm && (
        <CategoryModalForm
          open={showCategoryModalForm}
          handleClose={handleClose}
        />
      )}

      {/* <Box>  */}
      {showUpdateCategoryForm && (
        <UpdateCategoryModalForm
          open={showUpdateCategoryForm}
          handleClose={handleUpdateFormClose}
        />
      )}
    </Box>
  );
};

export default HouseCategory;

export const DeleteCategoryModal = ({ open, onClose, onSubmit }) => {
  const handleConfirmDelete = () => {
    onSubmit(true);
    onClose();
  };
  return (
    <Box>
      <Dialog open={open}>
        <DialogContent>
          <Typography variant="h6" textAlign={"center"} mb={4}>
            Confirmation de suppréssion
          </Typography>
          <Typography>Voulez-vous supprimer cette catégorie?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            sx={{ backgroundColor: "#f4511e" }}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
