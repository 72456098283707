import { Box } from "@mui/material";
import React from "react";
import BookingHouse from "../../pages-sections/booking/BookingHouse";

const BookingPage = () => {
    return(
        <Box>
            <BookingHouse/>
        </Box>
    )
}

export default BookingPage;