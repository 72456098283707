import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import fileApiRoute from "../../../utils/api/fileApiRoute";
import categoryApiRoutes from "../../../utils/api/categoryApiRoutes";
import serviceApiRoute from "../../../utils/api/serviceApiRoute";
import InputLocationSearch from "../../../components/Location/InputLocationSearch";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, PhotoCamera } from "@mui/icons-material";
import houseApiRoutes from "../../../utils/api/houseApiRoutes";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { GoogleMap, Marker } from "@react-google-maps/api";
import throttle from "lodash/throttle";
const autocompleteService1 = { current: null };




const UpdateHouseForm = () => {
  const [bedroom, setBedroom] = useState(1);
  const [shower, setShower] = useState(1);
  const [livingRoom, setLivingRoom] = useState(1);
  const [type, setType] = useState("");
  const [price, setPrice] = useState(null);
  // const [trueCategory, settrueCategory] = useState("")
  const [status, setStatus] = useState("");
  const [location, setlocation] = useState("");
  const [season, setSeason] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [processInProgress, setProcessInProgress] = useState(false);
  const [categories, setCategories] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [service, setService] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [unit, setUnit] = useState("");
  const [generator, setGenerator] = useState(false);

  //Chauffage
  const [heating, setHeating] = useState(false);
  const [rooftop, setRooftop] = useState(false);
  const [internet, setInternet] = useState(false);
  //piscine
  const [pool, setPool] = useState(false);

  //Jardin
  const [garden, setGarden] = useState(false);
  const [parking, setParking] = useState(false);
  const [toilet, setToilet] = useState(1);
  const [areaValue, serAreaValue] = useState(1);
  const [waterTank, setWaterTank] = useState(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  // AUTOCOMPLETE PLACE API
  const [coordinates, setCoordinates] = useState({});
  const [locationName, setLocationName] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [searchLngLat, setSearchLngLat] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [options, setOptions] = useState([]);
  const alert = useAlert();
  const navigate = useNavigate();
  const [selectedPlace, setSelectedPlace] = useState(null);

 const id = useParams()
   const categoryObject = (id) => {
    return {
      id: id,
    };
  };

  const serviceObject = (id) => {
    return {
      id,
    };
  };

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
    setImages(Array.from(event.target.files));
  };

  const handleRemoveFile = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  const uploadImage = async () => {
    console.log("fileeee:", files);

    let urls = [];

    setIsLoading(true);

    for (let i = 0; i < files.length; i++) {
      const fileApiResponse = await fileApiRoute.uploadFile(files[i]);
      console.log("file: ", fileApiResponse);

      if (!Object.keys(fileApiResponse).includes("error")) {
        setIsLoading(true);
        console.log("fileResponseee:", fileApiResponse);

        urls.push(fileApiResponse);
      } else {
        setIsLoading(true);
        alert.error(<div> Echec de l'enregistrement des image</div>);
      }
      setImagesUrl(urls);
    }

    console.log("imagesssss: ", urls);

    return urls;
  };

  const handleFormSubmit = async (values) => {
    console.log("values: ", values);

    const selectedIdCategory = values.category;
    const selectedCategoryObject = categoryObject(selectedIdCategory);
    console.log("Catégorie",selectedCategoryObject);
    console.log("locationnnnn", locationName);
    
    const selectedServiceOject = serviceObject(values.service);

    const imagesResponse = await uploadImage();
    // console.log("imageresponse:", imagesResponse);

    // if (imagesResponse.length > 0) {
      // console.log("imageresponse:", imagesResponse);
      console.log("Valeurs de générateur", values.heating, values.generator);
      
      const dataToSubmit = {
        bedroom: values.bedroom,
        shower: values.shower,
        livingRoom: values.livingRoom,
        toilet: values.toilet,
        unit: values.unit,
        generator: values.generator,
        heating: values.heating,
        rooftop: values.rooftop,
        waterTank: values.waterTank,
        areaValue: values.areaValue,
        garden: values.garden,
        parking: values.parking,
        category: selectedCategoryObject,
        price: values.price,
        title: values.title,
        type: values.type,
        description: values.description,
        // location: locationName.name,
        service: selectedServiceOject,
        // images: imagesResponse,
      };

      setIsLoading(true);
      const houseApiResponse = await houseApiRoutes.updateHouse(id.id, dataToSubmit);
      console.log(values);

      if (!Object.keys(houseApiResponse).includes("error")) {
        setIsLoading(false);
        alert.success("Mise à du logement réussit");

        // navigate("/immo/all-house");
      } else {
        setIsLoading(false);
        alert.error("Une érreur a été rencontrée");
      }
    // } else {
    //   alert.error("Veuillez ajouter une image");
    // }
    // setImagesUrl([]);
    // setFiles([]);
    // setDescription("");
    // setTitle("");
    // setSelectedServiceId("");
  };

  const retrieveAllCategories = async () => {
    setIsLoading(true);
    const apiResponse = await categoryApiRoutes.getAllCategories();

    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      setCategories([...apiResponse]);
      console.log(apiResponse);
    } else {
      alert.error(
        <div style={{ textTransform: "none" }}>
          {apiResponse.error ??
            "Une erreur s'est produite lors de la récupération des catégories"}
        </div>
      );
    }
  };

  const retrieveAllServices = async () => {
    setIsLoading(true);
    const apiResponse = await serviceApiRoute.getAllService();

    setIsLoading(false);
    if (!Object.keys(apiResponse).includes("error")) {
      setServiceData([...apiResponse]);
      // console.log(apiResponse);
    } else {
      alert.error(
        <div style={{ textTransform: "none" }}>
          {apiResponse.error ??
            "Une erreur s'est produite lors de la récupération des services"}
        </div>
      );
    }
  };

  const [houseInfos, setHouseInfos] = useState ({
    bedroom:0,
    shower:0,
    livingRoom:0,
    category: "",
    price:0,
    unit:"",
    toilet:0,
    title:"",
    type:"",
    description:"",
    generator: false,
    heating: false,
    waterTank: false,
    rooftop: false,
    pool: false,
    garden: false,
    parking: false,
    internet: false,
    location: "",
    areaValue: 0,
    service: "",
    // images: [],
  })

  const formSchema = yup.object().shape({
    // name: yup.string().required("Le nom est requis"),
    category: yup.string().required("La catégorie est requise"),
    title: yup.string().required("Un petite description est obligatoire"),
    description: yup.string().required(""),
    // location: yup.string().required("La localisation est requise"),
    // price: yup.number().required("Le logement doit avoir un prix"),
    service: yup.string().required("Le service est requis"),
    type: yup.string().required("La catégorie est requise"),
    unit: yup.string().required("L'unité est requis"),
    // files: yup.
  });

  const loadDataPage = async () => {
    setProcessInProgress(true);
    await retrieveAllCategories();
    await retrieveAllServices();
    setProcessInProgress(false);
  };

  const handleSelect = (location) => {
    // setLocationName(location?.description)

    // Get latitude and longitude via utility function
    getGeocode({ address: location?.description })
      .then((result) => getLatLng(result[0]))
      .then(({ lat, lng }) => {
        setCoordinates({ lat, lng });
      })
      .catch((error) => {});
  };

  const fetch1 = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService1.current
          .getPlacePredictions(request, callback)
          .then((r) => {});
      }, 200),
    []
  );
  const getHouseInfos = async() => {
    setIsLoading(true)
    console.log(id.id);

    const apiResponse = await houseApiRoutes.getHouseById(id.id)

    if(!Object.keys(apiResponse).includes("error")){
      console.log('HouseInfos', apiResponse);
      setIsLoading(false)

      alert.success(
        <div style={{ textTransform: 'none' }}>
          Récupération des informations de la msaison
        </div>
      )
      setHouseInfos(apiResponse)

    }else{
      setIsLoading(false)
      alert.success(
        <div style={{ textTransform: 'none' }}>
          Une erreur est survenue
        </div>
      )
    }
  }
  useEffect(() => {
    loadDataPage();
    getHouseInfos()
  }, []);

  // handle place change on search
  // const handlePlaceChanged = value => {
  //   setSelectedPlace(true)
  //   geoCodePlaceId(value?.place_id)
  // }

  // const geoCodePlaceId = placeId => {
  //   if (placeId) {
  //     const geocoder = new google.maps.Geocoder()
  //     geocoder
  //       .geocode({ placeId })
  //       .then(({ results }) => {
  //         if (results[0]) {
  //           setCoordinates(results[0].geometry.location)
  //           setSearchLngLat({
  //             lat: results[0].geometry.location.lat(),
  //             lng: results[0].geometry.location.lng()
  //           })
  //         } else {
  //           alert.error(
  //             <div style={{ textTransform: 'none' }}>
  //               "Aucun résultat trouvé"
  //             </div>
  //           )
  //         }
  //       })
  //       .catch(e => {
  //         alert.error(
  //           <div style={{ textTransform: 'none' }}>
  //             "Un problème est survenu."
  //           </div>
  //         )
  //       })
  //     setCurrentLocation(null)
  //   }
  // }
  return (
    <>
      <Container width={"full"}>
        <Box p={{ xs: 3, sm: 3, md: 9 }} mt={{ xs: 5, sm: 8, md: "30px" }}>
          <Typography variant="h6" gutterBottom>
            Ajouter un logement
          </Typography>
          <Card>
            {processInProgress ? (
              "Loading"
            ) : (
              <Formik
              enableReinitialize
                initialValues={houseInfos}
                validationSchema={formSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} p={{ xs: 2, md: 4 }}>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel >
                            Type
                          </InputLabel>
                          <Select
                            label="Type"
                            name="category"
                            value={values.category}
                            // value={}age
                            helpertext={touched.type && errors.type}
                            error={!!touched.type && !!errors.type}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setSelectedCategoryId(event.target.value);
                              console.log("id", event.target.value);
                              const catId = event.target.value;
                              const selectedCate = categories.find(
                                (category) => category.id === catId
                              );
                              setSelectedCategoryName(
                                selectedCate
                                  ? selectedCate.name.toLowerCase()
                                  : ""
                              );
                              handleChange(event);
                            }}
                            size="small"
                          >
                            {categories &&
                              categories?.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                  {category.name}
                                </MenuItem>
                              ))}
                            {}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="title"
                          value={values.title}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!touched.title && !errors.title}
                          helperText={touched.title && errors.title}
                          fullWidth
                          size="small"
                          placeholder="Titre"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Catégorie
                          </InputLabel>
                          <Select
                            name="type"
                            value={values.type}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={!!touched.type && !errors.type}
                            helperText={touched.type && errors.type}
                            fullWidth
                            size="small"
                            displayEmpty
                            label="Catégorie"
                            //inputProps={{ "aria-label": "Type" }}
                          >
                            <MenuItem value="Meublé">Meublé</MenuItem>
                            <MenuItem value="Non meublé">Non meublé</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="description"
                          value={values.description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!touched.description && !errors.description}
                          helperText={touched.description && errors.description}
                          fullWidth
                          size="small"
                          placeholder="Description"
                          variant="outlined"
                          multiline
                          rows={3}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          type="number"
                          name="price"
                          value={values.price}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          size="small"
                          placeholder="Prix"
                          error={!!touched.price && !errors.price}
                          helpertext={touched.price && errors.price}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Unité
                          </InputLabel>
                          <Select
                            name="unit"
                            value={values.unit}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                            size="small"
                            placeholder="Unité"
                            error={!!touched.unit && !errors.unit}
                            helpertext={touched.unit && errors.unit}
                            variant="outlined"
                          >
                            <MenuItem value={"Par heure"}>Par heure</MenuItem>
                            <MenuItem value={"Par jour"}>Par jour</MenuItem>
                            <MenuItem value={"Par semaine"}>
                              Par semaine
                            </MenuItem>
                            <MenuItem value={"Par mois"}>Par mois</MenuItem>
                            <MenuItem value={"Par année"}>Par année</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}> */}
                      {/* <InputLocationSearch
                        parentCallback={(value) => {
                          setLocationName(value);
                        }}
                      /> */}
                      {console.log("LOCATION", locationName.name)}
                      {/* </Grid> */}
                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          name="location"
                          value={values.location}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!touched.location && !errors.location}
                          helpertext={touched.location && errors.location}
                          fullWidth
                          size="small"
                          placeholder="Emplacement"
                          variant="outlined"
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel >
                            Chambre
                          </InputLabel>
                          <Select
                            name="bedroom"
                            value={values.bedroom}
                            fullWidth
                            size="small"
                            label="Chambre"
                            onBlur={handleBlur}
                            error={!!touched.bedroom && !!errors.bedroom}
                            helpertext={touched.bedroom && errors.bedroom}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel >
                            Douche
                          </InputLabel>
                          <Select
                            name="shower"
                            value={values.shower}
                            fullWidth
                            size="small"
                            label="Douche"
                            onBlur={handleBlur}
                            error={!!touched.shower && !!errors.shower}
                            helpertext={touched.shower && errors.shower}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Toilette
                          </InputLabel>
                          <Select
                            name="toilet"
                            value={values.toilet}
                            fullWidth
                            size="small"
                            label="toilette"
                            onBlur={handleBlur}
                            error={!!touched.toilet && !!errors.toilet}
                            helpertext={touched.toilet && errors.toilet}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Salon
                          </InputLabel>
                          <Select
                            name="livingRoom"
                            value={values.livingRoom}
                            fullWidth
                            size="small"
                            label="Salon"
                            onBlur={handleBlur}
                            error={!!touched.livingRoom && !!errors.livingRoom}
                            helpertext={touched.livingRoom && errors.livingRoom}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Service
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="service"
                            value={values.service}
                            fullWidth
                            size="small"
                            label="Service"
                            onBlur={handleBlur}
                            error={!!touched.service && !!errors.service}
                            helpertext={touched.service && errors.service}
                            onChange={(event) => {
                              setSelectedServiceId(event.target.value);
                              handleChange(event);
                              console.log("idd", event.target.value);
                            }}
                          >
                            {serviceData &&
                              serviceData?.map((service) => (
                                <MenuItem key={service.id} value={service.id}>
                                  {service.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="generator"
                              checked={values.generator}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              color="primary"
                            />
                          }
                          label="Groupe électrogène"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="heating"
                              checked={values.heating}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              color="primary"
                            />
                          }
                          label="Chauffage"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="rooftop"
                              checked={values.rooftop}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              color="primary"
                            />
                          }
                          label="Terrasse"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="waterTank"
                              checked={values.waterTank}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              color="primary"
                            />
                          }
                          label="Reserve d'eau"
                        />
                      </Grid>{" "}
                      {values.type === "Meublé" && (
                        <>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="pool"
                                  checked={values.pool}
                                  onChange={handleChange}
                                />
                              }
                              label="Piscine"
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="internet"
                                  checked={values.internet}
                                  onChange={handleChange}
                                />
                              }
                              label="Internet"
                            />
                          </Grid>
                        </>
                      )}
                      {selectedCategoryName === "appartement" && (
                        <>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="parking"
                                  checked={values.parking}
                                  onChange={handleChange}
                                />
                              }
                              label="Parking"
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="garden"
                                  checked={values.garden}
                                  onChange={handleChange}
                                />
                              }
                              label="Jardin"
                            />
                          </Grid>
                        </>
                      )}
                      {selectedCategoryName === "local commercial" && (
                        <>
                          <Grid item xs={12} sm={3}>
                            <Typography>Superficie</Typography>
                            <TextField
                              type="number"
                              name="areaValue"
                              value={values.areaValue}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                              size="small"
                              placeholder="Superficie"
                              error={!!touched.areaValue && !errors.areaValue}
                              helpertext={touched.areaValue && errors.areaValue}
                              variant="outlined"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={12}>
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<PhotoCamera />}
                          sx={{ mb: 2 }}
                        >
                          <input
                            type="file"
                            multiple
                            name="images"
                            // value={values.images}
                            onBlur={handleBlur}
                            ml={3}
                            onChange={handleFileChange}
                          />
                          {/* Ajouter des images
                          <input
                            type="file"
                            multiple
                            name="images"
                            value={values.images}
                            onBlur={handleBlur}
                            ml={3}
                            // hidden
                            onChange={handleFileChange}
                          /> */}
                        </Button>
                        <Grid container spacing={2}>
                          {files.map((file, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                              <Card sx={{ position: "relative" }}>
                                <CardMedia
                                  component="img"
                                  height="140"
                                  image={file.preview}
                                  alt={`Preview ${index + 1}`}
                                />
                                <CardContent>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {file.name}
                                  </Typography>
                                </CardContent>
                                <IconButton
                                  onClick={() => handleRemoveFile(index)}
                                  // sx={{ position: "absolute", top: 8, right: 8 }}
                                >
                                  <Delete />
                                </IconButton>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                        >
                          Soumettre
                        </Button>
                      </Grid>
                    </Grid>
                  </form>

                  // <input
                  //   type="file"
                  //   multiple
                  //   name="images"
                  //   value={values.images}
                  //   onBlur={handleBlur}
                  //   ml={3}
                  //   style={{
                  //     marginTop: "10rem",
                  //   }}
                  //   // hidden
                  //   onChange={handleFileChange}
                  // />
                )}
                {/* <button
                onClick={async () => {
                  console.log();
                  await uploadImage();
                }}
              >Test</button> */}
              </Formik>
            )}
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default UpdateHouseForm;
