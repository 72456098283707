import { useEffect, useMemo, useState } from "react";

import {
  Box,
  Checkbox,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import bookingApiRoute from "../../../utils/api/bookingApiRoute";

const ValidatedBookings = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [validatedBookings, setValidateBooking] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const alert = useAlert();
  const navigate = useNavigate();

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const retrieveValidetedBooking = async () => {
    setIsLoading(true);

    const responseApi = await bookingApiRoute.getAllValidetedBookings();
    // console.log(responseApi);
    if (!Object.keys(responseApi).includes("error")) {
    //   console.log(responseApi);
      setValidateBooking([...responseApi]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alert("Une erreur est survenue");
    }
  }

  const handleCancelBooking = async (id) => {
    setIsLoading(true);
    console.log("Id de la la reservation annulée:", id);

    const apiResponse = await bookingApiRoute.cancelBooking(id);
    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      alert.success(
        <div style={{ textTransform: "none" }}>
          Réservation annulée avec succès
        </div>
      );
    } else {
      setIsLoading(false);
      alert.error(
        <div style={{ textTransform: "none" }}>
          impossible d' annuler cette réservation
        </div>
      );
    }
  };
  useEffect(() => {
    retrieveValidetedBooking()
  }, []);


  return (
    <Box>
      <Container>
        <Box p={{ xs: 3, sm: 3, md: 8 }} mt={{ xs: 5, sm: 8, md: "40px" }}>
          <Typography variant="h6" gutterBottom>
            Réservations confirmées
          </Typography>
          {/* <MaterialReactTable table={table} /> */}
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < houses.length
                      }
                      checked={
                        houses.length > 0 && selected.length === houses
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell> */}
                    <TableCell>Nom</TableCell>
                    <TableCell>Prénom</TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>Prix</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Date</TableCell>
                    {/* <TableCell>Publication</TableCell> */}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {validatedBookings 
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((booking) => (
                      <TableRow key={booking.id}>
                        {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={selected.indexOf(booking.id) !==-1}
                          onChange={() => handleSelect(booking.id)}
                        />
                      </TableCell> */}
                        <TableCell>{booking.lastName}</TableCell>
                        <TableCell>{booking.firstName}</TableCell>
                      <TableCell>{booking.phoneNumber}</TableCell>

                        <TableCell>
                          {booking.price}
                          {}
                          {"FCFA"}
                        </TableCell>
                        <TableCell>{booking.status}</TableCell>
                      <TableCell>{new Date(booking.bookingHour).toLocaleDateString("fr-FR")}</TableCell>

                        {/* <TableCell>{formattedDate}</TableCell> */}
                        {/* <TableCell>{booking.publishStatus}</TableCell> */}

                        <TableCell>
                          <IconButton
                            // onClick={() => console.log("Edit item", house.id)}
                            onClick={() => handleCancelBooking(booking.id)}
                           
                            size="small"
                            sx={{ color: "#f44336" }}
                          >
                            Annuler
                          </IconButton>
                          <IconButton
                            // onClick={() => console.log("Edit item", house.id)}
                            // onClick={() => publishHouses(house.id)}
                            size="small"
                          >
                            Détails
                          </IconButton>
                          {/* <IconButton
                          onClick={() => console.log("Delete item", house.id)}
                        >
                          <DeleteIcon sx={{color:"#ff3d00"}} fontSize="small"/>
                        </IconButton> */}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
            >
              {/* <IconButton
              color="red"
              // onClick={handleDelete}
              // disabled={selected.length === 0}
              size="small"
            >
              <DeleteIcon fontSize="small"/>
               Supprimer */}
              {/* </IconButton> */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
               count={validatedBookings.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};


export default ValidatedBookings;
