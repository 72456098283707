import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    
    const userInfo = localStorage.getItem('userInfo');
    const token = localStorage.getItem('authToken');
    if (userInfo && token) {
      setIsAuthenticated(true);
      setCurrentUser(JSON.parse(userInfo));
      console.log("data: ", token);
      console.log("data: ", userInfo);

    }else{
        setIsAuthenticated(false)
    }
  }, []);

//   const login = (userData) => {
//     localStorage.setItem('userInfo', JSON.stringify(userData));
//     setCurrentUser(userData);
//     setIsAuthenticated(true);
//   };

  const logout = () => {
    localStorage.removeItem('userInfo');
    setCurrentUser(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ currentUser, isAuthenticated,
    //  login, 
     logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);