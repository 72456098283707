import axios from "axios";
import { url } from "../constants";

const categoryUrl = url + "api/category";

const createCategory = async (category) => {
  let response = {};
  await axios
    .post(categoryUrl + "/create", category)
    .then((res) => {
      response = res?.data;
    })
    .catch((error) => {
      response = { error: error.response?.data.message };
    });
  return response;
};

const getAllCategories = async () => {
  let response = {};
  await axios
    .get(categoryUrl + "/getAll")
    .then((res) => {
      response = res?.data;
    })
    .catch((error) => {
      response = { error: error.response?.data.message };
    });
  return response;
};

const deleteCategory = async (id) => {
  let response = {};
  await axios
    .delete(categoryUrl + "/delete", {
      params: { id: id }
    })
    .then((res) => {
      response = res?.data;
    })
    .catch((error) => {
      response = { error: error.response?.data.message };
    });
  return response;
};

const getById = async (id) => {
 let response = {}
 await axios.get(categoryUrl + `/getById/`+ id)
 .then((res) => {
  response = res?.data;
 })
 .catch((errors) => {
  response = {error: errors.response?.data.message?? ''}
 })

 return response
}

const update = async (id, category) => {

  let response =  {}
  await axios.put(categoryUrl + "/update/" + id, category)
  .then((res => {
    response = res?.data
  }))
  .catch((errors) => {
    response = {error : errors.response?.data.message?? ''}
  })
  return response
}

export default {
  createCategory,
  getAllCategories,
  deleteCategory,
  getById,
  update
};
