import { Box } from "@mui/material";
import React from "react";
import HouseCategory from "../../../pages-sections/manager/houseCategories/HouseCategory";
const HouseCategoryPage = () => {
  return (
    <Box>
      <HouseCategory />
    </Box>
  );
};

export default HouseCategoryPage;
