// import React, { useState } from "react";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Grid,
//   Container,
//   Paper,
// } from "@mui/material";

// import { Formik } from "formik";
// import * as yup from "yup";
// import { useAlert } from "react-alert"
// import contactApiRoute  from "../../utils/api/contactApiRoute"
// // import contactA

// const _initialValues = {};
// const ContactPage = () => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [message, setMessage] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const alert = useAlert();

//   const initialValues = {
//     name: name,
//     email: email,
//     phoneNumber: phoneNumber,
//     message: message,
//   };

//   const formSchema = yup.object().shape({
//     name: yup.string().required("Nom requis"),
//     email: yup.string("Email requis").email().required(" Email invalide "),
//     message: yup.string().required("Message requis")
//   })
//   const handleFormSubmit = async (values) => {
//     setIsLoading(true);
//     console.log(values);

//     let apiResponse = await contactApiRoute.saveContact(values)
//     if (!Object.keys(apiResponse).includes("error")){
//       setIsLoading(false)
//       alert.success(
//         <div style={{ textTransform: "none" }}>
//           Votre message a bien été envoyé
//         </div>
//       )
//       console.log("Formulaire soumis", values);
//     } else {
//       setIsLoading(false)
//       alert.error(
//         <div style={{ textTransform: "none" }}>
//           {apiResponse.error ?? "Message non envoyé"}
//         </div>
//       )
//     }
//   };

//   return (
//     <Container
//     //  maxWidth="md"
//     >
//       <Box sx={{ py: 8 }} px={{xs:2, sm:3, md:4}}>
//         <Box>
//           <Typography variant="h5" p={5}>
//             NOUS JOINDRE
//           </Typography>
//         </Box>
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           // height="100vh"
//         >
//           <Grid container spacing={4}>
//             {/* Informations de contact */}
//             <Grid item xs={12} md={4}>
//               <Paper elevation={3} sx={{ padding: 3, height: "89%" }}>
//                 <Typography variant="h5" gutterBottom>
//                   Informations de Contact
//                 </Typography>
//                 <Typography variant="body1" paragraph>
//                   <strong>Adresse:</strong>
//                   <br />
//                   146 Rue 1185 Koumassi, Bonapriso, Douala, Cameroun
//                 </Typography>
//                 <Typography variant="body1" paragraph>
//                   <strong>Téléphone:</strong>
//                   <br />
//                   +237 676 45 67 89
//                 </Typography>
//                 <Typography variant="body1" paragraph>
//                   <strong>Email:</strong>
//                   <br />
//                   contact@immo.com
//                 </Typography>
//               </Paper>
//             </Grid>
//             <Grid item xs={12} md={8}>
//               <Formik
//               initialValues={_initialValues || initialValues}
//               validationSchema={formSchema}
//               onSubmit={handleFormSubmit}
//               >
//                 {({
//                   values,
//                   errors,
//                   handleChange,
//                   handleBlur,
//                   handleSubmit,
//                   touched,
//                 }) => (
//                   <Paper elevation={3} sx={{ padding: 3 }}>
//                     <Typography variant="h5" gutterBottom>
//                       Contactez-nous
//                     </Typography>
//                     <form onSubmit={handleSubmit}>
//                       <Grid container spacing={2}>
//                         <Grid item xs={12} sm={6}>
//                           <TextField
//                             fullWidth
//                             label="Name"
//                             name="name"
//                             values={values.name}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             variant="outlined"
//                             errors={!!touched.name && !!errors.name}
//                             helperText={touched.name && errors.name}
//                           />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                           <TextField
//                             fullWidth
//                             label="Adresse e-mail"
//                             type="email"
//                             variant="outlined"
//                             name="email"
//                             values={values.email}
//                             onBlur={handleBlur}
//                             onChange={handleChange}
//                             error={!!touched.name && !!errors.email}
//                             helperText={touched.name && errors.email}
//                           />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                           <TextField
//                             fullWidth
//                             label="Numéro de téléphone"
//                             variant="outlined"
//                             onBlur={handleBlur}
//                             name="phoneNumber"
//                             values={values.phoneNumber}
//                             onChange={handleChange}
//                             error={
//                               !!touched.phoneNumber && !!errors.phoneNumber
//                             }
//                             helperText={
//                               touched.phoneNumber && errors.phoneNumber
//                             }
//                             // required
//                           />
//                         </Grid>
//                         <Grid item xs={12}>
//                           <TextField
//                             fullWidth
//                             label="Message"
//                             name="message"
//                             multiline
//                             rows={4}
//                             variant="outlined"
//                             onBlur={handleBlur}
//                             values={values.message}
//                             onChange={handleChange}
//                             error={!!touched.message && !!errors.message}
//                             helperText={touched.message && errors.message}
//                             // required
//                           />
//                         </Grid>
//                         <Grid item xs={12}>
//                           <Button
//                             type="submit"
//                             variant="contained"
//                             color="primary"
//                             fullWidth
//                           >
//                             Envoyer
//                           </Button>
//                         </Grid>
//                       </Grid>
//                     </form>
//                   </Paper>
//                 )}
//               </Formik>
//             </Grid>
//           </Grid>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default ContactPage;

import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import {
  Phone as PhoneIcon,
  Mail as MailIcon,
  LocationOn as LocationIcon,
  AccessTime as TimeIcon,
} from "@mui/icons-material";
import { googleApiKey } from "../../utils/constants";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { RoomRounded } from "@mui/icons-material";
import { useAlert } from "react-alert";

import { Formik } from "formik";
import * as yup from "yup";
import contactApiRoute from "../../utils/api/contactApiRoute";

const ContactPage = () => {
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [clientSubject, setClientSubject] = useState("");
  const [service, setService] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();
  const handleMarkerClick = () => {
    setIsOpen(!isOpen);
  };

  const initialValues = {
    name: name,
    email: email,
    phoneNumber: phoneNumber,
    message: message,
    clientSubject: clientSubject,
    service: service,
  };

  const formSchema = yup.object().shape({
    name: yup.string().required("Nom requis"),
    email: yup.string("Email requis").email().required(" Email invalide "),
    message: yup.string().required("Message requis"),
  });
  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    console.log(values);

    let apiResponse = await contactApiRoute.saveContact(values);
    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      alert.success(
        <div style={{ textTransform: "none" }}>
          Votre message a bien été envoyé
        </div>
      );
      console.log("Formulaire soumis", values);
    } else {
      setIsLoading(false);
      alert.error(
        <div style={{ textTransform: "none" }}>
          {apiResponse.error ?? "Message non envoyé"}
        </div>
      );
    }
  };

  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  //   service: "",
  //   subject: "",
  //   message: "",
  // });

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };



  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const baliDoualaCoords = {
    lat: 4.0478, // Latitude de Bali, Douala
    lng: 9.6973, // Longitude de Bali, Douala
  };

  const markerIcon = {
    path: RoomRounded.path,
    fillColor: "red",
    fillOpacity: 1,
    strokeWeight: 1,
    strokeColor: "#ffffff",
    scale: 2,
  };
  const parisCoords = {
    lat: 48.8566, // Latitude de départ (par exemple : Paris)
    lng: 2.3522, // Longitude de départ
  };
  // const googleApiKey = "AIzaSyBewJ2x5COtaqlSdrt8DdKObNP_rRWCEEE";
  return (
    <Container maxWidth="lg">
      <Box pt={"90px"} px={{ xs: 1, sm: 1, md: 1 }}>
        <Typography variant="h5" align="left" mb={4}>
          Contactez-nous
        </Typography>
        <Typography variant="p" align="left">
          Nous sommes là pour répondre à toutes vos questions et vous aider dans
          vos projets immobiliers.
        </Typography>

        <Grid container spacing={4} mt={1}>
          <Formik
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
            }) => (
              <Grid item xs={12} md={12}>
                <Card elevation={3}>
                  <CardContent>
                    <Typography variant="h5" gutterBottom color={"#26a69a"}>
                      Formulaire de Contact
                    </Typography>
                    <form onSubmit={handleSubmit}>
                      <TextField
                        fullWidth
                        label="Nom complet"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        margin="normal"
                        onBlur={handleBlur}
                        variant="outlined"
                        errors={!!touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        size="small"
                      />
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={!!touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        margin="normal"
                        size="small"
                      />
                      <TextField
                        fullWidth
                        label="Téléphone"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        margin="normal"
                        onBlur={handleBlur}
                        errors={!!touched.phoneNumber && !!errors.phoneNumber}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        size="small"
                      />
                      <FormControl fullWidth margin="normal" required>
                        <InputLabel>Service concerné</InputLabel>
                        <Select
                          name="service"
                          value={values.service}
                          onBlur={handleBlur}
                          errors={!!touched.service && !!errors.service}
                          helperText={touched.service && errors.service}
                          size="small"
                          onChange={handleChange}
                        >
                          <MenuItem value="location">Location</MenuItem>
                          <MenuItem value="construction">Construction</MenuItem>
                          <MenuItem value="amenagement">Aménagement</MenuItem>
                          <MenuItem value="diaspora">Diaspora</MenuItem>
                          <MenuItem value="autre">Autre</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        fullWidth
                        label="Sujet"
                        name="clientSubject"
                        value={values.clientSubject}
                        onChange={handleChange}
                        margin="normal"
                        onBlur={handleBlur}
                        errors={!!touched.clientSubject && !!errors.clientSubject}
                        helperText={touched.clientSubject && errors.clientSubject}
                        size="small"
                      />
                      <TextField
                        fullWidth
                        label="Message"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={!!touched.message && !!errors.message}
                        helperText={touched.message && errors.message}
                        margin="normal"
                        multiline
                        rows={4}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        // color="#ff5722"
                        size="large"
                        sx={{ mt: 2, backgroundColor: "#f4511e" }}
                      >
                        Envoyer
                      </Button>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Formik>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card elevation={3}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Bureau au cameroun
                    </Typography>
                    <Box display="flex" alignItems="center" mb={1}>
                      <LocationIcon sx={{ mr: 1, color: "#26a69a" }} />
                      <Typography>
                        146 Rue 1185 Koumassi, Bonapriso, Douala, Cameroun
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <PhoneIcon sx={{ mr: 1, color: "#26a69a" }} />
                      <Typography>+237 2 34 56 78 90</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <MailIcon sx={{ mr: 1, color: "#26a69a" }} />
                      <Typography>@servicesimmobiliers.com</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <TimeIcon sx={{ mr: 1, color: "#26a69a" }} />
                      <Typography>Lundi - Vendredi: 9h - 18h</Typography>
                    </Box>
                  </CardContent>
                </Card>
                <Box mt={3}>
                  <LoadScript googleMapsApiKey={googleApiKey}>
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={baliDoualaCoords}
                      zoom={12}
                    >
                      {/* Ajout d'un marqueur */}
                      {window.google && window.google.maps && (
                        <Marker
                          position={baliDoualaCoords}
                          icon={markerIcon}
                          onClick={handleMarkerClick}
                        />
                      )}
                      {isOpen && (
                        <InfoWindow
                          position={baliDoualaCoords}
                          onCloseClick={() => setIsOpen(false)}
                        >
                          <div>
                            <h3>
                              146 Rue 1185 Koumassi, Bonapriso, Douala, Cameroun
                            </h3>
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  </LoadScript>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card elevation={3}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Bureau de France
                    </Typography>
                    <Box display="flex" alignItems="center" mb={1}>
                      <LocationIcon sx={{ mr: 1, color: "#26a69a" }} />
                      <Typography>
                        123 Rue de l'Immobilier, 75000 Paris, France
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <PhoneIcon sx={{ mr: 1, color: "#26a69a" }} />
                      <Typography>+33 1 23 45 67 89</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <MailIcon sx={{ mr: 1, color: "#26a69a" }} />
                      <Typography>@servicesimmobiliers.com</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <TimeIcon sx={{ mr: 1, color: "#26a69a" }} />
                      <Typography>Lundi - Vendredi: 9h - 18h</Typography>
                    </Box>
                  </CardContent>
                </Card>
                <Box mt={3}>
                  <LoadScript googleMapsApiKey={googleApiKey}>
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={parisCoords}
                      zoom={12}
                    >
                      {window.google && window.google.maps && (
                        <Marker
                          position={parisCoords}
                          icon={markerIcon}
                          onClick={handleMarkerClick}
                        />
                      )}
                      {isOpen && (
                        <InfoWindow
                          position={parisCoords}
                          onCloseClick={() => setIsOpen(false)}
                        >
                          <div>
                            <h3>Paris, France</h3>
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  </LoadScript>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid constainer xs={12} md={12} py={5}> 
        <LoadScript googleMapsApiKey={googleApiKey}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={12}
          > */}
        {/* Ajout d'un marqueur */}
        {/* <Marker position={center} />
          </GoogleMap>
        </LoadScript> */}
        {/* </Grid> */}
      </Box>
    </Container>
  );
};

export default ContactPage;
