import { Box } from "@mui/material"
import React from "react"
import HouseList from "../pages-sections/house/HouseList"
import HouseRentalServices from "../pages-sections/car-services/Home1"
import Home1 from "../pages-sections/car-services/Home1"
const Welcome = () => {
    return(
        <Box>
            <Home1/>
            {/* <HouseList/> */}
        </Box>
    )
}

export default Welcome