import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
  IconButton,
  Link,
  InputAdornment,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import authApiRoute from "../../utils/api/authApiRoute";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as yup from "yup";

const __initValues = {};

const handleGoogleSignIn = (response) => {
  console.log("Google sign-in response:", response);
  // Ajoutez ici la logique pour traiter la connexion avec Google
};

const SignUp = ({ validationSchema, initData }) => {
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const alert = useAlert();
  const navigate = useNavigate();

  const initialValues = {
    lastName,
    firstName,
    phoneNumber,
    email,
    password,
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formSchema = yup.object().shape({
    lastName: yup.string().required("Nom requis"),
    firstName: yup.string().required("Prénom requis"),
    phoneNumber: yup.string().required("Numéro de téléphone requis"),
    email: yup.string().email("Email requis").required("Email invalide"),
    password: yup
      .string()
      .required("Mot de passe requis")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&amp;])[A-Za-z\d@$!%*#?&amp;]{8,}$/,
        "Le mot de passe doit contenir au moins 8 caractères, Une majuscule, une minuscule, un nombre et un caractère spécial"
      ),
  });

  const handleFormSubmit = async (values) => {
    setIsLoading(true);

    console.log(values);

    const registerApiResponse = await authApiRoute.createAccount(values);
    console.log(registerApiResponse);
    if (!Object.keys(registerApiResponse).includes("error")) {
      setIsLoading(false);
      //console.log(registerApiResponse);
      //let user = registerApiResponse?.data;
      //setCurrenUser({ ...user });
      alert.success("Votre compte a bien été crée");
      navigate("/login");
    } else {
      setIsLoading(false);
      alert.error("Une erreur est survenue");
    }
    // setIsLoggedIn(true);
  };

  return (
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        padding={2}
      >
        <Card
          sx={{
            padding: 3,
            maxWidth: 400,
            width: "100%",
            boxShadow: 3,
          }}
        >
          <Typography variant="h5" textAlign={"center"} gutterBottom>
            Création de Compte
          </Typography>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={__initValues || initialValues}
            validationSchema={formSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  autoFocus
                  margin="normal"
                  label="Nom"
                  name="lastName"
                  value={values.lastName}
                  fullWidth
                  variant="outlined"
                  size="small"
                  //required
                  // onChange={(event) => setLastName(event.target.value)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!touched.lastName && !!errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                />
                <TextField
                  autoFocus
                  margin="normal"
                  label="Prénom"
                  name="firstName"
                  value={values.firstName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onBlur={handleBlur}
                  //required
                  // onChange={(event) => setFirstName(event.target.value)}
                  onChange={handleChange}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
                <TextField
                  autoFocus
                  margin="normal"
                  label="Adresse e-mail"
                  type="email"
                  fullWidth
                  variant="outlined"
                  name="email"
                  size="small"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  autoFocus
                  margin="normal"
                  label="Téléphone"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  fullWidth
                  variant="outlined"
                  size="small"
                  onBlur={handleBlur}
                  //required
                  onChange={handleChange}
                  error={!!touched.phoneNumber && !!errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
                <TextField
                  margin="normal"
                  id="password"
                  label="Mot de passe"
                  //type="password"
                  fullWidth
                  size="small"
                  variant="outlined"
                  onBlur={handleBlur}
                  //required
                  value={values.password}
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                />
                <Box display={"flex"} alignItems={"center"} gap={1} mt={2}>
                  <Typography component="h6">
                    Vous avez déja un compte?
                  </Typography>
                  <Link href="/login">Se connectez!</Link>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={2}
                  mt={2}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate("/")}
                  >
                    Annuler
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Créer un compte
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          {/* <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={4}
          >
            <Typography variant="body2" gutterBottom>
              Ou créez un compte avec Google
            </Typography>
            <GoogleLogin
              onSuccess={handleGoogleSignIn}
              onFailure={(error) => console.log(error)}
              render={(renderProps) => (
                <IconButton onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <GoogleIcon />
                </IconButton>
              )}
            />
          </Box> */}
        </Card>
      </Box>
    </GoogleOAuthProvider>
  );
};

export default SignUp;
