import { Box, Typography } from "@mui/material";
import React from "react";

const CarServicePage = () => {
  return (
    <Box p={6}>
      <Box>
        <Typography variant="h4"> Voitures dispobible</Typography>
        
      </Box>
    </Box>
  );
};

export default CarServicePage;
