import React from "react";
import BookingServiceForm from "../../../pages-sections/manager/bookingServices/BookingServiceForm";
import { Box } from "@mui/material";


const AddServicepage = () => {

    return(
        <Box>
            <BookingServiceForm/>
        </Box>
    )
}

export default AddServicepage;