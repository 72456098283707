import {
  AppBar,
  Box,
  Button,
  CardMedia,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchFilters from "../../components/searchBar/SearchFilter";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  People as PeopleIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const BannerBox = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "200px",
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    height: "300px",
  },
}));

// const BannerImage = styled(CardMedia)({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "100%",
//   height: "100%",
//   objectFit: "cover",
// });

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState("");
  const location = useLocation();


  const buttonStyle = {
    "&:hover": {
      color: "#f4511e", // Couleur orange au survol
      backgroundColor: "transparent",
    },
  };

  const isActive = (path) => location.pathname === path;
  
  const activeStyle = {
    color: "#f4511e", 
    backgroundColor: "transparent",
    borderBottom: "2px solid #f4511e",
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "#26a69a", borderBottom: "3px solid #e8491d" ,zIndex: 3,}}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            House Finder
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerClose}
              >
                <List>
                  <ListItem button component="a" href="/" sx={
                  isActive("/design-service") ? activeStyle : buttonStyle
                }>
                    <ListItemText primary="Accueil" / >
                  </ListItem>
                  <ListItem button component="a" href="/home-page">
                    <ListItemText primary="Location" />
                  </ListItem>
                  <ListItem button component="a" href="/constuction-service">
                    <ListItemText primary="Construction" />
                  </ListItem>
                  <ListItem button component="a" href="/design-service">
                    <ListItemText primary="Amenagement" />
                  </ListItem>
                  <ListItem button component="a" href="/diaspora-service">
                    <ListItemText primary="Diaspora" />
                  </ListItem>
                  {/* <ListItem button onClick={handleClick}>
                    <ListItemText primary="Services sup" />
                    <ArrowDropDownIcon />
                  </ListItem>
                  <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                      onClick={handleClose}
                      component="a"
                      href="/coworking-services"
                    >
                      Coworking
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      component="a"
                      href="/other-service"
                    >
                      Loisir
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      component="a"
                      href="/immo/dashboard"
                    >
                      Location voitures
                    </MenuItem>
                  </Menu> */}
                  <ListItem button component="a" href="/contact">
                    <ListItemText primary="Contact" />
                  </ListItem>
                  <ListItem button component="a" href="/login">
                    <ListItemText primary="Connexion" />
                  </ListItem>
                </List>
              </Drawer>
            </>
          ) : (
            <>
              <Button
                href="/"
                color="inherit"
                sx={
                  isActive("/") ? activeStyle : buttonStyle
                }
              >
                Accueil
              </Button>
              <Button
                color="inherit"
                href="/home-page"

                sx={
                  isActive("/home-page") ? activeStyle : buttonStyle
                }
              >
                Location
              </Button>
              <Button
                color="inherit"
                href="/constuction-service"
                sx={
                  isActive("/constuction-service") ? activeStyle : buttonStyle
                }
              >
                Construction
              </Button>
              <Button color="inherit" href="/design-service" sx={
                  isActive("/design-service") ? activeStyle : buttonStyle
                }>
                Amenagement
              </Button>
              <Button color="inherit" href="/diaspora-service" sx={ isActive("/diaspora-service") ? activeStyle : buttonStyle}>
                Diaspora
              </Button>
              <Button color="inherit" href="/projects" sx={ isActive("/projects") ? activeStyle : buttonStyle}>
                Nos projets
              </Button>
              {/* <Button
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ color: "white" }}
              >
                Services sup
                <ArrowDropDownIcon sx={{ color: "white" }} />
              </Button> */}
              {/* <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <MenuItem onClick={handleClose}>
                  <a
                    href="/coworking-services"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Coworking
                  </a>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <a
                    href="/other-service"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Loisir
                  </a>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <a
                    href="/#"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Location voitures
                  </a>
                </MenuItem>
              </Menu> */}
              <Button color="inherit" href="/contact" sx={isActive("/contact") ? activeStyle : buttonStyle}>
                Contact
              </Button>
              <Button
                color="inherit"
                href="/login"
                backgroundColor="transparent"
                sx={isActive("/login") ? activeStyle : buttonStyle}
              >
                Connexion
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
