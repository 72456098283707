import React from "react"

import CoworkingService from "../../pages-sections/coworking-service/Coworking"
import { Box } from "@mui/material"

const CoworkingServicesPage = () => {

return(
    <Box>
        <CoworkingService/>
    </Box>
)
}

export default CoworkingServicesPage