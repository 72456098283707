import {
  Box,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import bookingApiRoute from "../../utils/api/bookingApiRoute";
import { useAuth } from "../../contexts/AuthenticationContext";
import { useNavigate } from "react-router-dom";

const BookingClient = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [userBookings, setUserBookings] = useState([]);
  const alert = useAlert();
  const [validatedBookings, setValidateBookings] = useState([]);
    const {currentUser} = useAuth()
  // const [currentUser, setCurrentUser] = useState({});
  const navigate = useNavigate();

  // const getUserInfo = () => {
  //   const userInfo = localStorage.getItem("userInfo");
  //   if (userInfo) {
  //     setCurrentUser(JSON.parse(userInfo));
  //   } else {
  //     return null;
  //   }
  // };

  // useEffect(() => {
  //   getUserInfo();
  // }, []);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const collectBookingByUserId = async () => {
    const userId = currentUser?.id;
    console.log("userId", userId);
    if (currentUser && currentUser?.id) {
      const responseApi = await bookingApiRoute.getBookingByUser(
        currentUser?.id
      );
      console.log(responseApi);
      if (!Object.keys(responseApi).includes("error")) {
        console.log(responseApi);
        setUserBookings([...responseApi]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        alert("Une erreur est survenue");
      }
    }
  };

  useEffect(() => {
    collectBookingByUserId();
  }, []);

  return (
    <Box>
      <Container>
        <Box p={{ xs: 3, sm: 3, md: 8 }} mt={{ xs: 5, sm: 8, md: "40px" }}>
          <Typography variant="h6" gutterBottom>
            Toutes mes réservations
          </Typography>
          {/* <MaterialReactTable table={table} /> */}
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < houses.length
                      }
                      checked={
                        houses.length > 0 && selected.length === houses
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell> */}
                    <TableCell>Date de debut</TableCell>
                    <TableCell>Date de fin</TableCell>
                    <TableCell>Prix</TableCell>
                    <TableCell>Status</TableCell>

                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userBookings
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((userBooking) => (
                      <TableRow key={userBooking.id}>
                        <TableCell>{userBooking.startDate}</TableCell>
                        <TableCell>{userBooking.endDate}</TableCell>

                        <TableCell>
                          {userBooking.price}
                          {}
                          {"FCFA"}
                        </TableCell>
                        <TableCell>{userBooking.status}</TableCell>
                        <TableCell>
                          <IconButton
                            // onClick={() => console.log("Edit item", house.id)}
                            size="small"
                          >
                            Annuler
                          </IconButton>
                          <IconButton
                            // onClick={() => console.log("Edit item", house.id)}
                            // onClick={() => publishHouses(house.id)}
                          onClick={() => navigate(`/immo/book-details/${userBooking.id}`)}
                            size="small"
                          >
                            Détails
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={userBookings.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default BookingClient;
