import { Box } from "@mui/material";
import React from "react";
import BookingForm from "../../../pages-sections/manager/bookings/BookingForm";


const AddBookingPage = () => {

    return(
        <Box>
            <BookingForm/>
        </Box>
    )
}

export default AddBookingPage;