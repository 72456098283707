import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Checkbox,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import bookingApiRoute from "../../../utils/api/bookingApiRoute";
import DeleteIcon from "@mui/icons-material/Delete";

const AllBookings = () => {
  //data and fetching state
  const [bookingData, setBookingData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const alert = useAlert();
  const navigate = useNavigate();

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getBookingHouse = async () => {
    setIsLoading(true);

    const responseApi = await bookingApiRoute.getAllBookings();
    console.log(responseApi);
    if (!Object.keys(responseApi).includes("error")) {
      console.log(responseApi);
      setBookingData([...responseApi]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alert("Une erreur est survenue");
    }
  };

  const date = new Date(bookingData.bookingHour);

  // Extraire le jour, le mois et l'année
  const day = String(date.getDate()).padStart(2, "0"); // Ajouter un 0 devant si nécessaire
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0 en JS
  const year = date.getFullYear();

  // Construire la date au format jour/mois/année
  const formattedDate = `${day}/${month}/${year}`;

  const validateBooking = async (id) => {
    setIsLoading(true);
    console.log("Id de la la reservation avalider:", id);

    const apiResponse = await bookingApiRoute.validateBooking(id);
    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      alert.success(
        <div style={{ textTransform: "none" }}>
          Réservation validée avec succès
        </div>
      );
    } else {
      setIsLoading(false);
      alert.error(
        <div style={{ textTransform: "none" }}>
          Impossible de valider cette réservation
        </div>
      );
    }
  };

  const handleCancelBooking = async (id) => {
    setIsLoading(true);
    console.log("Id de la la reservation annulée:", id);

    const apiResponse = await bookingApiRoute.cancelBooking(id);
    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      alert.success(
        <div style={{ textTransform: "none" }}>
          Réservation annulée avec succès
        </div>
      );
    } else {
      setIsLoading(false);
      alert.error(
        <div style={{ textTransform: "none" }}>
          impossible d' annuler cette réservation
        </div>
      );
    }
  };

  useEffect(() => {
    getBookingHouse();
  }, []);
  //if you want to avoid useEffect, look at the React Query example instead
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!data.length) {
  //       setIsLoading(true);
  //     } else {
  //       setIsRefetching(true);
  //     }

  //     const url = new URL(
  //       "/api/data",
  //       process.env.NODE_ENV === "production"
  //         ? "https://www.material-react-table.com"
  //         : "http://localhost:3000"
  //     );
  //     url.searchParams.set(
  //       "start",
  //       `${pagination.pageIndex * pagination.pageSize}`
  //     );
  //     url.searchParams.set("size", `${pagination.pageSize}`);
  //     url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
  //     url.searchParams.set("globalFilter", globalFilter ?? "");
  //     url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

  //     try {
  //       const response = await fetch(url.href);
  //       const json = await response.json();
  //       setData(json.data);
  //       setRowCount(json.meta.totalRowCount);
  //     } catch (error) {
  //       setIsError(true);
  //       console.error(error);
  //       return;
  //     }
  //     setIsError(false);
  //     setIsLoading(false);
  //     setIsRefetching(false);
  //   };
  //   fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   columnFilters,
  //   globalFilter,
  //   pagination.pageIndex,
  //   pagination.pageSize,
  //   sorting,
  // ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "firstName",
        header: "First Name",
      },
      //column definitions...
      {
        accessorKey: "lastName",
        header: "Last Name",
      },
      {
        accessorKey: "address",
        header: "Address",
      },
      {
        accessorKey: "state",
        header: "State",
      },
      {
        accessorKey: "phoneNumber",
        header: "Phone Number",
      },
    ],
    []
  );

  return (
    <Container>
      <Box p={{ xs: 3, sm: 3, md: 8 }} mt={{ xs: 5, sm: 8, md: "40px" }}>
        <Typography variant="h6" gutterBottom>
          Toutes les réservations
        </Typography>
        {/* <MaterialReactTable table={table} /> */}
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < houses.length
                      }
                      checked={
                        houses.length > 0 && selected.length === houses
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell> */}
                  <TableCell>Nom</TableCell>
                  <TableCell>Prénom</TableCell>
                  <TableCell>Téléphone</TableCell>
                  <TableCell>Prix</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                  {/* <TableCell>Publication</TableCell> */}

                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookingData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((booking) => (
                    <TableRow key={booking.id}>
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={selected.indexOf(booking.id) !==-1}
                          onChange={() => handleSelect(booking.id)}
                        />
                      </TableCell> */}
                      <TableCell>{booking.lastName}</TableCell>
                      <TableCell>{booking.firstName}</TableCell>
                      <TableCell>{booking.phoneNumber}</TableCell>
                      <TableCell>
                        {booking.price} {}{"FCFA"}
                      </TableCell>
                    <TableCell>{booking.status}</TableCell>
                      <TableCell>{new Date(booking.bookingHour).toLocaleDateString("fr-FR")}
                      </TableCell>
                      {/* <TableCell>{booking.publishStatus}</TableCell> */}
                      <TableCell>
                        {booking.validated === true ? (
                          <IconButton
                            onClick={() => handleCancelBooking(booking.id)}
                            size="small"
                            sx={{ color: "#f44336" }}
                          >
                            Annuler
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => validateBooking(booking.id)}
                            size="small"
                            sx={{ color: "#009688" }}
                          >
                            Valider
                          </IconButton>
                        )}
                        {/* <IconButton
                          onClick={() => validateBooking(booking.id)}
                          size="small"
                        >
                          Valider
                        </IconButton> */}
                        <IconButton
                          // onClick={() => console.log("Edit item", house.id)}
                          // onClick={() => publishHouses(house.id)}
                          onClick={() =>
                            navigate(`/immo/booking-details/${booking.id}`)
                          }
                          size="small"
                        >
                          Détails
                        </IconButton>
                        {/* <IconButton
                          onClick={() => console.log("Delete item", house.id)}
                        >
                          <DeleteIcon sx={{color:"#ff3d00"}} fontSize="small"/>
                        </IconButton> */}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <IconButton
              color="red"
              // onClick={handleDelete}
              // disabled={selected.length === 0}
              size="small"
            >
              {/* <DeleteIcon fontSize="small" /> */}
              {/* Supprimer */}
            </IconButton>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={bookingData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default AllBookings;
