import React from "react";
import SignUp from "../pages-sections/sessions/SignUp";
import { Box } from "@mui/material";

const SignUpPage = () => {

    return(
        <Box>
            <SignUp/>
        </Box>
    )
}

export default  SignUpPage;