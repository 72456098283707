import axios from "axios";
import { url } from "../constants";

const fileUrl = url + "api/file";

const uploadFile = async (file) => {
  let response = {};
  const formData = new FormData();
  formData.append("file", file);

  // axios.defaults.withXSRFToken = true;

  await axios
    .post(fileUrl + "/upload", formData)
    .then((res) => {
      console.log("result: ", res);
      console.log("result: ", res?.data);

      response = res.data;
    })
    .catch((error) => {
      console.log("error: ", error);
      console.log("error 1: ", error?.data);

      response = { error: error.response?.data.message };
    });

  return response;
};

export default {
  uploadFile,
};
