import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  IconButton,
  useMediaQuery,
  useTheme,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/system";
// import { Close as CloseIcon } from 'lucide-react';
import CloseIcon from "@mui/icons-material/Close";
import {
  ArrowBack,
  ArrowBackIos,
  ArrowForward,
  ArrowForwardIos,
  AspectRatio,
} from "@mui/icons-material";
import { Carousel } from "react-responsive-carousel";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  CarouselContext,
} from "pure-react-carousel";
// Définition des couleurs personnalisées
const customColors = {
  primary: "#50b3a2",
  secondary: "#e8491d",
  background: "#f4f4f4",
};

// Styles personnalisés
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(to right, ${customColors.primary}, #6ec6b8)`,
  borderBottom: `3px solid ${customColors.secondary}`,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  "&:hover": {
    fontWeight: "bold",
  },
}));

const ShowcaseSection = styled("section")(({ theme }) => ({
  minHeight: "400px",
  backgroundImage:
    'url("https://images.unsplash.com/photo-1631679706909-1844bbd07221?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80")',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  textAlign: "center",
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  paddingTop: "56.25%",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  },
}));
const StyledCardMediaModal = styled(CardMedia)(({ theme }) => ({
  paddingTop: "5%", // 16:9 aspect ratio
  height: "600px",
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ModalContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  outline: "none",
  // maxWidth: "90%",
  width: "75%",
  maxHeight: "90%",
  overflowY: "auto",
}));

// Composant principal
const AmenagementService = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [modalOpen, setModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const scrollRef = useRef(null);
  const [modalImageIndex, setModalImageIndex] = useState(0);

  const projects = [
    {
      id: "salon-moderne",
      title: "Salon Moderne",
      description:
        "Réaménagement complet d un salon pour un style contemporain",
      images: ["assets/rooftop.jpg", "assets/open_space.jpg"],
    },
    {
      id: "cuisine-ouverte",
      title: "Cuisine Ouverte",
      description: "Création d une cuisine ouverte fonctionnelle et élégante",
      images: [
        "https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
        "/assets/rooftop.jpg",
      ],
    },
    {
      id: "terrasse-zen",
      title: "Terrasse Zen",
      description:
        "Aménagement d une terrasse avec espace détente et jardin zen",
      images: ["/assets/rooftop.jpg", "/assets/rooftop.jpg"],
    },
  ];

  const handleOpenModal = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProject(null);
  };

  const nextProject = () => {
    setCurrentProject((prev) => (prev + 1) % projects.length);
    setCurrentImage(0);
  };

  const prevProject = () => {
    setCurrentProject((prev) => (prev - 1 + projects.length) % projects.length);
    setCurrentImage(0);
  };

  const nextImage = () => {
    setCurrentImage(
      (prev) => (prev + 1) % projects[currentProject].images.length
    );
  };

  const prevImage = () => {
    setCurrentImage(
      (prev) =>
        (prev - 1 + projects[currentProject].images.length) %
        projects[currentProject].images.length
    );
  };

  const [imageIndex, setImageIndex] = useState(
    projects.map(() => 0) // Initialiser les index d'image pour chaque propriété
  );

  const handlePrevModalImage = () => {
    setModalImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedProject.images.length - 1 : prevIndex - 1
    );
  };

  const handleNextModalImage = () => {
    setModalImageIndex((prevIndex) =>
      prevIndex === selectedProject.images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const handlePrevImage = (index) => {
    setImageIndex((prevState) =>
      prevState.map((imgIndex, i) =>
        i === index
          ? imgIndex === 0
            ? projects[index].images.length - 1
            : imgIndex - 1
          : imgIndex
      )
    );
  };

  const handleNextImage = (index) => {
    setImageIndex((prevState) =>
      prevState.map((imgIndex, i) =>
        i === index
          ? imgIndex === projects[index].images.length - 1
            ? 0
            : imgIndex + 1
          : imgIndex
      )
    );
  };

  const services = [
    {
      title: "Design d'Intérieur",
      description:
        "Créez un intérieur qui reflète votre style et votre personnalité avec notre expertise en design d'intérieur.",
      image: "https://images.unsplash.com/photo-1586023492125-27b2c045efd7",
    },
    {
      title: "Rénovation",
      description:
        "Donnez une nouvelle vie à votre espace avec nos services de rénovation complets et sur mesure.",
      image: "https://images.unsplash.com/photo-1533090161767-e6ffed986c88",
    },
    {
      title: "Aménagement Extérieur",
      description:
        "Transformez votre espace extérieur en un havre de paix avec nos services d'aménagement paysager.",
      image: "/assets/amenagement01.jpg",
    },
  ];

  const processSteps = [
    "Consultation initiale et évaluation des besoins",
    "Conception et présentation du projet",
    "Sélection des matériaux et des finitions",
    "Planification détaillée et calendrier des travaux",
    "Exécution des travaux d’aménagement",
    "Contrôle qualité et finitions",
    "Livraison du projet et visite finale",
    "Service après-vente et suivi",
  ];

  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);

  const scroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -300 : 300;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const autoScroll = setInterval(() => {
        if (
          container.scrollLeft + container.clientWidth >=
          container.scrollWidth
        ) {
          container.scrollTo({ left: 0, behavior: "smooth" });
        } else {
          container.scrollBy({ left: 1, behavior: "smooth" });
        }
      }, 50);

      return () => clearInterval(autoScroll);
    }
  }, []);
  const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    if (!images || images.length === 0) {
      return (
        <Box className="w-full h-64 flex items-center justify-center bg-gray-200">
          <Typography>Aucune image disponible</Typography>
        </Box>
      );
    }
    const goToPrevious = () => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };

    const goToNext = () => {
      const isLastSlide = currentIndex === images.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };
    return (
      <Box className="relative w-full h-64">
        {/* <IconButton
          onClick={goToPrevious}
          className="absolute left-2 top-1/2 transform -translate-y-1/2 z-10"
        >
          <ArrowBackIos />
        </IconButton>
        <IconButton
          onClick={goToNext}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10"
        >
          <ArrowForwardIos />
        </IconButton> */}
        {/* <IconButton
        onClick={goToPrevious}
        //className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 transition-opacity duration-300 opacity-0 group-hover:opacity-100"
        style={{ zIndex: 10 }}
      >
        <ArrowBackIos />
      </IconButton>
      <IconButton
        onClick={goToNext}
        //className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 transition-opacity duration-300 opacity-0 group-hover:opacity-100"
        style={{ zIndex: "50px" }}
      >
        <ArrowForwardIos />
      </IconButton>
      <StyledCardMediaModal
        component="img"
        image={images[currentIndex]}
        alt={`Slide ${currentIndex + 1}`}
      /> */}
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          useKeyboardArrows={true}
          dynamicHeight={false}
          showThumbs={true} // Supprime les miniatures si vous n'en avez pas besoin
          autoPlay={true} // Optionnel, si vous voulez que les slides changent automatiquement
          // sx={{
          //   '.thumbs-wrapper': {
          //     display: 'flex',
          //     justifyContent: 'center',
          //     margin: '0 auto',
          //   },
          //   '.thumbs': {
          //     display: 'flex',
          //     justifyContent: 'center',
          //   },
          // }}
          className="carousel"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          {images?.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  // height: "500px", // Ajustez la hauteur en fonction de vos besoins
                }}
              />
            </div>
          ))}
        </Carousel>
        {/* <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          // className="w-full h-full object-contain"
          // style={{width:"700px", height:"800px"}}
        />
        <Card>
          <CardMedia
            image={images[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
          />
        </Card> */}
      </Box>
    );
  };

  // const sliderRef = useRef(null);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (sliderRef.current) {
  //       // Passe à la diapositive suivante
  //       sliderRef.current.next();
  //     }
  //   }, 3000); // Changer toutes les 3 secondes

  //   return () => clearInterval(interval); // Nettoie l'intervalle lorsque le composant est démonté
  // }, []);
  const sliderRef = useRef(null);

  // Hook pour le défilement automatique
  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.next(); // Passe à la diapositive suivante
      }
    }, 3000); // Changer toutes les 3 secondes

    return () => clearInterval(interval); // Nettoie l'intervalle lorsque le composant est démonté
  }, []);

  return (
    <>
      {/* <StyledAppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <span style={{ color: customColors.secondary, fontWeight: "bold" }}>
              Services
            </span>{" "}
            Immobiliers
          </Typography>
          {!isMobile && (
            <>
              <StyledButton color="inherit">Accueil</StyledButton>
              <StyledButton color="inherit">Location</StyledButton>
              <StyledButton color="inherit">Construction</StyledButton>
              <StyledButton color="inherit">Aménagement</StyledButton>
              <StyledButton color="inherit">Diaspora</StyledButton>
              <StyledButton color="inherit">Contact</StyledButton>
            </>
          )}
        </Toolbar>
      </StyledAppBar> */}

      <ShowcaseSection>
        <Box>
          <Typography variant="h3" component="h1" gutterBottom>
            Services d'Aménagement
          </Typography>
          <Typography variant="h5" component={"p"}>
            Transformez votre espace en un lieu unique et fonctionnel
          </Typography>
        </Box>
      </ShowcaseSection>
      <Container maxWidth="lg" style={{ marginTop: theme.spacing(6) }}>
        <Typography variant="h5" align="left" mb={3}>
          Nos Services d'Aménagement
        </Typography>
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledCard>
                <StyledCardMedia image={service.image} title={service.title} />
                <CardContent>
                  <Typography
                    mb={2}
                    variant="h6"
                    component="h2"
                    color={"#26a69a"}
                  >
                    {service.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    textAlign={"justify"}
                  >
                    {service.description}
                  </Typography>
                </CardContent>
                <Box p={2} mt="auto">
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#f4511e" }}
                    size="small"
                    href="/contact"
                  >
                    En savoir +
                  </Button>
                </Box>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        <Box my={8}>
          {/* <Typography variant="h5" gutterBottom align="center">
            Pourquoi Nous Choisir ?
          </Typography> */}
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <img
                src="/assets/amenagement.jpg"
                alt="Équipe d'aménagement professionnelle"
                style={{ width: "100%", height: "auto", borderRadius: "6px" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom ml={1}>
                Pourquoi Nous Choisir ?
              </Typography>
              <ul>
                <ListItem>
                  <li>Équipe de designers et d'architectes expérimentés</li>
                </ListItem>
                <ListItem>
                  <li>Approche personnalisée pour chaque projet</li>
                </ListItem>
                <ListItem>
                  <li>Respect des délais et du budget</li>
                </ListItem>
                <ListItem>
                  <li>Service client exceptionnel</li>
                </ListItem>
                <ListItem>
                  <li>Garantie sur tous nos travaux</li>
                </ListItem>
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={4} alignItems="center" py={5}>
          {/* Section Content */}
          <Grid item xs={12} md={6}>
            <div style={{ textAlign: "left" }}>
              <Typography
                variant="h5"
                component="h2"
                style={{ marginBottom: "20px", color: "#333" }}
              >
                Notre Processus d'Aménagement
              </Typography>
              <ol disablePadding>
                {processSteps.map((step, index) => (
                  <li key={index} style={{ padding: "4px" }}>
                    {step}
                  </li>
                ))}
              </ol>
            </div>
          </Grid>

          {/* Section Image */}
          <Grid item xs={12} md={6}>
            <img
              src="./assets/amenagement5.jpg"
              alt="Processus d'aménagement"
              style={{
                width: "100%",
                height: "345px",
                borderRadius: "6px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              }}
              // style={{ width: "100%", height: "auto", borderRadius: "6px" }}
            />
          </Grid>
        </Grid>

        <Box my={8}>
          <Typography
            variant="h4"
            gutterBottom
            textAlign={{ xs: "center", md: "left" }}
          >
            Nos Projets Réalisés
          </Typography>

          <Grid container spacing={4}>
            {projects.map((project, index) => (
              <Grid item xs={12} sm={6} md={4} key={project.id}>
                <Card
                  sx={{
                    maxWidth: 345,
                    margin: "20px auto",
                    "&:hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                    },
                  }}
                  onClick={() => handleOpenModal(project)}
                >
                  <Box sx={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      height="250"
                      image={project.images[imageIndex[index]]}
                      alt={project.title}
                    />
                    {/* <IconButton
                      onClick={() => handlePrevImage(index)}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "0",
                        transform: "translateY(-50%)",
                        color: "white",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <ArrowBackIos />
                    </IconButton> */}
                    {/* <IconButton
                      onClick={() => handleNextImage(index)}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        right: "0",
                        transform: "translateY(-50%)",
                        color: "white",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <ArrowForwardIos />
                    </IconButton> */}
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" textAlign={"justify"}>
                      {project.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md">
            <DialogContent>
              {selectedProject && (
                // selectedProject.images &&
                // selectedProject.images.length > 0 ?
                <Box sx={{ textAlign: "center", position: "relative" }}>
                  <IconButton
                    onClick={handlePrevModalImage}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "0",
                      transform: "translateY(-50%)",
                      color: "white",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <ArrowBackIos />
                  </IconButton>

                  <img
                    src={selectedProject.images[modalImageIndex]}
                    alt={selectedProject.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "80vh",
                      borderRadius: 8,
                    }}
                  />
                  {console.log(
                    "Image URL:",
                    selectedProject.images[modalImageIndex]
                  )}
                  <IconButton
                    onClick={handleNextModalImage}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: "0",
                      transform: "translateY(-50%)",
                      color: "white",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <ArrowForwardIos />
                  </IconButton>

                  <Typography variant="h6" mt={2}>
                    {selectedProject.title}
                  </Typography>
                </Box>
                // ) : (
                //   <Typography variant="h6" align="center">
                //     Pas d'image disponible
                //   </Typography>
              )}
            </DialogContent>
          </Dialog>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            padding: "2rem",
            borderRadius: "12px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* Image */}
          <Box
            component="img"
            src="./assets/cuisine_luxe1.jpg"
            // src="./assets/design-interieur-cuisine.jpg"
            alt="Consultation d'aménagement"
            sx={{
              width: { xs: "100%", md: "40%" },
              height: "350px",
              borderRadius: "12px",
              marginBottom: { xs: "1.5rem", md: 0 },
              marginRight: { md: "2rem" },
            }}
          />

          {/* Content */}
          <Box
            sx={{ textAlign: { xs: "center", md: "left" }, maxWidth: "500px" }}
          >
            <Typography variant="h5" mb={4}>
              Prêt à Transformer Votre Espace ?
            </Typography>
            <Typography variant="p" paragraph mb={4} textAlign={"justify"}>
              Contactez-nous dès aujourd'hui pour une consultation gratuite et
              commencez votre projet d'aménagement.
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#f4511e" }}
              size="large"
              href="/contact"
            >
              Nous Contacter
            </Button>
          </Box>
        </Box>

        {/* <Box my={8} textAlign="center">
          <Typography variant="h5" gutterBottom>
            Prêt à Transformer Votre Espace ?
          </Typography>
          <Typography variant="p" paragraph>
            Contactez-nous dès aujourd'hui pour une consultation gratuite et
            commencez votre projet d'aménagement.
          </Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#f4511e" }}
            size="large"
          >
            Nous Contacter
          </Button>
        </Box> */}
      </Container>

      <StyledModal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="project-modal-title"
        aria-describedby="project-modal-description"
      >
        <ModalContent>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            style={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedProject && (
            <>
              <Typography id="project-modal-title" variant="h6" component="h2">
                {selectedProject.title}
              </Typography>
              {/* <img
                src={selectedProject.image}
                alt={selectedProject.title}
                style={{ width: "100%", marginTop: theme.spacing(2) }}
              /> */}
              <Typography
                id="project-modal-description"
                style={{ marginTop: theme.spacing(2) }}
              >
                {selectedProject.description}
              </Typography>
              <ImageCarousel images={selectedProject.images} />
            </>
          )}
        </ModalContent>
      </StyledModal>
    </>
  );
};

export default AmenagementService;
