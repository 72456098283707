import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  CssBaseline,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  useMediaQuery,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  People as PeopleIcon,
  Settings as SettingsIcon,
  ExpandLess,
  ExpandMore,
  AccountCircle,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import RoomServiceIcon from '@mui/icons-material/RoomService';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
const drawerWidth = 270; // Width of the drawer

const Sidebar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
 const [open, setOpen] = useState(false)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const [currentUser, setCurrentUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const getUserInfo = () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      setIsAuthenticated(true)
      setCurrentUser(JSON.parse(userInfo));
    } else {
      return null;
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  const handleSubmenuClick = (menu) => {
    setOpenSubmenu(openSubmenu === menu ? null : menu);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
;
  const drawerBgColor = "#333"; 
  const appBarBgColor = drawerBgColor;

  const handleLogout = () => {
    localStorage.removeItem('userInfo');
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex", bgcolor: '#f5f5f5'}} >
      <CssBaseline />
      {/* { isAuthenticated &&
       } */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: theme.palette.primary.greyWhite, 
          color: theme.palette.primary.greyPureBlack, 
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Acceuil
          </Typography>
          <Box ml={4}>
            <Button href="/" variant="outlined" style={{ whiteSpace: 'nowrap' }} color="secondary">
              Aller sur le site
            </Button>
            </Box>
          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <Typography>Role</Typography> */}
            <IconButton
              sx={{
                color: "secondary",
                marginLeft: "auto",
              }}
              onClick={handleClick}
            >
              <AccountCircle fontSize="large" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem 
               onClick={()=> navigate("/immo/profile")}
              >Mon profil</MenuItem>
              <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
            </Menu>
          </Box>

        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: theme.palette.primary.greyWhite, 
            color: theme.palette.primary.greyPureBlack,
            borderRight: "none",
          },
        }}
        anchor="left"
      >
          <List 
           
          >
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Accueil" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/immo/dashboard")}
          >
            <ListItemIcon>
              <SpaceDashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
            {/* {openSubmenu === 'settings' ? <ExpandLess /> : <ExpandMore />} */}
          </ListItem>
          <ListItem button onClick={() => handleSubmenuClick("users")} mt="2">
            <ListItemIcon>
              <MapsHomeWorkIcon />
            </ListItemIcon>
            <ListItemText primary="Logements" />
            {openSubmenu === "users" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSubmenu === "users"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/immo/house-categories">
                <ListItemText primary="Catégories de logements" sx={{ pl: 8 }} />
              </ListItem>
              <ListItem button component={Link} to="/immo/all-house">
                <ListItemText primary="Liste des logements" sx={{ pl: 8 }} />
              </ListItem>
              <ListItem button component={Link} to="/immo/publish-house">
                <ListItemText primary="Logements publiés" sx={{ pl: 8 }} />
              </ListItem>
              <ListItem button component={Link} to="/immo/create-house">
                <ListItemText primary="Ajouter un logement" sx={{ pl: 8 }} />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => handleSubmenuClick("userrs")} mt="2">
            <ListItemIcon>
              <RoomServiceIcon />
            </ListItemIcon>
            <ListItemText primary="Services" />
            {openSubmenu === "userrs" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSubmenu === "userrs"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/immo/booking-services">
                <ListItemText primary="Liste des services" sx={{ pl: 8 }} />
              </ListItem>
              <ListItem button component={Link} to="/immo/add-service">
                <ListItemText primary="Ajouter un service" sx={{ pl: 8 }} />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => handleSubmenuClick("booking")} mt="2">
            <ListItemIcon>
              <BookmarkAddIcon />
            </ListItemIcon>
            <ListItemText primary="Reservations" />
            {openSubmenu === "list" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSubmenu === "booking"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/immo/all-bookings">
                <ListItemText primary="Toutes les réservations" sx={{ pl: 8 }} />
              </ListItem>
              <ListItem button component={Link} to="/immo/validated-bookings">
                <ListItemText primary="Réservations validées" sx={{ pl: 8 }} />
              </ListItem>
              <ListItem button component={Link} to="/immo/add-bookings">
                <ListItemText primary="Faire une reservation" sx={{ pl: 8 }} />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => navigate("/immo/profile")}
          >
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary="Profil" />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Outlet/>

      <Box
        component="main"
        sx={{
           bgcolor: '#f5f5f5'
        }}
      > 
        {/* <Toolbar /> */}
        {/* <Typography variant="h4" gutterBottom>
          Welcome to the Dashboard
        </Typography> */}
      </Box>
    </Box>
  );
};

export default Sidebar;
