import { Box } from "@mui/material";
import React from "react";
import ValidatedBookings from "../../../pages-sections/manager/bookings/ValidatedBookings";

const ValidatedBookingsPage = () => {
  return (
    <Box>
      <ValidatedBookings />
    </Box>
  );
};
export default ValidatedBookingsPage;
