import { Box } from "@mui/material";
import React from "react";
import Header from "../../layouts/header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../../layouts/footer/Footer";

const Layout = () => {
    return(
        <Box>
            <Header/>
            <Outlet/>
            <Footer/>
        </Box>
    )
}

export default Layout;