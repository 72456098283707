// import { Box } from "@mui/material"
// import React from "react"

//  const HouseDetails = () => {

//     return(
//         <Box>

//         </Box>
//     )
//  }

import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import houseApiRoutes from "../../../utils/api/houseApiRoutes";
import categoryApiRoutes from "../../../utils/api/categoryApiRoutes";
import serviceApiRoute from "../../../utils/api/serviceApiRoute";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const LogementDetails = () => {
  const CARD_HEIGHT = 410;

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();

  const [detailsData, setDetailsData] = useState({});
  const [category, setCategory] = useState({});
  const [services, setServices] = useState({});

  const getHouseDetails = async () => {
    setIsLoading(true);
    const apiResponse = await houseApiRoutes.getHouseById(id);
    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      setDetailsData({ ...apiResponse });
      console.log(apiResponse);

      const categoryApiResponse = await categoryApiRoutes.getById(
        apiResponse.category
      );
      if (!Object.keys(categoryApiResponse).includes("error")) {
        setIsLoading(false);
        setCategory({ ...categoryApiResponse });
      }

      console.log("ServiceId:", apiResponse.service.id);
      const serviceApiResponse = await serviceApiRoute.getById(
        apiResponse.service.id
      );
      if (!Object.keys(serviceApiResponse).includes("error")) {
        setIsLoading(false);
        setServices({ ...serviceApiResponse });
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const publishHouses = async (id) => {
    setIsLoading(true);
    const publishApiResponse = await houseApiRoutes.publishHouse(id);
    if (!Object.keys(publishApiResponse).includes("error")) {
      setIsLoading(false);
      alert.success("Logement publié");
      console.log("responsePublish", publishApiResponse);
    } else {
      setIsLoading(false);
      alert.error("Logement on publié");
    }
  };

  useEffect(() => {
    getHouseDetails();
  }, []);

  return (
    <Box sx={{ mt: "90px" }} px={{ xs: 4, sm: 7, md: 10 }} mb={4}>
      <Box display={{ md: "flex" }} gap={{ xs: 1, md: 8 }} p={2}>
        <Typography variant="h5" gutterBottom>
          Détails du logement
        </Typography>
        <Button
          variant="contained"
          onClick={() => publishHouses(id)}
        >
          Publier
        </Button>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              height: { sx: CARD_HEIGHT, md: 650 },
            }}
          >
            <Typography
              p={3}
              variant="h6"
              textAlign={{ xs: "left", sm: "left", md: "left" }}
            >
              Images du logement
            </Typography>
            <Box> </Box>
            <Carousel
              showArrows={true}
              infiniteLoop={true}
              useKeyboardArrows={true}
              dynamicHeight={false}
            >
              {detailsData &&
                detailsData.images?.map((imag, index) => (
                  <div key={index}>
                    <img
                      src={imag}
                      alt={`img ${index + 1}`}
                      style={{
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
            </Carousel>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <Typography variant="h6" p={2}>
              {category.name} {} {detailsData.type} {} {detailsData.title}
            </Typography>
            <CardContent>
              <Typography variant="h6">Description</Typography>
              <Typography variant="body1" paragraph>
                {detailsData.description}
              </Typography>
              <Typography variant="h7">
                Situé à : {} {detailsData.location}
              </Typography>
              <Typography variant="h6"></Typography>
              <Typography variant="body1">
                Nombre de salon : {detailsData.livingRoom}
              </Typography>
              <Typography variant="body1">
                Nombre de chambre : {detailsData.bedroom}
              </Typography>
              <Typography variant="body1">
                Nombre de douche : {detailsData.shower}
              </Typography>
              <Typography variant="body1">
                Nombre de toilette : {detailsData.toilet}
              </Typography>
              {/* <Typography variant="body1">
                Location : {detailsData.unit}
              </Typography> */}
              <Typography variant="h6" mt={2}>
                Prix
              </Typography>
              <Typography variant="body1">
                {detailsData.price} FCFA {detailsData.unit}
              </Typography>
              <Typography variant="h6" mt={2}>
                Comodités
              </Typography>
              <ul>
                <li>
                  {services.name}: {services.price} FCFA
                </li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogementDetails;
