import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { useAlert } from "react-alert";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import categoryApiRoutes from "../../../utils/api/categoryApiRoutes";
import serviceApiRoute from "../../../utils/api/serviceApiRoute";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const _initValues = {};

const UpdateCategoryModalForm = ({ open, handleUpdateFormClose }) => {
    const [name, setName] = useState('')
    const [description, setDescription] = useState("");
     const [isLoading, setIsLoading] = useState(false);
     const [serviceInfos, setServiceInfos] = useState({})
    const alert = useAlert();
   const navigate = useNavigate();
  const initialValues = {
    name,
  };

  const formSchema = yup.object().shape({
    name: yup.string().required("Le nom ne doit pas être nul"),
  });

//   const handleCategoryForm = async (values) => {
//     setIsLoading(true);
//     console.log(values);
//     const apiResponse = await categoryApiRoutes.createCategory(values);
//     if (!Object.keys(apiResponse).includes("error")) {
//       console.log("Catégorie créee",apiResponse);
//       setIsLoading(false);
//       alert.success("Création de la catégorie réussit");
//       handleUpdateFormClose();
//     } else {
//       setIsLoading(false);
//       alert.error("Une érreur a été rencontrée");
//     }
//   };

  const updateCategory = async (id, category) => {
    setIsLoading(true)
    const apiResponse  = await categoryApiRoutes.update(id, category)
    if(!Object.keys(apiResponse).includes('error')){
      setIsLoading(false)
        alert.success(
          <div style={{ textTransform: 'none' }}>
            Mise à jour réussit
          </div>
        )
    }else{
      setIsLoading(false)
      alert.error(
        <div style={{ textTransform: 'none' }}>
          Echec de la mise à jour
        </div>
      )
    }

  }



  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleUpdateFormClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth={"sm"}
      maxWidth={"sm"}
    >
      <DialogTitle textAlign={"center"}>{"Mettre à jour la catégorie"}</DialogTitle>
      <DialogContent 
      >
        <Formik
        //   onSubmit={handleCategoryForm}
          initialValues={_initValues || initialValues}
          validationSchema={formSchema}
        >
          {({
            errors,
            values,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                autoFocus
                margin="normal"
                label="Nom"
                fullWidth
                variant="outlined"
                size="small"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />
              <DialogActions>
                <Button onClick={handleUpdateFormClose} variant="contained">
                  Annuler
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                  Mettre à jour
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateCategoryModalForm;
