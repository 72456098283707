import { Box } from "@mui/material";
import React from "react";
import UpdateHouseForm from "../../../pages-sections/manager/houses/UpdateHouseForm";

const UpdateHousePage = () => {
    return(
        <Box>
            <UpdateHouseForm/>
        </Box>
    )
}

export default UpdateHousePage;