import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel"; 
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import houseApiRoutes from "../../utils/api/houseApiRoutes";
import { useNavigate, useParams } from "react-router-dom";
import categoryApiRoutes from "../../utils/api/categoryApiRoutes";
import serviceApiRoute from "../../utils/api/serviceApiRoute";

const HouseDetails = () => {
  
  const CARD_HEIGHT = 400
 const  [isLoading, setIsLoading] = useState(false)
 const [houseDetail, setHouseDetail] = useState({});
 const [category, setCategory] = useState({})
 const [services, setServices] = useState({})
 const navigate = useNavigate()
 const [currentUser, setCurrentUser] = useState({});
 const [isAuthenticated, setIsAuthenticated] = useState(false);

   const {id} = useParams()

  const getUserInfo = () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      setIsAuthenticated(true)
      setCurrentUser(JSON.parse(userInfo));
    } else {
      return null;
    }
  };

  const handleBookingClick = () => {
    if (isAuthenticated) {
      // Rediriger vers la page de réservation si connecté
      navigate(`/booking-house/${id}`);
    } else {
      // Rediriger vers la page de login si non connecté
      navigate('/login');
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const getHouseDetails = async() => {
      setIsLoading(true)
    const apiResponse = await houseApiRoutes.getHouseById(id)
     if(!Object.keys(apiResponse).includes('error')){
      setIsLoading(false)
      setHouseDetail({...apiResponse})

       const categoryApiResponse = await categoryApiRoutes.getById(apiResponse.category)
       if(!Object.keys(categoryApiResponse).includes('error')){
        setIsLoading(false)
        setCategory({...categoryApiResponse})
       }

      console.log("ServiceId:", apiResponse.service.id);
      const serviceApiResponse = await serviceApiRoute.getById(apiResponse.service.id)
      if(!Object.keys(serviceApiResponse).includes('error')){
        setIsLoading(false)
        console.log("ddddd", serviceApiResponse);
        setServices({...serviceApiResponse})
       }else{
        setIsLoading(false)
       }
     }else{
      setIsLoading(false)
     }
  }

  useEffect(() =>{
    getHouseDetails()
  }, [])


  return (
    <Box sx={{ mt:"90px"}} px={{xs:4, sm:7, md:10}}>
        <Box display={{md:"flex"}} gap={{xs:1, md:8}} p={2}>
            <Typography variant="h5" gutterBottom>
                Détails du logement
            </Typography>
            <Button variant="contained" 
              // href={`/booking-house/${id}`}
              onClick={handleBookingClick}
            >Réserver</Button>
        </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card
           sx={{
            height: {sx:CARD_HEIGHT, md:650}

           }}
           
          >
            {/* <CardHeader title="Galerie d'images" sx={{fontSize:"18px"}}/> */}
            {/* <CardHeader title="Galerie d'images" sx={{fontSize:"18px"}}/> */}
            <Typography p={3} variant="h6" textAlign={{xs:"left", sm:"left", md:"left"}}>Galerie d'images</Typography>
            {/* <CardMedia 
            > */}
              <Box> </Box>
              <Carousel
                showArrows={true}
                infiniteLoop={true}
                useKeyboardArrows={true}
                dynamicHeight={false}
              >
                {houseDetail && houseDetail.images?.map((imag, index) => (
                  <div key={index}>
                    <img 
                      src={imag} 
                      alt={`img ${index + 1}`} 
                      style={{
                        width: '100%', 
                        // height: '400px', 
                        objectFit: 'cover', 
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            {/* </CardMedia> */}
          </Card>
        </Grid>
        {/* Section des détails du logement */}
        <Grid item xs={12} md={6}>
          <Card>
            {/* <CardHeader title={category.name}  /> */}
            <Typography variant="h6" p={2}>{category.name} {} {houseDetail.type} - {houseDetail.location}</Typography>
            <CardContent>
              <Typography variant="h6">Description</Typography>
              <Typography variant="body1" paragraph>
                {houseDetail.bigDescription}
              </Typography>
              {/* <Typography variant="h6">Informations</Typography> */}
              <Typography variant="body1">
                Nombre de salon : {houseDetail.livingRoom}
              </Typography>
              <Typography variant="body1">
                Nombre de chambre : {houseDetail.bedroom}
              </Typography>
              <Typography variant="body1">
                Nombre de douche : {houseDetail.shower}
              </Typography>

              <Typography variant="h6" mt={2}>Prix</Typography>
              {/* <Typography variant="h6">Prix par saison</Typography> */}
              <Typography variant="body1">
                 {houseDetail.price} FCFA
              </Typography>
              {/* <Typography variant="body1">
                Été : {houseDetails.seasons.summer}
              </Typography>
              <Typography variant="body1">
                Hiver : {houseDetails.seasons.winter}
              </Typography> */}

              <Typography variant="h6" mt={2}>Comodités</Typography>
              <ul>
                {/* {services.map((service, index) => ( */}
                  <li >{services.name}: {services.price} FCFA</li>
                {/* ))} */}
              </ul>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HouseDetails;
