import "./App.css";
import HouseList from "./pages-sections/house/HouseList";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { useAuth } from "./contexts/AuthenticationContext";
import Login from "./pages-sections/sessions/Login";
import SignUpPage from "./pages/signUp";
import BookingPage from "./pages/booking-house/bookingPage";
import HouseDetails from "./pages-sections/house-details/HouseDetails";
import ContactForm from "./pages-sections/contact/Contact";
import CarServicePage from "./pages/car-services/carServices";
import Layout from "./pages/app-layout/layoutPage";
import CoworkingServicesPage from "./pages/coworking/coServices";
import entertainementPageService from "./pages/entertainement/funService";
import EntertainementService from "./pages-sections/entertainnment-services/Entertainment";
import DrawerSidebar from "./pages/drawerSidebar/drawerSidebar";
import HouseCategoryPage from "./pages/manager/houseCategories/houseCategory";
import CreateHouse from "./pages/manager/house/create";
import AllHousePage from "./pages/manager/house";
import ServicePages from "./pages/manager/BookingService";
import AddServicepage from "./pages/manager/BookingService/create";
import AllBookingsPage from "./pages/manager/bookings";
import AddBookingPage from "./pages/manager/bookings/create";
import ValidatedBookingsPage from "./pages/manager/bookings/validated";
import ProfilePage from "./pages/userProfile";
import DashboardComponent from "./pages/dashboard";
import BookingDetailsPage from "./pages/manager/bookings/details";
import DetailsPage from "./pages/manager/house/details";
import BookingClientPage from "./pages/clientAdministration/bookings";
import DetailsPageClient from "./pages/clientAdministration/details";
import AmenagementPage from "./pages/car-services/amenagement";
import HouseRentalPage from "./pages/car-services/houseRental";
import ConstructionPage from "./pages/car-services/construction";
import DiasporaPage from "./pages/car-services/diaspora";
import HomePage from "./pages/car-services/home";
import Welcome from "./pages/welcome";
import ProjectPage from "./pages/car-services/project";
import { Fragment } from "react";
import { Helmet } from 'react-helmet';
import PublishHousePage from "./pages/manager/house/publishHouse";
import UpdateServicePage from "./pages/manager/BookingService/update";
import UpdateHousePage from "./pages/manager/house/update";
// import { appWithTranslation } from 'next-i18next';
const ProtectedRoute = ({ children }) => {
  const Navigate = useNavigate();
  const { isAuthenticated, currentUser } = useAuth();
  console.log("iiii: ", isAuthenticated);
  console.log("ddddd: ", currentUser);
  //  const token = localStorage.getItem("accessToken")
  if (isAuthenticated && currentUser) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }

  // if (!isAuthenticated) {
  //  Navigate("/login")}
};

// const ProtectedRoute = ({ children }) => {

//   const token = localStorage.getItem("access")

//   return children;
// };

//   return children;
// };

// const AdminRoutes = () => (
//   <DrawerSidebar>
//     <Routes>
//       <Route path="dashboard" element={<DashboardComponent />} />
//       <Route path="house-categories" element={<HouseCategoryPage />} />
//       <Route path="create-house" element={<CreateHouse />} />
//       <Route path="all-house" element={<AllHousePage />} />
//       <Route path="booking-services" element={<ServicePages />} />
//       <Route path="add-service" element={<AddServicepage />} />
//       <Route path="all-bookings" element={<AllBookingsPage />} />
//       <Route path="add-bookings" element={<AddBookingPage />} />
//       <Route path="booking-details/:id" element={<BookingDetailsPage />} />
//       <Route path="validated-bookings" element={<ValidatedBookingsPage />} />
//       <Route path="profile" element={<ProfilePage />} />
//     </Routes>
//   </DrawerSidebar>
// );

const AdminRoutes = () => (
  <DrawerSidebar>
    <Outlet /> {/* Ceci est important pour rendre les routes enfants */}
  </DrawerSidebar>
);

function App() {
  return (
    // <>
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <meta name="description"content="Web site created using create-react-app"/>
          <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.8.3/jquery.min.js" />
          <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBewJ2x5COtaqlSdrt8DdKObNP_rRWCEEE&libraries=places,drawing,geometry" />{" "}
          <title>Immo App</title>
        </Helmet>

      <BrowserRouter>
        <Routes>
          constuction-service
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Welcome />} />
            <Route path="/booking-house/:id" element={<BookingPage />} />
            <Route path="/details/:id" element={<HouseDetails />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/car-services" element={<CarServicePage />} />
            <Route path="/design-service" element={<AmenagementPage />} />
            <Route path="/house-rental" element={<HouseRentalPage />} />
            <Route path="/constuction-service" element={<ConstructionPage />} />
            <Route path="/diaspora-service" element={<DiasporaPage />} />
            <Route path="/home-page" element={<HomePage />} />
            <Route path="/projects" element={<ProjectPage />} />

            <Route
              path="/coworking-services"
              element={<CoworkingServicesPage />}
            />
            <Route path="/other-service" element={<EntertainementService />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          {/* <Route path="/dashboard" element={<DashboardPage/>} /> */}
          {/* <Route path="/immo" element={<DrawerSidebar />}>
          <Route path="/immo/dashboard" element={<DashboardComponent />} />
          <Route
            path="/immo/house-categories"
            element={<HouseCategoryPage />}
          />
          <Route path="/immo/create-house" element={<CreateHouse/>} />
          <Route path="/immo/all-house" element={<AllHousePage/>} />
          <Route path="/immo/booking-services" element={<ServicePages/>} />
          <Route path="/immo/add-service" element={<AddServicepage/>} />
          <Route path="/immo/all-bookings" element={<AllBookingsPage/>}/>
          <Route path="/immo/add-bookings" element={<AddBookingPage/>}/>
          <Route path="/immo/booking-details/:id" element={<BookingDetailsPage/>}/>
          <Route path="/immo/validated-bookings" element={<ValidatedBookingsPage/>}  />
          <Route path="/immo/profile" element={<ProfilePage/>}/>
        </Route> */}
          {/* <Route
            path="/immo/*"
            element={
              <ProtectedRoute>
                <AdminRoutes />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/login" replace />} /> */}
          <Route
            path="/immo"
            element={
              <ProtectedRoute>
                <AdminRoutes />
              </ProtectedRoute>
            }
          >
            <Route path="dashboard" element={<DashboardComponent />} />
            <Route path="house-categories" element={<HouseCategoryPage />} />
            <Route path="create-house" element={<CreateHouse />} />

            <Route path="all-house" element={<AllHousePage />} />
            <Route path="publish-house" element={<PublishHousePage />} />
            <Route path="details-house/:id" element={<DetailsPage />} />
            <Route path="booking-services" element={<ServicePages />} />
            <Route path="add-service" element={<AddServicepage />} />
            <Route path="update-service/:id" element={<UpdateServicePage />} />
            <Route path="update-house/:id" element={<UpdateHousePage />} />

            <Route path="all-bookings" element={<AllBookingsPage />} />
            <Route path="add-bookings" element={<AddBookingPage />} />
            <Route
              path="booking-details/:id"
              element={<BookingDetailsPage />}
            />
            <Route
              path="validated-bookings"
              element={<ValidatedBookingsPage />}
            />
            <Route path="client-bookings" element={<BookingClientPage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="book-details/:id" element={<DetailsPageClient />} />
          </Route>
          <Route
            path="/immo/dashboard"
            element={<Navigate to="/immo/dashboard" replace />}
          />
        </Routes>
      </BrowserRouter>
      </Fragment>

    // </>
  );
}

export default App;
