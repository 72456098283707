import React from "react";
import { Box, Container, Typography, Link, IconButton } from "@mui/material";
import { Facebook, WhatsApp, LinkedIn, Twitter, YouTube, 
  Instagram } from '@mui/icons-material';
  import { styled } from '@mui/system';

const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: '#e8491d',
  color: '#ffffff',
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
}));

const Footer = () => {


  return (
    
    // <Box bgcolor="#f4511e" color="white" py={3} mt={4}>
    //   <Container>
    //     <Box
    //       display={{ md: "flex" }}
    //       flexDirection={{ xs: "column", md: "row" }}
    //       justifyContent="space-between"
    //     >
    //       <Box 
    //         display={"flex"} 
    //         flexDirection={"column"} 
    //         flex={1}
    //         gap={2}
    //       >
    //         <Typography variant="h5" >A propos</Typography>
    //         <p style={{ margin: 0 }}>Acceuil</p>
    //       </Box>
    //       <Box 
    //         display={"flex"} 
    //         flexDirection={"column"} 
    //         flex={1}
    //         gap={2}
    //       >
    //         <Typography variant="h5" mt={2}>Nous contacter</Typography>
    //         <p style={{ margin: 0 }}>146 Rue 1185 Koumassi, Bonapriso, Douala, Cameroun</p>
    //         <p style={{ margin: 0 }}>BP: 4170 Douala, Cameroun</p>
    //         <p style={{ margin: 0 }}>Email: contact@immo.com</p>
    //       </Box>
    //       <Box 
    //         display={"flex"} 
    //         flexDirection={"column"} 
    //         gap={2}

    //       >
    //         <Typography variant="h5" sx={{mt:2 }}>Conditions et termes</Typography>
    //           <Link 
    //             href="#" 
    //             color="inherit" 
    //             underline="none"
    //           >
    //             Conditions d'utilisation immo
    //           </Link>
    //       </Box>
    //     </Box>
    //     <Typography 
    //      variant="body1"
    //      align="center" 
    //      mt={2}
    //      >
    //       © 2024 Pategou-consulting. Tous droits réservés.
    //     </Typography>
    //   </Container>
    // </Box>

    <Box>
      <StyledFooter>
        <Container maxWidth="lg">
          <Typography variant="body1" align="center" gutterBottom>
          ©2024 Pategou consulting. Tous droits réservés.
          </Typography>
          <Box display="flex" justifyContent="center">
            {[Facebook, WhatsApp, LinkedIn, Twitter, YouTube, Instagram].map((Icon, index) => (
              <IconButton key={index} color="inherit" aria-label="social media">
                <Icon />
              </IconButton>
            ))}
          </Box>
        </Container>
      </StyledFooter>
    </Box>
  );
};

export default Footer;
