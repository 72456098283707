import { Box } from "@mui/material";
import React from "react";
import UserProfile from "../../pages-sections/profile/UserProfile";
const ProfilePage = ( ) => {

    return(
        <Box>
            <UserProfile/>
        </Box>
    )
}

export default ProfilePage;