import React, { useState, useRef } from "react";
import {
  Button,
  Checkbox,
  Box,
  Divider,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
  Select,
  OutlinedInput,
  ListItemText,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Autocomplete from "@mui/material/Autocomplete";

import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { useTranslation, Trans } from "react-i18next";

const autocompleteService = { current: null };

const InputLocationSearch = ({
  parentCallback,
  defaultValue,
  title,
  shrink,
  className,
  variant,
  visualStyle,
  textSize,
}) => {
  const [loader, setLoader] = useState(false);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  let [myCurrentLocation, setMyCurrentLocation] = useState("");
  const { t } = useTranslation();

  // AUTOCOMPLETE PLACE API
  const [value, setValue] = useState(defaultValue?.name ? defaultValue : null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [cityToSend, setCityToSend] = useState([]);
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current
          .getPlacePredictions(request, callback)
          .then((r) => {});
      }, 200),
    []
  );

  const handleSelect = (location) => {
    // Get latitude and longitude via utility function
    getGeocode({ address: location?.description })
      .then((result) => getLatLng(result[0]))
      .then(({ lat, lng }) => {
        setCityToSend({
          name: location.description,
          coordinates: { lat, lng },
        });
        parentCallback({
          name: location.description,
          coordinates: { lat, lng },
        });
        setCoordinates({ lat, lng });
      })
      .catch((error) => {});
  };
    
  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  // END AUTOCOMPLETE PLACE API

  const getMyCurrentLocation = () => {
    // const location = navigator.geolocation
    // const geocoder = new google.maps.Geocoder()
    const location = navigator.geolocation;
    const geocoder = new window.google.maps.Geocoder();
    if ("geolocation" in navigator) {
      location.getCurrentPosition(
        (position) => {
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          geocoder
            .geocode({
              location: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            })
            .then((response) => {
              if (response.results[0]) {
                setMyCurrentLocation(response.results[0].formatted_address);
                setMyCurrentLocation({
                  name: response.results[0].formatted_address,
                  coordinates: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  },
                });
              } else {
                window.alert(t("errors.noResultsFound"));
              }
            })
            .catch((e) => window.alert("Geocoder failed due to: " + e));
        },
        (error) => {
          setCoordinates({ lat: 0, lng: 0 });
        }
      );
    }
  };

  return (
    <Grid item md={6} xs={12}>
        {/* {console.log("OPTIONS", options.name)} */}
      <Autocomplete
        id="google-map-demo"
        size="small"
        className={className + " " + textSize + ""}
        getOptionLabel={(option) => {
          return typeof option === "string"
            ? option
            : option?.description ?? option.name;
        }}
                      
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          handleSelect(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => {
          return visualStyle && visualStyle == "header" ? (
            <Paper
              component="form"
              sx={{
                p: "0",
                display: "flex",
                alignItems: "center",
                width: "100%",
                boxShadow: "none !important",
                borderBottom: "1px solid",
                borderRadius: "0",
              }}
            >
                      {/* {console.log("hhhh", part.text)} */}

              <TextField
                {...params}
                className={textSize + ""}
                placeholder={"Rechercher un emplacement"}
                label={title}
                size="small"
                fullWidth
                variant={variant}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              ></IconButton>
            </Paper>
          ) : (
            <TextField
              className={textSize + ""}
              {...params}
              placeholder={"Localisation"}
              label={title}
              size="small"
              fullWidth
              InputLabelProps={{
                shrink: shrink,
              }}
              variant={variant}
            />
          );
        }}
    
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box sx={{ color: "text.secondary", mr: 2 }}>
                    <i className="pi pi-map-marker"> </i>
                  </Box>
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                    
                  ))}
                  {/* {} */}
                  <Typography
                    variant="body2"
                    className={textSize ?? "text-sm"}
                    color="text.secondary"
                  >
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
    
      />
    </Grid>
  );
};

export default InputLocationSearch;
