import axios from "axios";
import { url } from "../constants";
import { bool } from "yup";

const bookingUrl = url + "api/booking";

const bookingHouse = async (bookingData) => {
    let response = {};
    await axios
      .post(bookingUrl + "/booking-house", bookingData)
      .then((res) => {
        response = res?.data;
      })
      .catch((error) => {
        response = { error: error.response?.data.message };
      });
    return response;
  };

  const getAllBookings = async () => {
    let response = {};
    await axios
      .get(bookingUrl + "/get-all-booking")
      .then((res) => {
        response = res?.data;
      })
      .catch((error) => {
        response = { error: error.response?.data.message ?? "" };
      });
    return response;
  };

  const validateBooking = async (id) => {
    let response = {}
    await axios
    .post(bookingUrl + "/validate/" + id)
    .then(res => {
      response = res.data
    })
    .catch(errors => {
      response = {error: errors.response.data.message ?? ''}
    })
    return response;
  }

  const cancelBooking = async (id) => {
    let response = {}
    await axios
    .post(bookingUrl + "/cancel/" + id)
    .then(res => {
      response = res.data
    })
    .catch(errors => {
      response = {error: errors.response.data.message ?? ''}
    })
    return response;
  }


  const getAllValidetedBookings =async () => {
    let response = {}

    await axios.get(bookingUrl + "/get-all-validated-booking")
    .then((res) => {
      response = res?.data;
    })
    .catch((error) => {
      response = { error: error.response?.data.message ?? "" };
    });
  return response;
  }


  const getById = async (id) => {
    let response = {}
    await axios.get(bookingUrl + `/get-by-id/`+ id)
    .then((res) => {
     response = res?.data;
    })
    .catch((errors) => {
     response = {error: errors.response?.data.message?? ''}
    })
   
    return response
  }


   const getBookingByUser = async(userId) => {

    let response = {}

    await axios.get(bookingUrl + '/' + userId)
    .then((res) => {
      response = res?.data
    })
    .catch((errors) => {
      response = {error: errors.response?.data.message?? ''}
    })

    return response;
   }
   
  export default{
    bookingHouse,
    getAllBookings,
    validateBooking,
    getAllValidetedBookings,
    getById,
    cancelBooking,
    getBookingByUser
  }