import axios from "axios";
import { url } from "../constants";

const contactUrl = url + "api/contact";

const saveContact = async(contactInfos) => {
    let response = {};
    await axios
      .post(contactUrl + "/sendEmail", contactInfos)
      .then((res) => {
        response = res?.data;
      })
      .catch((errors) => {
        response = { error: errors.response?.data.message };
      });
    return response;
  };
  

  export default{
    saveContact
  }