import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Box,
  Chip,
  OutlinedInput,
  Modal,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useAlert } from "react-alert";
import houseApiRoutes from "../../utils/api/houseApiRoutes";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import bookingApiRoute from "../../utils/api/bookingApiRoute";
import categoryApiRoutes from "../../utils/api/categoryApiRoutes";
import InputLocationSearch from "../../components/Location/InputLocationSearch";

const theme = createTheme({
  palette: {
    primary: {
      main: "#50b3a2",
    },
    secondary: {
      main: "#e8491d",
    },
  },
});

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 10,
}));

const ModalContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  outline: "none",
  width: "75%",
  maxHeight: "90%",
  overflowY: "auto",
}));

const Home = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [price, setPrice] = useState(0);
  const [userId, setUserId] = useState("");
  const [houseId, setHouseId] = useState("");
  const [serviceId, setServiceId] = useState([]);
  const [publishHouses, setPublishHouses] = useState([]);
  const [searchHouseType, setSearchHouseType] = useState([]);
  const [type, setType] = useState("");
  const [location, setLocation] = useState("");
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [bedroom, setBedroom] = useState(0);
  const [unit, setUnit] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [publishDate, setPublishDate] = useState("")
  const [locationName, setLocationName] = useState({});

  const [filteredHouses, setFilteredHouses] = useState(publishHouses);

  const alert = useAlert();


  const searchHouseByAllParameter = async () => {
    setIsLoading(true);
    const locationValues = location.name
    const encodedType = type ? encodeURIComponent(type) : null;

    const apiResponse = await houseApiRoutes.searchHouseByAllParameter(
      encodedType,
      locationValues,
      bedroom,
      minPrice,
      maxPrice,
      unit,
      categoryId
    );

    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      setSearchHouseType([...apiResponse]);
      setPublishHouses([...apiResponse]);
    } else {
      setIsLoading(false);
    }
    // setBedroom(0)
    // setAmenagement("")
    // setChambre(0)
    // setLocation("")
    // setMaxPrice(0)
    // setMinPrice(0)
  };

  const ShowcaseSection = styled("section")(({ theme }) => ({
    minHeight: "400px",
    backgroundImage: 'url("/assets/maison_home.jpg")',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    textAlign: "center",
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const propertyList = [
    {
      id: 1,
      category: "Appartements",
      title: "Appartement Luxueux",
      images: ["/assets/home-banner2.jpg", "/assets/maison_luste.jpg"],
      type: "appartement",
      description: "Magnifique appartement de 3 pièces avec vue panoramique.",
      price: 500000,
    },
    {
      id: 2,
      category: "Maisons",
      title: "Maison Familiale",
      images: ["/assets/hermes-130-jardin.jpg", "/assets/location6.jpg"],
      type: "maison",
      description: "Belle maison de 5 pièces avec grand jardin et garage.",
      price: 300000,
    },
    {
      id: 3,
      category: "Bureaux",
      title: "Bureau Professionnel",
      images: [
        "/assets/confort-office.jpg",
        "/assets/premium-coworking-office.jpg",
        "/assets/conference_room_b.jpg",
      ],
      type: "bureau",
      description: "",
      price: 700000,
    },
    {
      id: 4,
      category: "Locaux commerciaux",
      title: "Espace de divertissement",
      images: ["/assets/bowling.jpg", "/assets/billard.jpg"],
      type: "boutique",
    },
  ];

  const initialValues = {
    lastName: null,
    firstName: null,
    phoneNumber,
    email: null,
    startDate,
    endDate,
    // price: houseDetail.price,
    price,
    userId,
    houseId,
    serviceId: [],
  };

  const formSchema = yup.object().shape({
    lastName: yup.string().required("Le nom ne doit pas etre vide"),
    firstName: yup.string().required("Le prénom ne doit pas etre vide"),
    phoneNumber: yup
      .string()
      .required("Veuillez ajouter un numéro de téléphone"),
    email: yup.string().email("Email requis").required("Email invalide"),
    startDate: yup
      .string()
      .required("Veuillez ajouter une date de debut")
      .test(
        "startDate-before-endDate",
        "La date de début doit être inférieure ou égale à la date de fin",
        function (value) {
          const { endDate } = this.parent; // Accède à la date de fin
          return !endDate || !value || new Date(value) <= new Date(endDate); // Vérifie que la date de début est inférieure ou égale à la date de fin
        }
      ),
    endDate: yup.string().required("Veuillez ajouter une date de fin"),
  });

  const handleOpenModal = (house) => {
    setSelectedLocation(house);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    if (!images || images.length === 0) {
      return (
        <Box className="w-full h-64 flex items-center justify-center bg-gray-200">
          <Typography>Aucune image disponible</Typography>
        </Box>
      );
    }

    // const goToPrevious = () => {
    //   const isFirstSlide = currentIndex === 0;
    //   const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    //   setCurrentIndex(newIndex);
    // };

    // const goToNext = () => {
    //   const isLastSlide = currentIndex === images.length - 1;
    //   const newIndex = isLastSlide ? 0 : currentIndex + 1;
    //   setCurrentIndex(newIndex);
    // };

    return (
      <Box className="relative w-full h-64">
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          useKeyboardArrows={true}
          dynamicHeight={false}
          showThumbs={true}
          autoPlay={true}
          className="carousel"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          {images?.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          ))}
        </Carousel>
      </Box>
    );
  };
  const [imageIndex, setImageIndex] = useState(propertyList.map(() => 0));

  const handlePrevImage = (index) => {
    setImageIndex((prevState) =>
      prevState.map((imgIndex, i) =>
        i === index
          ? imgIndex === 0
            ? propertyList[index].images.length - 1
            : imgIndex - 1
          : imgIndex
      )
    );
  };

  const handleNextImage = (index) => {
    setImageIndex((prevState) =>
      prevState.map((imgIndex, i) =>
        i === index
          ? imgIndex === propertyList[index].images.length - 1
            ? 0
            : imgIndex + 1
          : imgIndex
      )
    );
  };

  const retrieveAllCategories = useCallback(async () => {
    setIsLoading(true);
    const apiResponse = await categoryApiRoutes.getAllCategories();

    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      setCategories([...apiResponse]);
    } else {
      alert.error(
        <div style={{ textTransform: "none" }}>
          {apiResponse.error ??
            "Une erreur s'est produite lors de la récupération des catégories"}
        </div>
      );
    }
  }, [alert]);

  const retrievePublishHouses = useCallback(async () => {
    setIsLoading(true);
    const apiResponse = await houseApiRoutes.getPublishAllHouses();

    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      
      const apiResponseCategories = await categoryApiRoutes.getAllCategories();   
      if(!Object.keys(apiResponseCategories).includes("error")){
        const housesWithCategoryNames = apiResponse.map(house => {
          const category = apiResponseCategories.find(cat => cat.id === house.category); 
          return {
            ...house,
            categoryName: category ? category.name : 'Aucune', 
          };
        })
      setPublishHouses(housesWithCategoryNames);
      }else {
       console.log("Aucune catégorie existante");
       
      }
      // setPublishHouses([...apiResponse]);
    } else {
      setIsLoading(false);
      alert.error("Aucun logement pour le moment");
    }
  }, [alert]);

  
  useEffect(() => {
    retrievePublishHouses();
    retrieveAllCategories();
    // getHouseCategory();
  }, []);

  const handleFormSubmit = async (values) => {
    // const useId = currentUser?.id;
    //  console.log("user:", useId);
    // const ids = serviceId.map((service, index) => service?.id);
    // console.log("Idddddssss", ids);

    const submitData = {
      lastName: values.lastName,
      firstName: values.firstName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      startDate: values.startDate,
      endDate: values.endDate,
      price: selectedLocation.price,
      // userId: useId,
      houseId: selectedLocation.id,
      // serviceId: ids,
    };

    const apiResponse = await bookingApiRoute.bookingHouse(submitData);
    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      alert.success(
        <div style={{ textTransform: "none", zIndex: 3000 }}>
          Votre réservation a bien été éfectuée
        </div>
      );
    } else {
      setIsLoading(false);
      alert.error(
        <div style={{ textTransform: "none", zIndex: 3000 }}>
          Echec de la réservation
        </div>
      );
    }
  };

  const drawer = (
    <div>
      <List>
        {["Home", "Rent", "Buy", "Sell", "Contact"].map((text) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <ShowcaseSection>
        <Box>
          <Typography variant="h3" component="h1" gutterBottom>
            Services de Location
          </Typography>
          <Typography variant="h5" component={"p"}>
            Trouvez votre chez-vous idéal parmi nos offres de location
          </Typography>
        </Box>
      </ShowcaseSection>
      <Container width="full" sx={{ mt: "50px" }}>
        <Box justifyContent={"center"} alignItems={"center"} ml={{xs:0, md:"150px",}}>
          <Typography variant="h5" gutterBottom>
            Rechercher une location
          </Typography>
          <Grid container spacing={3} sx={{ mb: 4, mt: 1 }}>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Type de bien</InputLabel>
                <Select
                  size="small"
                  value={categoryId}
                  onChange={(event) => {
                    setCategoryId(event.target.value);
                  }}
                >
                  {categories &&
                    categories?.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Aménagement</InputLabel>
                <Select
                  size="small"
                  value={type}
                  onChange={(event) => setType(event.target.value)}
                >
                  <MenuItem value="Meublé">Meublé</MenuItem>
                  <MenuItem value="Non meublé">Non meublé</MenuItem>
                  {/* <MenuItem value="office">Bureau</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                label="Chambre"
                type="number"
                variant="outlined"
                size="small"
                value={bedroom}
                onChange={(e) => setBedroom(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InputLocationSearch
                parentCallback={(value) => {
                  setLocation(value);
                }}
              />
              {/* <TextField
                fullWidth
                label="Localisation"
                variant="outlined"
                size="small"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              /> */}
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#f4511e", color: "white" }}
                size="lg"
              >
                Sélectionner sur la carte
              </Button>
            </Grid> */}
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                label="Min Price"
                type="number"
                variant="outlined"
                size="small"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                label="Max Price"
                type="number"
                variant="outlined"
                size="small"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Unité</InputLabel>
                <Select
                  size="small"
                  value={unit}
                  onChange={(event) => setUnit(event.target.value)}
                >
                  <MenuItem value="Par heure">Par heure</MenuItem>
                  <MenuItem value="Par jour">Par jour</MenuItem>
                  <MenuItem value="Par semaine">Par semaine</MenuItem>
                  <MenuItem value="Par mois">Par mois</MenuItem>
                  <MenuItem value="Par année">Par année</MenuItem>
                  {/* <MenuItem value="office">Bureau</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
            {/* <Typography>Période</Typography> */}
              <TextField
                fullWidth
                // label="Période"
                type="date"
                variant="outlined"
                size="small"
                value={publishDate}
                onChange={(event) => setPublishDate(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#f4511e", color: "white" }}
                size="lg"
                onClick={searchHouseByAllParameter}
              >
                Rechercher
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box px={{ xs: 0, md: 8 }} width={"full"}>
        <Typography
          variant="h4"
          textAlign={{ xs: "center", md: "left" }}
          mt={6}
          ml={{ xs: 0, md: 6 }}
        >
          Nos Biens en Location
        </Typography>

        <Box
          sx={{
            display: { md: "flex" },
            justifyContent: "center",
            position: "relative",
          }}
        >
          {/* <IconButton
            onClick={handlePrevImage}
            sx={{
              position: "absolute",
              top: "50%",
              left: "0",
              transform: "translateY(-50%)",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 10,
              // marginRight:4
            }}
          >
            <ArrowBackIos />
          </IconButton> */}
          {publishHouses.length > 0 ? (
            <Grid container spacing={4} px={{ xs: 0, md: 6 }}>
              {publishHouses.map((house, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={house.id}>
                  <Grid
                    // xs={12}
                    // md={3}
                    mt={{ xs: 0, md: 5 }}
                    ml={{ xs: 5, md: 0 }}
                  >
                    {/* <Typography variant="h6" component={"h6"} width={"220px"}>
                    {property.category}
                  </Typography> */}
                  </Grid>
                  <Card
                    sx={{
                      maxWidth: 345,
                      margin: "20px auto",
                      "&:hover": {
                        transform: "translateY(-5px)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      },
                    }}
                    onClick={() => handleOpenModal(house)}
                  >
                    <Box sx={{ position: "relative" }}>
                      <CardMedia
                        component="img"
                        height="250"
                        image={house.images[0]}
                        alt={house.title}
                      />
                    </Box>
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        color={"#26a69a"}
                      >
                        {house.categoryName} {} 
                         {console.log(house.categoryName)
                         }
                        {house.title} 
                      </Typography>
                      <Typography mb={2} variant="h7" component="p" textAlign={"justify"}>
                        {house.description}
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#26a69a", color: "white" }}
                        size="small"
                      >
                        Réserver
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box mt={4}>
              <Typography variant="h5" textAlign={"center"}>
                Aucun résultat correspondant 0 votre recherche
              </Typography>
            </Box>
          )}
          {/* <IconButton
            onClick={handleNextImage}
            sx={{
              position: "absolute",
              top: "50%",
              right: "0",
              transform: "translateY(-50%)",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 10,
            }}
          >
            <ArrowForwardIos />
          </IconButton> */}

          {/* <Grid container spacing={4} px={{ xs: 0, md: 6 }}>
            {searchHouseByUnit.length > 0 ? (
              searchHouseByUnit.map((house) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={house.id}>
                  <Grid
                    xs={12}
                    md={3}
                    mt={{ xs: 0, md: 5 }}
                    ml={{ xs: 5, md: 0 }}
                  >
                    {/* <Typography variant="h6" component={"h6"} width={"220px"}>
                    {property.category}
                  </Typography> */}
          {/* </Grid>
                  <Card
                    sx={{ maxWidth: 345, margin: "20px auto" }}
                    onClick={() => handleOpenModal(house)}
                  >
                    <Box sx={{ position: "relative" }}>
                      <CardMedia
                        component="img"
                        height="250"
                        image={house.images[0]}
                        alt={house.title}
                      />
                    </Box>
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        color={"#26a69a"}
                      >
                        {house.title}
                      </Typography> */}
          {/* <Typography mb={2} variant="h7" component="p">
                        {house.title}
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#26a69a", color: "white" }}
                        size="small"
                      >
                        Réserver
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography>Aucun logement trouvé</Typography>
            )}
          </Grid> */}
        </Box>

        <StyledModal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="project-modal-title"
          aria-describedby="project-modal-description"
        >
          <ModalContent>
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              style={{
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {selectedLocation && (
              <>
                <Typography variant="h5">Détails</Typography>
                <Grid container pacing={4} mb={3}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      id="project-modal-title"
                      variant="body1"
                      component="h2"
                    >
                      {selectedLocation.categoryName} {}
                      {selectedLocation.type.toLowerCase()} {}
                      {selectedLocation.title.toLowerCase()} 
                      
                    </Typography>
                    <Typography variant="body1">
                      Situé à : {} {selectedLocation.location}
                    </Typography>
                    <Typography variant="body1">
                      Nombre de salon : {selectedLocation.livingRoom}
                    </Typography>
                    <Typography variant="body1">
                      Nombre de chambre : {selectedLocation.bedroom}
                    </Typography>
                    <Typography variant="body1">
                      Nombre de douche : {selectedLocation.shower}
                    </Typography>
                    <Typography variant="body1">
                      Nombre de toilette : {selectedLocation.toilet}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography id="project-modal-description" className="mt-4">
                      description: {selectedLocation.description}
                    </Typography>
                    <Typography id="project-modal-description" className="mt-4">
                      Prix: {selectedLocation.price} {"FCFA"}
                      {} {selectedLocation.unit}
                    </Typography>
                    <Typography variant="h6" mt={2}>
                      Comodités
                    </Typography>
                  </Grid>
                </Grid>

                <ImageCarousel images={selectedLocation.images} />
                <Grid item xs={12} md={6} mt={4}>
                  {/* <p>email : {initialValues.email}</p> */}

                  <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={formSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid
                          container
                          spacing={2}
                          width="100%"
                          maxWidth="700px"
                        >
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Nom"
                              name="lastName"
                              value={values.lastName}
                              onChange={handleChange}
                              variant="outlined"
                              onBlur={handleBlur}
                              error={!!touched.lastName && !!errors.lastName}
                              helperText={touched.lastName && errors.lastName}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Prénom"
                              name="firstName"
                              value={values.firstName}
                              variant="outlined"
                              onChange={handleChange}
                              error={!!touched.firstName && !!errors.firstName}
                              helperText={touched.firstName && errors.firstName}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Adresse e-mail"
                              type="email"
                              variant="outlined"
                              name="email"
                              size="small"
                              value={values.email}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={!!touched.email && !!errors.email}
                              helperText={touched.email && errors.email}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Numéro de téléphone"
                              name="phoneNumber"
                              value={values.phoneNumber}
                              variant="outlined"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={
                                !!touched.phoneNumber && !!errors.phoneNumber
                              }
                              helperText={
                                touched.phoneNumber && errors.phoneNumber
                              }
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>Période de réservation</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Date de debut</Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              type="date"
                              name="startDate"
                              value={values.startDate}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={!!touched.startDate && !!errors.startDate}
                              size="small"
                              helperText={touched.startDate && errors.startDate}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>Date de fin</Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              type="date"
                              name="endDate"
                              value={values.endDate}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={!!touched.endDate && !!errors.endDate}
                              helperText={!!touched.endDate && !!errors.endDate}
                              size="small"
                            />
                          </Grid>
                          {serviceId.length > 0 && ( 
                          <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                              <InputLabel>Services</InputLabel>
                              <Select
                                fullWidth
                                multiple
                                name="serviceId"
                                value={values.serviceId}
                                size="small"
                                onChange={(e) => {
                                  handleChange(e);
                                  setServiceId(e.target.value);
                                }}
                                input={
                                  <OutlinedInput
                                    id="select-multiple-chip"
                                    label="Chip"
                                  />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip key={value.id} label={value.name} />
                                    ))}
                                  </Box>
                                )}
                              >
                                {/* {services.map((service) => (
                            <MenuItem key={service.id} value={service}>
                              {service.name} : {service.price} FCFA
                            </MenuItem>
                          ))} */}
                              </Select>
                            </FormControl>
                          </Grid>
                          )}
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            spacing={1}
                            alignItems={"center"}
                          >
                            <Grid item>
                              <Typography variant="body1">
                                Prix du logement: {selectedLocation.price}{" "}
                                {"FCFA"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1">
                                {/* {houseDetail.price} FCFA */}
                              </Typography>
                            </Grid>
                          </Grid>

                          {serviceId.length > 0 && (
                            <Grid
                              container
                              item
                              xs={12}
                              sm={12}
                              spacing={2}
                              alignItems={"center"}
                            >
                              <Grid item>
                                <Typography variant="body1">
                                  Prix des services
                                </Typography>
                              </Grid>
                              <Grid container item spacing={2} ml={2}>
                                {serviceId?.map((service) => (
                                  <Typography variant="body1">
                                    {service.name}: {service.price}
                                    FCFA
                                  </Typography>
                                ))}
                              </Grid>
                            </Grid>
                          )}
                          {/* {totalBookingPrice !== 0 && 
                    <Grid item xs={12} sm={6}>
                      <Typography>Prix total: {totalBookingPrice} FCFA</Typography>
                      {/* <TextField
                        fullWidth
                        autoFocus
                        variant="outlined"
                        name="price"
                        // value={values.price}
                        disabled
                    //   /> */}
                          {/* // </Grid> */}
                          {/* // } */}
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                            justifyContent="flex-end"
                          >
                            <Grid item>
                              <Button variant="outlined" color="secondary">
                                Annuler
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                              >
                                Réserver
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Formik>
                </Grid>
              </>
            )}
          </ModalContent>
        </StyledModal>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
