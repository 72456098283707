import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  IconButton,
  useMediaQuery,
  useTheme,
  Box,
  Chip,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { Formik } from "formik";
import * as yup from "yup";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CloseIcon from "@mui/icons-material/Close";

const customColors = {
  primary: "#50b3a2",
  secondary: "#e8491d",
  background: "#f4f4f4",
};

// Styles personnalisés
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(to right, ${customColors.primary}, #6ec6b8)`,
  borderBottom: `3px solid ${customColors.secondary}`,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  "&:hover": {
    fontWeight: "bold",
  },
}));

const ShowcaseSection = styled("section")(({ theme }) => ({
  minHeight: "400px",
  backgroundImage:
    'url("assets/construction50.jpg")',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  textAlign: "center",
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  },
   // 16:9 aspect ratio
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  paddingTop: "56.25%", // 16:9 aspect ratio
}));
const StyledCardMediaModal = styled(CardMedia)(({ theme }) => ({
  paddingTop: "5%", // 16:9 aspect ratio
  height:"600px"
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ModalContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  outline: "none",
  width: "75%",
  maxHeight: "90%",
  overflowY: "auto",
}));

// Composant principal
const ConstructionService = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [price, setPrice] = useState(0);
  const [userId, setUserId] = useState("");
  const [houseId, setHouseId] = useState("");
  const [serviceId, setServiceId] = useState([]);
  const [category, setCategory] = useState({});  
  const handleOpenModal = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  

  const services = [
    {
      title: "Construction Résidentielle",
      description:
        "Nous réalisons vos rêves de maison individuelle ou d'immeuble résidentiel, en respectant vos exigences et les normes de qualité.",
      image: "https://images.unsplash.com/photo-1586023492125-27b2c045efd7",
    },
    {
      title: "Construction Commerciale",
      description:
        "Des bureaux aux centres commerciaux, nous construisons des espaces commerciaux fonctionnels et attractifs pour votre entreprise.",
      image: "https://images.unsplash.com/photo-1533090161767-e6ffed986c88",
    },
    {
      title: "Rénovation et Extension",
      description:
        "Transformez votre espace existant avec nos services de rénovation et d'extension, pour un habitat modernisé et agrandi.",
      image: "assets/renovation12.jpg",
    },
  ];

  const projects = [
    {
      id: "residence-moderne",
      title: "Résidence Moderne",
      description:
        "Construction d'une villa contemporaine avec piscine",
      price: 500000,
      images:
        [
          "https://images.unsplash.com/photo-1583847268964-b28dc8f51f92?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
          "assets/construction.jpg",
        ],
    },
    {
      id: "tour-de-bureaux",
      title: "Tour de Bureaux",
      description: "Construction d'un immeuble de bureaux de 15 étages.",
      price: 800000,
      images:
      [ "assets/cuisine_jolie.jpg",

        "https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
      ],
    },
    {
      id: "centre-commercial",
      title: "Centre Commercial",
      price: 1000000,
      description:
        "Réalisation d'un centre commercial de 50 000 m²",
      images:
        ["assets/centre_commercial.jpg", "assets/centre_commercial.jpg", "https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"]
    }
  ];

  const initialValues = {
    lastName,
    firstName,
    phoneNumber,
    email,
    startDate,
    endDate,
    // price: houseDetail.price,
    price,

    userId,
    houseId,
    serviceId: [],
  };

  const formSchema = yup.object().shape({
    lastName: yup.string().required("Le nom ne doit pas etre vide"),
    firstName: yup.string().required("Le prénom ne doit pas etre vide"),
    phoneNumber: yup
      .string()
      .required("Veuillez ajouter un numéro de téléphone"),
    email: yup.string().email("Email requis").required("Email invalide"),
    startDate: yup.string().required("Veuillez ajouter une date de debut"),
    endDate: yup.string().required("Veuillez ajouter une date de fin"),
  });
  // const [currentIndex, setCurrentIndex] = useState(0);

  const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    if (!images || images.length === 0) {
      return (
        <Box className="w-full h-64 flex items-center justify-center bg-gray-200">
          <Typography>Aucune image disponible</Typography>
        </Box>
      );
    }
    const goToPrevious = () => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
  
    const goToNext = () => {
      const isLastSlide = currentIndex === images.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };
    return (
      <Box className="relative w-full h-64">
        {/* <IconButton
          onClick={goToPrevious}
          className="absolute left-2 top-1/2 transform -translate-y-1/2 z-10"
        >
          <ArrowBackIos />
        </IconButton>
        <IconButton
          onClick={goToNext}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10"
        >
          <ArrowForwardIos />
        </IconButton> */}
         <Carousel
          showArrows={true}
          infiniteLoop={true}
          useKeyboardArrows={true}
          dynamicHeight={false}
          showThumbs={true} // Supprime les miniatures si vous n'en avez pas besoin
          autoPlay={true} // Optionnel, si vous voulez que les slides changent automatiquement
          // sx={{
          //   '.thumbs-wrapper': {
          //     display: 'flex',
          //     justifyContent: 'center',
          //     margin: '0 auto',
          //   },
          //   '.thumbs': {
          //     display: 'flex',
          //     justifyContent: 'center',
          //   },
          // }}
          className="carousel"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems:'center',
            margin: '0 auto',
          }}
                >
          {images?.map((image, index) => (
            <div key={index} >
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  // height: "500px", // Ajustez la hauteur en fonction de vos besoins
                }}
              />
            </div>
          ))}
        </Carousel>
        {/* <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          // className="w-full h-full object-contain"
          // style={{width:"700px", height:"800px"}}
        />
        <Card>
          <CardMedia
            image={images[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
          />
        </Card> */}
      </Box>
    );
  };

  const processSteps = [
    "Consultation initiale et évaluation des besoins",
    "Conception et planification détaillée",
    "Obtention des permis et autorisations nécessaires",
    "Préparation du site et fondations",
    "Construction de la structure",
    "Finitions intérieures et extérieures",
    "Contrôle qualité et inspections",
    "Livraison du projet et service après-vente",
  ];

  return (
    <>
      {/* <StyledAppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <span style={{ color: customColors.secondary, fontWeight: "bold" }}>
              Services
            </span>{" "}
            Immobiliers
          </Typography>
          {!isMobile && (
            <>
              <StyledButton color="inherit">Accueil</StyledButton>
              <StyledButton color="inherit">Location</StyledButton>
              <StyledButton color="inherit">Construction</StyledButton>
              <StyledButton color="inherit">Aménagement</StyledButton>
              <StyledButton color="inherit">Diaspora</StyledButton>
              <StyledButton color="inherit">Contact</StyledButton>
            </>
          )}
        </Toolbar>
      </StyledAppBar> */}

      <ShowcaseSection>
        <Box>
          <Typography variant="h3" component="h1" gutterBottom>
          Services de Construction
          </Typography>
          <Typography variant="h5" component={"p"}>
          Des solutions sur mesure pour vos projets de construction
          </Typography>
        </Box>
      </ShowcaseSection>

      <Container maxWidth="lg" style={{ marginTop: theme.spacing(6) }}>
        <Typography variant="h5" align="left" mb={3}>
          Nos Services de Construction
        </Typography>
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <StyledCard 
              
              >
                <StyledCardMedia image={service.image} title={service.title} />
                <CardContent>
                  <Typography
                    mb={2}
                    variant="h6"
                    component="h2"
                    color={"#26a69a"}
                  >
                    {service.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {service.description}
                  </Typography>
                </CardContent>
                <Box p={2} mt="auto">
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#f4511e" }}
                    // fullWidth
                    size="small"
                    href="/contact"
                  >
                    En savoir +
                  </Button>
                </Box>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        <Box my={8}>
          {/* <Typography variant="h5" gutterBottom align="center">
            Pourquoi Nous Choisir ?
          </Typography> */}
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <img
                src="/assets/amenagement.jpg"
                alt="Équipe d'aménagement professionnelle"
                style={{ width: "100%", height: "auto", borderRadius: "6px" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <List>
                <ListItem>
                  <ListItemText primary="Équipe de designers et d'architectes expérimentés" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Approche personnalisée pour chaque projet" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Utilisation de matériaux durables et de qualité" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Respect des délais et du budget" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Service client exceptionnel" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Garantie sur tous nos travaux" />
                </ListItem>
              </List> */}
              <Typography variant="h5" gutterBottom ml={1}>
                Pourquoi Nous Choisir ?
              </Typography>
              <ul>
                <ListItem>
                  <li >
                  Expertise reconnue dans le domaine de la construction
                  </li>
                </ListItem>
                <ListItem>
                  <li>Équipe de professionnels qualifiés et expérimentés</li>
                  {/* <ListItemText primary="Approche personnalisée pour chaque projet" /> */}
                </ListItem>
                <ListItem>
                  <li>Utilisation de matériaux de haute qualité</li>
                </ListItem>
                <ListItem>
                  <li>Suivi personnalisé de chaque projet</li>
                </ListItem>
                <ListItem>
                  <li>Garantie sur tous nos travaux</li>
                </ListItem>
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={4} alignItems="center" py={5}>
          <Grid item xs={12} md={6} >
            <div style={{ textAlign: "left" }}>
              <Typography
                variant="h5"
                component="h2"
                style={{ marginBottom: "20px", color: "#333" }}
              >
                Notre Processus de Construction
              </Typography>
              <ol disablePadding>
                {processSteps.map((step, index) => (
                  <li key={index} style={{padding:'4px'}}>{step}</li>
                ))}
              </ol>
            </div>
          </Grid>

          {/* Section Image */}
          <Grid item xs={12} md={6}>
            <img
              src="./assets/jolie_cuisine.jpg"
              alt="Processus d'aménagement"
              style={{
                width: "100%",
                height: "345px",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              }}
            />
          </Grid>
        </Grid>

        <Box my={8}>
          <Typography variant="h4" mb={3} textAlign={{xs:"center", md:"left"}}>
            Nos Projets Réalisés
          </Typography>
          <Grid container spacing={4}>
            {projects.map((project) => (
              <Grid item xs={12} sm={6} md={4} key={project.id}>
                <StyledCard onClick={() => handleOpenModal(project)}>
                  <StyledCardMedia
                    image={project.images[0]}
                    title={project.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {project.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {project.description}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            padding: "2rem",
            borderRadius: "12px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* Image */}
          <Box
            component="img"
            src="/assets/location7.jpg"
            alt="Consultation d'aménagement"
            sx={{
              width: { xs: "100%", md: "40%" },
              height: "350px",
              borderRadius: "12px",
              marginBottom: { xs: "1.5rem", md: 0 },
              marginRight: { md: "2rem" },
            }}
          />

          {/* Content */}
          <Box
            sx={{ textAlign: { xs: "center", md: "left" }, maxWidth: "500px" }}
          >
            <Typography variant="h5" mb={4}>
            Prêt à Concrétiser Votre Projet ?
            </Typography>
            <Typography variant="p" paragraph mb={4} textAlign={"justify"}>
            Contactez-nous dès aujourd'hui pour une consultation gratuite et sans engagement.
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#f4511e" }}
              // size="small"
              href="/contact"
            >
              Nous Contacter
            </Button>
          </Box>
        </Box>

        {/* <Box my={8} textAlign="center">
          <Typography variant="h5" gutterBottom>
            Prêt à Transformer Votre Espace ?
          </Typography>
          <Typography variant="p" paragraph>
            Contactez-nous dès aujourd'hui pour une consultation gratuite et
            commencez votre projet d'aménagement.
          </Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#f4511e" }}
            size="large"
          >
            Nous Contacter
          </Button>
        </Box> */}
      </Container>

      <StyledModal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="project-modal-title"
        aria-describedby="project-modal-description"
      >
        <ModalContent>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            style={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedProject && (
            // <>
            //   <Typography id="project-modal-title" variant="h6" component="h2">
            //     {selectedProject.title}
            //   </Typography>
            //   <img
            //     src={selectedProject.image}
            //     alt={selectedProject.title}
            //     style={{ width: "100%", marginTop: theme.spacing(2) }}
            //   />
            //   <Typography
            //     id="project-modal-description"
            //     style={{ marginTop: theme.spacing(2) }}
            //   >
            //     {selectedProject.description}
            //   </Typography>
            // </>
            <>
              <Typography id="project-modal-title" variant="h6" component="h2">
               {selectedProject.title}
              </Typography>
              <Typography  mb={3} id="project-modal-description" className="mt-4">
                {selectedProject.description}
              </Typography>
              <ImageCarousel images={selectedProject.images} />
       
            </>
          )}
        </ModalContent>
      </StyledModal>
    </>
  );
};

export default ConstructionService;
