import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/sidebar/Sidebar";
import ClientSidebar from "../../layouts/sidebar/ClientSidebar";
import { useAuth } from "../../contexts/AuthenticationContext";
const DrawerSidebar = () => {
  const { currentUser, isAuthenticated } = useAuth();
  // const navigate = useNavigate()

  // }, []);

  // if (!currentUser) {
  //   return null; // ou un composant de chargement
  // }
  // useEffect(() => {
  //   console.log("isss", isAuthenticated);
  //   console.log("isss", currentUser);

  //   if (currentUser && isAuthenticated) {
  //     // Redirection vers le tableau de bord si l'utilisateur est connecté
  //     navigate("/immo/dashboard");
  //   }
  // }, [currentUser, navigate]);

  return (
    <Box>
      {currentUser?.role === "CLIENT" ? <ClientSidebar /> : <Sidebar />}
    </Box>
  );
};

export default DrawerSidebar;
