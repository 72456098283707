import {
  Box,
  Typography,
  CardMedia,
  Container,
  Card,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

const BannerBox = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "250px",
  overflow: "hidden",
  paddingTop: "150px",
  borderRadius: "6px",
  [theme.breakpoints.up("md")]: {
    height: "250px",
  },
}));

const BannerImage = styled(CardMedia)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  objectFit: "cover",
});
const SearchFilters = ({ onFilterChange }) => {
  const [filters, setFilters] = React.useState({
    type: "",
    furnished: "",
  });
  const images = [
    "/assets/banner-image.jpg",
    "/assets/home-banner.jpeg",
    "/assets/home-banner2.jpg",
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); 
    return () => clearInterval(interval);
  }, [images]);

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  return (
    <Box 
    px={4} 
    py={2} 
    
    >
      <Container>
        <Box mt={10} width={"full"}>
          <Card
            sx={{
              borderRadius: 3,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 3,
              }}
            >
              <Typography
              color="white" 
              sx={{
                fontSize: {
                  xs: "15px", 
                  sm: '30px',
                  md: '40px',  
                },
              }}
              textAlign="center"
              >
                Votre plateforme de confiance pour trouver le logement idéal
              </Typography>
            </Box>

            <CardMedia
              component="img"
              image={images[currentImageIndex]}
              alt={`banner image ${currentImageIndex + 1}`}
              sx={{
                height: { xs: "170px", sm: "260px", md: "380px" },
                transition: "opacity 0.5s ease-in-out",
                objectFit: "cover",
              }}
            />
          </Card>
        </Box>
      </Container>
      <Typography
        variant="h6"
        component="h1"
        gutterBottom
        textAlign={"center"}
        mt={10}
      >
        Trouvez votre logement
      </Typography>
      {/* <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Type de logement</InputLabel>
            <Select name="type" value={filters.type} onChange={onFilterChange}>
              <MenuItem value="">Tous</MenuItem>
              <MenuItem value="maison">Maison</MenuItem>
              <MenuItem value="appartement">Appartement</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Préférence</InputLabel>
            <Select
              name="furnished"
              value={filters.furnished}
              onChange={onFilterChange}
            >
              <MenuItem value="">Tous</MenuItem>
              <MenuItem value="true">Meublé</MenuItem>
              <MenuItem value="false">Non meublé</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} mt={2}>
          <FormControl fullWidth variant="standard">
            <TextField variant="standard" type="date" />
          </FormControl>
        </Grid>
      </Grid> */}
    </Box>
  );
};

export default SearchFilters;
