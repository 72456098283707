import { Box } from "@mui/material";
import DashboardPage from "../layouts/dashboard/Dashboard";
import DashboardClient from "../layouts/dashboard/DashboardClient"; 
import { useEffect, useState } from "react";

const DashboardComponent = () => {
  const [currentUser, setCurrentUser] = useState({});

  const getUserInfo = () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      setCurrentUser(JSON.parse(userInfo));
    } else {
      return null;
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <Box width={"full"}>
      {currentUser.role === "MANAGER" && (
        <DashboardPage />
      )}
    </Box>
  );
};
export default DashboardComponent;
