import {
  Box,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState, useTransition } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import serviceApiRoute from "../../../utils/api/serviceApiRoute";
import { useAlert } from "react-alert";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useNavigate } from "react-router-dom";
const BookingServices = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
const navigate = useNavigate()
  const [serviceData, setServiceData] = useState([]);
  const alert = useAlert();
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = serviceData.map((service) => service.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (id) => {
    console.log("selected:", selected);
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        // Si l'ID est déjà sélectionné, on le retire (désélection)
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        // Sinon, on l'ajoute à la liste (sélection)
        return [...prevSelected, id];
      }
    });
  };

  const isSelected = (id) => selected.includes(id);
  const numSelected = selected.length;
  const rowCount = serviceData.length;

  const retrieveAllServices = async () => {
    setIsLoading(true);
    const apiResponse = await serviceApiRoute.getAllService();

    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      setServiceData([...apiResponse]);
    } else {
      alert.error(
        <div style={{ textTransform: "none" }}>
          {apiResponse.error ??
            "Une erreur s'est produite lors de la récupération des services"}
        </div>
      );
    }
  };

  useEffect(() => {
    retrieveAllServices();
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = () => {
    console.log("Deleting selected rows: ", selected);
    setIsLoading(true);
    const apiResponse = selected.map((serviceId) =>
      serviceApiRoute.removeService(serviceId)
    );
    console.log(apiResponse);

    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      console.log("selected:", selected);

      console.log(apiResponse);
      alert.success("Service(s) suprimé(s) avec succès");
    } else {
      setIsLoading(false);
      alert.error("Une erreur a été rencontrée");
    }
  };

  return (
    <Container width={"full"}>
      <Box p={{ xs: 3, sm: 3, md: 10 }} mt={{ xs: 8, sm: 8, md: "20px" }}>
        <Typography variant="h6" mb={3}>
          Liste des services
        </Typography>
        <Box p={2}>
          <Button
            aria-label="Ajouter une catégorie"
            color="secondary"
            variant="contained"
            onClick={() => navigate("/immo/add-service")}
          >
            Ajouter un service
          </Button>
        </Box>
        <Card>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={handleSelectAll}
                      />
                      {/* <Checkbox
                        indeterminate={
                          selected.length > 0 && selected.length < serviceData.length
                        }
                        checked={
                          serviceData.length > 0 && selected.length === serviceData
                        }
                        onChange={handleSelectAll}
                      /> */}
                    </TableCell>
                    <TableCell>Nom </TableCell>
                    <TableCell>Prix</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((service) => (
                      <TableRow key={service.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected(service.id)}
                            onChange={() => handleSelect(service.id)}
                          />
                        </TableCell>
                        <TableCell>{service.name}</TableCell>
                        <TableCell>
                          {service.price} {"FCFA"}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            // onClick={() => console.log("Edit item", service.id)}
                            size="small"
                            onClick={() => navigate(`/immo/update-service/${service.id}`)}
                          >
                            <Tooltip title="Editer">
                              <DriveFileRenameOutlineIcon sx={{color:"#26a69a"}}/>
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
            >
              <IconButton
                color="red"
                onClick={() => setOpenConfirmationModal(true)}
                disabled={selected.length === 0}
                size="small"
                sx={{ color: "#f44336" }}
              >
                <DeleteIcon fontSize="small" sx={{ color: "#f44336" }} />
                Supprimer
              </IconButton>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={serviceData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          </Paper>
        </Card>
        {openConfirmationModal ? (
          <DeleteServiceModal
            open={openConfirmationModal}
            onClose={() => setOpenConfirmationModal(false)}
            onSubmit={handleDelete}
          />
        ) : (
          ""
        )}
      </Box>
    </Container>
  );
};

export default BookingServices;
export const DeleteServiceModal = ({ open, onClose, onSubmit }) => {
  const handleConfirmDelete = () => {
    onSubmit(true);
    onClose();
  };
  return (
    <Box>
      <Dialog open={open}>
        <DialogContent>
          <Typography variant="h6" textAlign={"center"} mb={2}>
            Confirmation de suppréssion
          </Typography>
          <Typography>
            Etes vous sur de vouloir supprimer ce service?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Annuler
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="secondary"
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
