import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  AppBar,
  Toolbar,
  Button,
  Container,
  Paper,
  Avatar,
  Divider,
  Icon,
  Box,
  CardMedia,
} from "@mui/material";
import { useParams } from "react-router-dom";
import bookingApiRoute from "../../utils/api/bookingApiRoute";
import houseApiRoutes from "../../utils/api/houseApiRoutes";
import categoryApiRoutes from "../../utils/api/categoryApiRoutes";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const BookingDetailsClient = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [bookingData, setBookingData] = useState({});
    const [houseDetails, setHouseDetails] = useState({});
    const [category, setCategory] = useState({});
    const { id } = useParams();


    const getBookingDetails = async () => {
        const apiResponse = await bookingApiRoute.getById(id);
        setIsLoading(false);
        if (!Object.keys(apiResponse).includes("error")) {
          setIsLoading(false);
          console.log("ddddd", apiResponse);
          setBookingData({ ...apiResponse });
          console.log(apiResponse.houseId);
          const apiResponseHouse = await houseApiRoutes.getHouseById(
            apiResponse.houseId
          );
          if (!Object.keys(apiResponseHouse).includes("error")) {
            console.log("Maison", apiResponseHouse);
            setHouseDetails(apiResponseHouse);
            const categoryApiResponse = await categoryApiRoutes.getById(
              apiResponseHouse.category.id
            );
            if (!Object.keys(categoryApiResponse).includes("error")) {
              setIsLoading(false);
              setCategory({ ...categoryApiResponse });
              console.log("category", categoryApiResponse);
            }
          }
        } else {
          setIsLoading(false);
        }
      };
    
      useEffect(() => {
        getBookingDetails();
      }, []);
    
      return (
        <Box mt={"100px"} >
          <Container>
            <Paper sx={{ padding: 3, marginBottom: 3 }}>
              <Box sx={{ textAlign: "right" }}>
                <Button variant="contained" color="secondary" sx={{ margin: 1 }}>
                  Annuler la réservation
                </Button>
              </Box>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <div>
                  <Typography variant="h5" gutterBottom>
                    {category.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {houseDetails.shortDescription}, {houseDetails.bigDescription}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mt={1}>
                    Localisation: {houseDetails.location}
                  </Typography>
                </div>
              </div>
    
              <Divider sx={{ margin: "16px 0" }} />
    
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    Nombre de salon: {houseDetails.livingRoom}
                  </Typography>
                  <Typography variant="body1">
                    Nombre de chambre : {houseDetails.bedroom}
                  </Typography>
                  <Typography variant="body1">
                    Nombre de douche : {houseDetails.shower}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography variant="body1">
                      Date: {bookingData.bookingHour}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography variant="body1">
                      Heure: {}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography variant="body1">
                      Date de debut: {bookingData.startDate}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography variant="body1">
                      Date de fin: {bookingData.endDate}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography variant="body1">
                      Statut: {bookingData.status}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid>
                <Typography variant="h5">
                  Prix total: {bookingData.price} FCFA
                </Typography>
              </Grid>
              <Divider sx={{ margin: "16px 0" }} />
              <Grid container xs={12} md={6}>
                <Typography variant="h6">Images</Typography>
                <Card width={"300px"}>
                  <Carousel
                    showArrows={true}
                    infiniteLoop={true}
                    useKeyboardArrows={true}
                    dynamicHeight={false}
                  >
                    {houseDetails &&
                    houseDetails.images &&
                    houseDetails.images.length > 0 ? (
                      houseDetails &&
                      houseDetails?.images.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image}
                            alt={`img ${index + 1}`}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <p>Aucune image disponible</p>
                    )}
                  </Carousel>
                </Card>
              </Grid>
              <Divider sx={{ margin: "16px 0" }} />
            </Paper>
          </Container>
        </Box>
      )
}

export default BookingDetailsClient;