import axios from "axios";

import { url } from "../constants";

const serviceUrl = url + "api/service";

const createService = async (service) => {
  let response = {};

  await axios
    .post(serviceUrl + "/add", service)
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      response = { error: e.response?.data?.message };
    });
  return response;
};

const getAllService = async () => {
  let response = {};

  await axios
    .get(serviceUrl + "/all-service")
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      response = { error: e.response?.data?.message ?? "" };
    });
  return response;
};

const removeService = async (id) => {
  let response = {};

  await axios
    .delete(serviceUrl + "/delete", {
      params: { id: id },
    })
    .then((res) => {
      response = res?.data;
    })
    .catch((error) => {
      response = { error: error.reponse?.data.message ?? "" };
    });
  return response;
};

  const getById = async(id) => {
    let response = {};
  
    await axios.get(serviceUrl +"/get-by-id/" + id)
    .then((res) => {
      response = res?.data
    })
    .catch((errors) => {
      response = {error: errors.response?.data.message ?? ''}
    })
    return response;
  };
  
  const updateService = async(id, service) => {

    let response = {}
    await axios.put(serviceUrl + "/update/"+ id, service)
    .then(res => {
      response = res?.data;
    })
    .catch(error => {
      response = {error: error.reponse.data?.message??''}
    })
    return response;
  }


export default {
  createService,
  getAllService,
  removeService,
  getById,
  updateService
};
