import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import GoogleIcon from "@mui/icons-material/Google";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import authApiRoute from "../../utils/api/authApiRoute";
import { Formik } from "formik";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const _initValues = {};

const Login = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [authData, setAuthData] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const alert = useAlert();
  const navigate = useNavigate();

  const initialValues = {
    email,
    password,
  };

  const formSchema = yup.object().shape({
    email: yup.string().email("Email requis").required("Email invalide"),
    password: yup
      .string()
      .required("Mot de passe requis")
      // .matches(
      //   "Le mot de passe doit contenir au moins 8 caractères, Une majuscule, une minuscule, un nombre et un caractère spécial"
      // ),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handleLogin = async (values) => {
  //   setIsLoading(true);

  //   console.log(values);
  //   const authResponse = await authApiRoute.login(values);
  //   if (!Object.keys(authResponse).includes("error")) {
  //     console.log(authResponse);
  //     setIsLoading(false);
  //     console.log("infos", authResponse);
  //     let user = authResponse;
  //     // setCurrentUser({ ...user });
  //     localStorage.setItem("userInfo", JSON.stringify(user));
  //     alert.success("Connexion réussit");
  //     navigate("/immo/dashboard");
  //   } else {
  //     setIsLoading(false);
  //     alert.error("Une erreur est survenue");
  //   }
  //   // setIsLoggedIn(true);
  // };
  const handleLogin = async (values) => {
    setIsLoading(true);
  
    try {
      const authResponse = await authApiRoute.login(values);
      if (authResponse && authResponse.accessToken && authResponse.user) {
        setIsLoading(false);
        localStorage.setItem("authToken", authResponse.accessToken);
        
        localStorage.setItem("userInfo", JSON.stringify(authResponse.user));
        console.log("userInfos", authResponse.user);
        alert.success("Connexion réussie");
        navigate("/immo/dashboard");
      } else {
        throw new Error("Données d'authentification incorrectes");
      }
    } catch (error) {
      console.error("Erreur d'authentification:", error);
      setIsLoading(false);
      alert.error("Les données d'authentifications sont incorrectes. veuillez Reesayer");
    }
  };


  const handleGoogleSignIn = (response) => {
    console.log("Google sign-in response:", response);
  };

  const getUserInfo = () => {
    const userInfo = localStorage.getItem("userInfo");
    return userInfo ? JSON.parse(userInfo) : null;
  };

  // useEffect(() => {
  //   let userData = getUserInfo();
  //       if(userData){
  //         navigate('/immo/dashboard')
  //       }else{
  //         navigate('/login')
  //       }
  // }, []);

  return (
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        padding={2}
        //   gap={3}
      >
        <Card
          sx={{
            padding: 3,
            maxWidth: 400,
            width: "100%",
            boxShadow: 3,
          }}
        >
          <Typography variant="h5" textAlign={"center"} gutterBottom>
            Connexion
          </Typography>
          <Typography variant="body1"  textAlign={"center"} justifyContent={"center"} gutterBottom>
            Veuillez vous connecter
          </Typography>
          <Formik
            onSubmit={handleLogin}
            initialValues={_initValues || initialValues}
            validationSchema={formSchema}
          >
            {({
              errors,
              values,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  autoFocus
                  margin="normal"
                  id="email"
                  label="Adresse e-mail"
                  type="email"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  label="Mot de passe"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  margin="normal"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box display={"flex"} alignItems={"center"} gap={1} mt={2}>
                  <Typography component="h6">
                    Vous n'avez pas de compte?
                  </Typography>
                  <Link href="/sign-up">Créez-le!</Link>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={2}
                  mt={2}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate("/")}
                  >
                    Annuler
                  </Button>
                  <Button type="submit" variant="contained" sx={{backgroundColor:'#f4511e'}}>
                    Se connecter
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            // mt={2}
          >
            {/* <Typography variant="body2" gutterBottom>
              Ou créez un compte avec Google
            </Typography> */}
            {/* <GoogleLogin
              onSuccess={handleGoogleSignIn}
              onFailure={(error) => console.log(error)}
              render={(renderProps) => (
                <IconButton
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <GoogleIcon />
                </IconButton>
              )}
            /> */}
          </Box>
        </Card>
      </Box>
    </GoogleOAuthProvider>
  );
};

export default Login;
