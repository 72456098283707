import { Box } from "@mui/material";
import React from "react";
import BookingDetailsClient from "../../pages-sections/clientdashboard/BookingDetailsClient";

const DetailsPageClient = () => {

    return(
        <Box>
<BookingDetailsClient/>
        </Box>
    )
}

export default DetailsPageClient;
