import React from "react";

import HouseRentalServices from "../../pages-sections/car-services/Home1";
import { Box } from "@mui/material";
import HouseCard from "../../components/house/HouseCard";
import Projects from "../../pages-sections/car-services/Project";

const ProjectPage = () => {
    
    return(
        <Box>
            <Projects/>
        </Box>
    )
}

export default ProjectPage;