import { Box } from "@mui/material";
import React from "react";
import BookingServices from "../../../pages-sections/manager/bookingServices/BookingServices";


const ServicesPage = () => {
    return(
        <Box>
            <BookingServices/>
        </Box>
    )
}

export default ServicesPage;