import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
      blueGrey: '#b0bec5',
      greyWhite: '#fafafa',
      greyPureBlack: '#212121',
      bgcolor: '#f5f5f5'
    },
    secondary: {
      main: '#19857b',
      fun: '#c22828',
      lowfun:'#f44336'
    },
    error: {
      main: '#red',
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;