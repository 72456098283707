import { Box } from "@mui/material";
import React from "react";
import ValidatedBookings from "../../pages-sections/manager/bookings/ValidatedBookings";
import BookingClient from "../../pages-sections/clientdashboard/Booking";
const BookingClientPage = () => {
  return (
    <Box>
      <BookingClient />
    </Box>
  );
};
export default BookingClientPage;