import { Box } from "@mui/material";
import React from "react";
import ConstructionService from "../../pages-sections/car-services/ConstructionService";

const ConstructionPage = () => {

    return(
        <Box>
            <ConstructionService/>
        </Box>
    )
}

export default ConstructionPage;