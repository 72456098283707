import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Container,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  IconButton,
  Modal,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const StyledCardMediaModal = styled(CardMedia)(({ theme }) => ({
  paddingTop: "5%", // 16:9 aspect ratio
  height: "600px",
}));

const ModalContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  outline: "none",
  width: "75%",
  maxHeight: "95%",
  overflowY: "auto",
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const projectsData = {
  location: [
    {
      id: 1,
      title: "Appartement",
      image: "/assets/amenagement4.jpg",
      description: "Un appartement moderne au cœur de la ville.",
    },
    {
      id: 2,
      title: "Appartement",
      image: "assets/appart.jpg",
      description: "Une spacieuse maison avec jardin.",
    },
    {
      id: 3,
      title: "Appartement luxueur",
      image: "assets/appart_luxueur.jpg",
      description: "Une spacieuse maison avec jardin.",
    },
    {
      id: 4,
      title: "Maison Familiale",
      image: "assets/location6.jpg",
      description: "Une spacieuse maison avec jardin.",
    },
  ],
  amenagement: [
    {
      id: 1,
      title: "Salon",
      image: "assets/amenagement_home.jpg",
      description: "Un espace de travail collaboratif et moderne.",
    },
    {
      id: 2,
      title: "Loft Industriel",
      image: "assets/amenagement2.jpg",
      description: "Un loft au style industriel chic.",
    },
  ],
  construction: [
    {
      id: 1,
      title: "Immeuble Résidentiel",
      image: "assets/construction31.jpg",
      description: "Un immeuble moderne de 50 appartements.",
    },
    {
      id: 2,
      title: "Centre Commercial",
      image: "https://example.com/mall1.jpg",
      description: "Un centre commercial de dernière génération.",
    },
  ],
  bureaux: [
    {
      id: 1,
      title: "Espace de Coworking",
      image: "assets/open_space.jpg",
      description: "Un espace de coworking flexible et convivial.",
    },
    {
      id: 2,
      title: "Bureaux Confort",
      image: "assets/confort-office.jpg",
      description: "Des bureaux haut de gamme pour cadres.",
    },
    {
      id: 2,
      title: "Bureau de réunion",
      image: "assets/bureau_reunion.jpg",
      description: "Des bureaux haut de gamme pour cadres.",
    },
  ],
};

const projectsDatas = [
  {
    id: 1,
    title: "Appartement",
    images: ["/assets/amenagement4.jpg"],
    description: "Un appartement moderne au cœur de la ville.",
  },
  {
    id: 2,
    title: "Appartement",
    images: ["assets/appart.jpg", "/assets/amenagement4.jpg"],
    description: "Une spacieuse maison avec jardin.",
  },
  {
    id: 3,
    title: "Appartement luxueur",
    images: ["assets/appart_luxueur.jpg"],
    description: "Une spacieuse maison avec jardin.",
  },
  {
    id: 4,
    title: "Maison Familiale",
    images: ["assets/location6.jpg"],
    description: "Une spacieuse maison avec jardin.",
  },
  {
    id: 5,
    title: "Salon",
    images: ["assets/amenagement_home.jpg"],
    description: "Un espace de travail collaboratif et moderne.",
  },
  {
    id: 6,
    title: "Loft Industriel",
    images: ["assets/amenagement2.jpg"],
    description: "Un loft au style industriel chic.",
  },
  {
    id: 7,
    title: "Immeuble Résidentiel",
    images: ["assets/construction31.jpg"],
    description: "Un immeuble moderne de 50 appartements.",
  },
  {
    id: 8,
    title: "Centre Commercial",
    images: ["assets/centre-commercial.jpg"],
    description: "Un centre commercial de dernière génération.",
  },

  {
    id: 9,
    title: "Espace de Coworking",
    images: ["assets/open_space.jpg"],
    description: "Un espace de coworking flexible et convivial.",
  },
  {
    id: 10,
    title: "Bureaux Confort",
    images: ["assets/confort-office.jpg"],
    description: "Des bureaux haut de gamme pour cadres.",
  },
  {
    id: 11,
    title: "Bureau de réunion",
    images: ["assets/bureau_reunion.jpg"],
    description: "Des bureaux haut de gamme pour cadres.",
  },
];
// const theme = createTheme({
//   palette: {
//     orange: {
//       main: '#FFA500',
//       dark: '#FF8C00',
//     },
//   },
// });
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFA500",
  color: "white",
  "&:hover": {
    backgroundColor: "#FF8C00",
  },
}));

const OrangeButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFA500",
  color: "white",
  "&:hover": {
    backgroundColor: "#FF8C00",
  },
}));
const ShowcaseSection = styled("section")(({ theme }) => ({
  minHeight: "400px",
  backgroundImage: 'url("/assets/project.jpg")',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  textAlign: "center",
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ProjectCard = ({ project, handleOpenModal }) => (
  <Card
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      transition: "transform 0.3s, box-shadow 0.3s",
      "&:hover": {
        transform: "translateY(-5px)",
        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
      },
    }}
    onClick={() => handleOpenModal(project)}
  >
    <CardMedia
      component="img"
      height="200"
      image={project.images[0]}
      alt={project.title}
    />
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography gutterBottom variant="h5" component="div">
        {project.title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {project.description}
      </Typography>
    </CardContent>
    <CardActions>
      <Button
        variant="contained"
        size="small"
        sx={{ backgroundColor: "#f4511e" }}
        href="/contact"
      >
        Nous contacter
      </Button>
    </CardActions>
  </Card>
);

const Projects = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedProject, setSelectedProject] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const sections = ["location", "amenagement", "construction", "bureaux"];

  const handleOpenModal = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    if (!images || images.length === 0) {
      return (
        <Box className="w-full h-64 flex items-center justify-center bg-gray-200">
          <Typography>Aucune image disponible</Typography>
        </Box>
      );
    }
    const goToPrevious = () => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };

    const goToNext = () => {
      const isLastSlide = currentIndex === images.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };
    return (
      <Box className="relative w-full h-64">
        {/* <IconButton onClick={goToPrevious} style={{ zIndex: 10 }}>
          <ArrowBackIos />
        </IconButton>
        <IconButton onClick={goToNext} style={{ zIndex: "50px" }}>
          <ArrowForwardIos />
        </IconButton> */}
        {/* <StyledCardMediaModal
          component="img"
          image={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
        /> */}
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          useKeyboardArrows={true}
          dynamicHeight={false}
          showThumbs={true} // Supprime les miniatures si vous n'en avez pas besoin
          autoPlay={true} // Optionnel, si vous voulez que les slides changent automatiquement
          // sx={{
          //   '.thumbs-wrapper': {
          //     display: 'flex',
          //     justifyContent: 'center',
          //     margin: '0 auto',
          //   },
          //   '.thumbs': {
          //     display: 'flex',
          //     justifyContent: 'center',
          //   },
          // }}
          className="carousel"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems:'center',
            margin: '0 auto',
          }}
                >
          {images?.map((image, index) => (
            <div key={index} >
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  // height: "500px", // Ajustez la hauteur en fonction de vos besoins
                }}
              />
            </div>
          ))}
        </Carousel>
      </Box>
    );
  };

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <ShowcaseSection>
        <Box>
          <Typography variant="h3" component="h1" gutterBottom>
            Nos projets
          </Typography>
          <Typography variant="h5" component={"p"}>
            Découvrez nos réalisations pour un espace personnalisé et
            harmonieux.
          </Typography>
        </Box>
      </ShowcaseSection>
      {/* <Container maxWidth="lg">
        <Typography
          variant="h6"
          align="center"
          // color="text.secondary"
          paragraph
          py={10}
        >
          Spécialiste en immobilier depuis plus de 20 ans, nous réalisons vos
          projets de location, d'aménagement, de construction et de location de
          bureaux avec passion et expertise.
        </Typography>
        {/* <Grid container spacing={4} py={5}>
          <Grid item xs={12} sm={6}>
           
              <Typography variant="h7" component={"p"}>ggg</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
              <Typography>ggg</Typography>
          </Grid>
        </Grid> */}
      {/* <Typography variant="h4" align="left" color="text.primary" gutterBottom>
          Nos projets
        </Typography>
        <Box sx={{ width: "100%", bgcolor: "background.paper", mt: 4 }}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            centered
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons="auto"
          >
            <Tab label="Location" />
            <Tab label="Aménagement" />
            <Tab label="Construction" />
            <Tab label="Bureaux" />
          </Tabs>
        </Box>

        <Box sx={{ mt: 4 }}>
          {sections.map((section, index) => (
            <Box key={section} hidden={currentTab !== index}>
              <Grid container spacing={4}>
                {projectsData[section].map((project) => (
                  <Grid item key={project.id} xs={12} sm={6} md={4}>
                    <ProjectCard project={project} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Box>
      </Container> */}

      <Container maxWidth="lg">
        <Typography
          variant="h6"
          align="center"
          // color="text.secondary"
          paragraph
          py={10}
        >
          Spécialiste en immobilier depuis plus de 20 ans, nous réalisons vos
          projets de location, d'aménagement, de construction et de location de
          bureaux avec passion et expertise.
        </Typography>
        {/* <Grid container spacing={4} py={5}>
          <Grid item xs={12} sm={6}>
           
              <Typography variant="h7" component={"p"}>ggg</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
              <Typography>ggg</Typography>
          </Grid>
        </Grid> */}
        <Typography variant="h4" align="left" color="text.primary" gutterBottom>
          Nos projets
        </Typography>
        <Box sx={{ width: "100%", bgcolor: "background.paper", mt: 4 }}>
          <Box sx={{ mt: 4 }}>
            {sections.map((section, index) => (
              <Box key={section} hidden={currentTab !== index}>
                <Grid container spacing={4}>
                  {projectsDatas.map((project) => (
                    <Grid item key={project.id} xs={12} sm={6} md={4}>
                      <ProjectCard
                        project={project}
                        handleOpenModal={handleOpenModal}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>

      <StyledModal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="project-modal-title"
        aria-describedby="project-modal-description"
      >
        <ModalContent>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            style={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedProject && (
            // <>
            //   <Typography id="project-modal-title" variant="h6" component="h2">
            //     {selectedProject.title}
            //   </Typography>
            //   <img
            //     src={selectedProject.image}
            //     alt={selectedProject.title}
            //     style={{ width: "100%", marginTop: theme.spacing(2) }}
            //   />
            //   <Typography
            //     id="project-modal-description"
            //     style={{ marginTop: theme.spacing(2) }}
            //   >
            //     {selectedProject.description}
            //   </Typography>
            // </>
            <>
              <Typography id="project-modal-title" variant="h6" component="h2">
                {selectedProject.title}
              </Typography>
              <Typography mb={3} id="project-modal-description" className="mt-4">
                 {selectedProject.description}
              </Typography>
              
              <ImageCarousel images={selectedProject.images} />
            </>
          )}
        </ModalContent>
      </StyledModal>
    </Box>
  );
};

export default Projects;
