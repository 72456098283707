import React from "react";
import { Box } from "@mui/material";
import UpdateServiceForm from "../../../pages-sections/manager/bookingServices/UpdateService";

const UpdateServicePage = () => {

    return(
        <Box>
            <UpdateServiceForm/>
        </Box>
    )
}

export default UpdateServicePage;