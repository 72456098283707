import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  Facebook,
  WhatsApp,
  LinkedIn,
  Twitter,
  YouTube,
  Instagram,
} from "@mui/icons-material";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "linear-gradient(to right, #50b3a2, #6ec6b8)",
  borderBottom: "3px solid #e8491d",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  "&:hover": {
    fontWeight: "bold",
  },
}));

const HighlightTypography = styled(Typography)(({ theme }) => ({
  color: "#e8491d",
  fontWeight: "bold",
}));

// const ShowcaseBox = styled(Box)(({ theme }) => ({
//   backgroundImage: "url(/assets/individual_desks_b.jpg)",
//   backgroundRepeat: "no-repeat",
//   backgroundSize: "cover",
//   backgroundPosition: "center",
//   color: "#ffffff",
//   textAlign: "center",
//   padding: theme.spacing(10, 2),
// }));

const ShowcaseSection = styled("section")(({ theme }) => ({
  minHeight: "400px",
  backgroundImage:
    'url("/assets/individual_desks_b.jpg")',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  textAlign: "center",
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const CTAButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#e8491d",
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "#333",
  },
}));

const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: "#e8491d",
  color: "#ffffff",
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
}));

const DiasporaService = () => {
  return (
    <Box width={"full"}>
      {/* <StyledAppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <HighlightTypography component="span">Services</HighlightTypography>{" "}
            Immobiliers
          </Typography>
          <StyledButton color="inherit">Accueil</StyledButton>
          <StyledButton color="inherit">Location</StyledButton>
          <StyledButton color="inherit">Construction</StyledButton>
          <StyledButton color="inherit">Aménagement</StyledButton>
          <StyledButton color="inherit">Diaspora</StyledButton>
          <StyledButton color="inherit">Contact</StyledButton>
        </Toolbar>
      </StyledAppBar> */}

      <ShowcaseSection>
        <Box>
          <Typography variant="h3" component="h1" gutterBottom textAlign={"justify"}>
            Services Immobiliers pour la Diaspora
          </Typography>
          <Typography variant="h5" component={"p"} textAlign={"justify"}>
            Votre partenaire de confiance pour investir dans votre pays
            d'origine
          </Typography>
        </Box>
      </ShowcaseSection>

      <Container maxWidth="full" style={{ marginTop: "2rem" }}>
        <Box px={{ xs: 3, md: "150px" }}>
          <Typography variant="h5" align="left" mb={2}>
            Nos Services pour la Diaspora
          </Typography>
          <Grid container spacing={3}>
            {[
              "Investissement Immobilier",
              "Gestion de Propriétés",
              "Construction sur Mesure",
            ].map((service, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card>
                  <CardContent padding={7}>
                    <Typography
                      variant="h6"
                      component="h2"
                      mb={3}
                      style={{ color: "#50b3a2" }}
                    >
                      {service}
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      textAlign={"justify"}
                      // color="textSecondary"
                      // fontWeight={700}
                    >
                      {index === 0 &&
                        "Nous vous aidons à identifier et à sécuriser les meilleures opportunités d'investissement immobilier dans votre pays d'origine."}
                      {index === 1 &&
                        "Nous gérons vos propriétés à distance, assurant leur entretien et leur location pour maximiser votre retour sur investissement."}
                      {index === 2 &&
                        "Réalisez votre projet de construction à distance avec notre équipe expérimentée qui supervise chaque étape du processus."}
                    </Typography>
                    <Grid mt={3}>
                      <Button
                        variant="contained"
                        size="lg"
                        sx={{ backgroundColor: "#f4511e" }}
                        href="/contact"
                      >
                        En savoir plus
                      </Button>
                    </Grid>
                  </CardContent>
                  {/* <CardActions> */}
                  {/* <Button
                    size="small"
                    href="/contact"
                    sx={{ backgroundColor: "#f4511e" }}
                  >
                    En savoir plus
                  </Button> */}
                  {/* </CardActions> */}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box my={6}>
          <Grid width={"full"}>
            <Card>
              <CardContent>
                <Box px={{ xs: 5, md: "120px" }} py={5}>
                  <Typography variant="h5" gutterBottom>
                    Pourquoi Nous Choisir ?
                  </Typography>
                  <List textAlign={"justify"}>
                    <ul>
                      {[
                        "Expertise locale et compréhension des besoins de la diaspora",
                        "Processus transparent et communication régulière",
                        "Réseau de partenaires de confiance (notaires, avocats, banques)",
                        "Assistance pour les démarches administratives et légales",
                        "Solutions de financement adaptées aux non-résidents",
                        "Service client dédié en plusieurs langues",
                      ].map((item, index) => (
                        <li style={{ padding: "3px" }}>{item}</li>
                      ))}
                    </ul>
                  </List>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Box>

        <Box my={4}>
          <Card>
            <CardContent>
              <Box px={{ xs: 5, md: "120px" }} py={5}>
                <Typography variant="h5" mb={3}>
                  Témoignages de Nos Clients
                </Typography>
                <Grid container spacing={3} direction={"column"}>
                  {[
                    {
                      text: "Grâce à leur expertise et leur professionnalisme, j'ai pu investir sereinement dans mon pays d'origine tout en restant à l'étranger. Je recommande vivement leurs services!",
                      author: "Marie K., France",
                    },
                    {
                      text: "L'équipe a géré la construction de ma maison de A à Z. Leur communication régulière et leur transparence m'ont permis de suivre le projet comme si j'étais sur place.",
                      author: "Ahmed L., Canada",
                    },
                  ].map((testimonial, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Card>
                        <CardContent sx={{ backgroundColor: "grey.100", p: 5 }}>
                          <Typography variant="h7" paragraph textAlign={"justify"}>
                            {testimonial.text}
                          </Typography>
                          <Typography variant="subtitle2">
                            - {testimonial.author}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box my={4} textAlign="left">
          <Card padding={6}>
            <CardContent>
              <Box px={{ xs: 5, md: "120px" }} py={5}>
                <Typography variant="h5" mb={2}>
                  Contactez-nous
                </Typography>
                <Typography variant="body1" paragraph textAlign={"justify"}>
                  Prêt à concrétiser votre projet immobilier ? Contactez-nous
                  dès aujourd'hui pour une consultation personnalisée.
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ backgroundColor: "#f4511e" }}
                  href="/contact"
                >
                  Nous Contacter
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
      {/* 
      <StyledFooter>
        <Container maxWidth="lg">
          <Typography variant="body1" align="center" gutterBottom>
          © 2024 Pategou-consulting. Tous droits réservés.
          </Typography>
          <Box display="flex" justifyContent="center">
            {[Facebook, WhatsApp, LinkedIn, Twitter, YouTube, Instagram].map((Icon, index) => (
              <IconButton key={index} color="inherit" aria-label="social media">
                <Icon />
              </IconButton>
            ))}
          </Box>
        </Container>
      </StyledFooter> */}
    </Box>
  );
};

export default DiasporaService;
