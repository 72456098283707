import { Box } from "@mui/material";
import React from "react";
import BookingDetails from "../../../pages-sections/manager/bookings/BookingDetails";

const BookingDetailsPage = () => {
  return (
    <Box>
      <BookingDetails />
    </Box>
  );
};
export default BookingDetailsPage;