import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  IconButton,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import houseApiRoutes from "../../../utils/api/houseApiRoutes";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import categoryApiRoutes from "../../../utils/api/categoryApiRoutes";

const AllHouse = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [houses, setHouses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [allHouseCategories, setAllHouseCategories] = useState([])

  const alert = useAlert();
  const navigate = useNavigate();

  const handleSelectAll = (event) => {
    const newSelected = event.target.checked
      ? houses.map((house) => house.id)
      : [];
    setSelected(newSelected);
    setSelectAll(event.target.checked);
  };

  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async () => {
    console.log("Deleting selected rows: ", selected);
    setIsLoading(true);
    const apiResponse = selected.map((houseId) =>
      houseApiRoutes.removeHouse(houseId)
    );
    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      alert.success("Supréssion réussit");
    } else {
      setIsLoading(false);
      alert.error("Echec de la suppréssion");
    }
  };
  const retrieveAllCategories = useCallback(async () => {
    setIsLoading(true);
    const apiResponse = await categoryApiRoutes.getAllCategories();

    if (!Object.keys(apiResponse).includes("error")) {
      setIsLoading(false);
      setAllHouseCategories(apiResponse)
      console.log(apiResponse);
    } else {
      alert.error(
        <div style={{ textTransform: "none" }}>
          {apiResponse.error ??
            "Une erreur s'est produite lors de la récupération des catégories"}
        </div>
      );
    }
  }, [alert]);
  const getHouses = useCallback(async () => {
    setIsLoading(true);

    const responseApi = await houseApiRoutes.getAllHouses();
    console.log(responseApi);
    if (!Object.keys(responseApi).includes("error")) {
      console.log(responseApi);
      const apiResponseCategories = await categoryApiRoutes.getAllCategories();   
      if(!Object.keys(apiResponseCategories).includes("error")){
        const housesWithCategoryNames = responseApi.map(house => {
          const category = apiResponseCategories.find(cat => cat.id === house.category); 
          return {
            ...house,
            categoryName: category ? category.name : 'Aucune', 
          };
        })
        setHouses(housesWithCategoryNames);
      }else {
       console.log("Aucune catégorie existante");
       
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alert("Une erreur est survenue");
    }
  },[alert])

  const publishHouses = async (id) => {
    setIsLoading(true);
    const publishApiResponse = await houseApiRoutes.publishHouse(id);
    if (!Object.keys(publishApiResponse).includes("error")) {
      setIsLoading(false);
      alert.success("Logement publié");
      console.log("responsePublish", publishApiResponse);
    } else {
      setIsLoading(false);
      alert.error("Logement non publié");
    }
  };

  const unPublishHouses = async (id) => {
    setIsLoading(true);
    const publishApiResponse = await houseApiRoutes.unPublishHouse(id);
    if (!Object.keys(publishApiResponse).includes("error")) {
      setIsLoading(false);
      alert.success("Logement dépublié avec succès");
      console.log("responsePublish", publishApiResponse);
    } else {
      setIsLoading(false);
      alert.error("Logement ");
    }
  };

  useEffect(() => {
    getHouses();
    retrieveAllCategories()
  }, [retrieveAllCategories, getHouses]);

  return (
    <Box width={"full"} p={{ xs: 3, md: 10 }} mt={{ xs: 8, md: 2 }}>
      <Box>
        <Typography variant="h6" gutterBottom mb={3}>
          Liste des logements
        </Typography>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < houses.length
                      }
                      checked={houses.length > 0 && selected.length === houses}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Catégory</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Prix</TableCell>
                  <TableCell>Publication</TableCell>

                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {houses
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((house) => (
                    <TableRow key={house.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selected.indexOf(house.id) !== -1}
                          onChange={() => handleSelect(house.id)}
                        />
                      </TableCell>
                      <TableCell>{house.categoryName}</TableCell>
                      <TableCell>{house.type}</TableCell>
                      <TableCell>{house.status}</TableCell>
                      <TableCell>
                        {house.price} {}
                        {"FCFA"} {} {house.unit}
                      </TableCell>
                      <TableCell>{house.publishStatus}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigate(`/immo/details-house/${house.id}`)
                          }
                          size="small"
                        >
                          Détails
                        </IconButton>
                        <IconButton
                          onClick={() => navigate(`/immo/update-house/${house.id}`)}
                          size="small"
                        >
                          <Tooltip title="Editer" sx={{color:"#26a69a"}}>
                            <DriveFileRenameOutlineIcon />
                          </Tooltip>
                        </IconButton>
                        {house.publish === true ? (
                          <IconButton
                            onClick={() => unPublishHouses(house.id)}
                            size="small"
                            sx={{
                              color: "#e57373",
                              // "&:hover":{backgroundColor:}
                            }}
                          >
                            dépublier
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => publishHouses(house.id)}
                            size="small"
                            sx={{ color: "#009688" }}
                          >
                            Publier
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <IconButton
              color="red"
              onClick={() => setOpenConfirmationModal(true)}
              disabled={selected.length === 0}
              size="small"
              sx={{ color: "#f44336" }}
            >
              <DeleteIcon fontSize="small" sx={{ color: "#f44336" }} />
              Supprimer
            </IconButton>
            {openConfirmationModal ? (
              <DeleteHouseModal
                open={openConfirmationModal}
                onClose={() => setOpenConfirmationModal(false)}
                onSubmit={handleDelete}
              />
            ) : (
              ""
            )}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={houses.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export const DeleteHouseModal = ({ open, onClose, onSubmit }) => {
  const handleConfirmDelete = () => {
    onSubmit(true);
    onClose();
  };

  return (
    <Box>
      <Dialog open={open}>
        <DialogContent>
          <Typography variant="h6" textAlign={"center"} mb={2}>
            Confirmation de suppréssion
          </Typography>
          <Box>
            <Typography>Voulez-vous supprimer ce logement?</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            sx={{ backgroundColor: "#f4511e" }}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AllHouse;
