import { Box, Container, Typography } from "@mui/material";
import React from "react";

const BookingForm = () => {
  return (
    <Container width={"full"}>
      <Box p={{ xs: 3, sm: 3, md: 10 }} mt={{ xs: 8, sm: 8, md: "20px" }}>
        <Typography variant="h6" mb={3}>
          Faire une réservation
        </Typography>
      </Box>
    </Container>
  );
};

export default BookingForm;
