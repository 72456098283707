import { Box, Container, Grid, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
import serviceApiRoute from "../../../utils/api/serviceApiRoute";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import * as yup from "yup";

let __initValues = null;

const UpdateServiceForm = () => {

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(null);
  const [loader, SetLoader] = useState(false);
//   const [serviceInfos, setServiceInfos] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const alert = useAlert();
 const id = useParams()

 const [serviceInfos, setServiceInfos] = useState({
    name: "",
    description: "",
    price: 0,
  });

  const formSchema = yup.object().shape({
    name: yup.string().required("Le nom ne peut pas être nul"),
    price:  yup.number().required("Le prix ne peut pas être nul"),

  });

  const handleFormSubmit = async (values ) => {
   // event.preventDefault();
    SetLoader(true);
    console.log("values",values);
    const submitValue = {
      name: values.name,
      price: values.price,
      description: values.description
    }

    const response = await serviceApiRoute.updateService(id.id, submitValue);
    if (!Object.keys(response).includes("error")) {
      SetLoader(false);
      // console.log(response);
      alert.success(
        <div style={{ textTransform: "none" }}>
          Mise à jour  réussit
        </div>
      );
      navigate("/immo/booking-services");
    } else {
      SetLoader(false);
      alert.error(
        <div style={{ textTransform: "none" }}>
          {response.error ??
            "Une erreur s'est produite lors de l'enregistrement du service"}
        </div>
      );
    }
  };

  const getServiceInfos = async() => {
    setIsLoading(true)
    console.log(id);

    const apiResponse = await serviceApiRoute.getById(id.id)

    if(!Object.keys(apiResponse).includes("error")){
      console.log('serviceInfos', apiResponse);
      setIsLoading(false)

      alert.success(
        <div style={{ textTransform: 'none' }}>
          Récupération des informations
        </div>
      )
      setServiceInfos(apiResponse)

    }else{
      setIsLoading(false)
      alert.success(
        <div style={{ textTransform: 'none' }}>
          Une erreur est survenue
        </div>
      )
    }
  }


useEffect(() =>{
  getServiceInfos()
},[])


  return (
    <Container width={"full"}>
      <Box p={{ xs: 3, sm: 3, md: 10 }} mt={{ xs: 8, sm: 8, md: "20px" }}>
        <Typography variant="h6" mb={3}>
          Modifier les information du service
          
        </Typography>
        <Card>
          <Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={serviceInfos}
            validationSchema={formSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container p={{ xs: 2, md: 4 }} spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      placeholder="Nom"
                      size="medium"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      error={!!touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      placeholder="Description"
                      name="description"
                     value={values.description}
                      error={!!touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      placeholder="Prix"
                      name="price"
                      value={values.price}
                      error={!!touched.price && !!errors.price}
                      helperText={touched.price && errors.price}
                      onChange={handleChange}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} alignItems={"flex-end"}>
                    <Button size="small"  color="secondary" variant="contained" type="submit">
                      Ajouter
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Card>
      </Box>
    </Container>
  );
};

export default UpdateServiceForm;
